<template lang="html">
  <layout-private>
    <div class="">
      <h2>Start Your Membership</h2>
      <hr />

      <div class="row">
        <div class="col-lg-9">
          <h4 class="snug">Step 2 of 3</h4>
          <h3>Payment Method</h3>
        </div> <!-- /.col-x -->
      </div> <!-- /.row -->

      <p v-if="!guarantor_account && !Object.keys(products).length" class="loading"><i class="far fa-pulse fa-2x fa-spinner major-color"></i></p>
      <div v-if="guarantor_account && Object.keys(products).length" class="row">
        <div class="col-lg-6">
          <div class="membership-summary">
            <h4>Summary</h4>
            <p>{{ members.length }} member<span v-if="members.length>1">s</span> receive<span v-if="members.length==1">s</span> benefits</p>
            <p v-if="totalMonthlyPrice > 0">${{ totalMonthlyPrice }} per month</p>
            <p v-if="totalYearlyPrice > 0">${{ totalYearlyPrice }} once per year</p>
            <p><strong>Due today:</strong> ${{ totalYearlyPrice + totalMonthlyPrice }}</p>
          </div>
        </div> <!-- /.col-x -->
      </div> <!-- /.row -->

      <div class="row">
        <div v-show="guarantor_account" class="col-12 col-md-6">
          <div ref="card"></div>
          <list-errors :errors="errors"></list-errors>
          <p>
            <button v-if="!loading"  @click.once.prevent="createStripeToken" class="btn btn-app">Pay Now &amp; Start Subscription<i class="far fa-angle-right fa-fw fa-right"></i></button>
            <i v-if="loading" class="far fa-spinner-third fa-spin fa-2x"></i>
          </p>
          <p><router-link to="/guarantor-account" class="snug">back to account</router-link></p>
        </div> <!-- /.col-x -->
      </div> <!-- /.row -->


    </div>
  </layout-private>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import ListErrors from '../components/ListErrors.vue'

// set up Stripe
let stripe = Stripe(process.env.VUE_APP_STRIPE_KEY),
    elements = stripe.elements(),
    card = undefined;

export default {
  props: ['plans', 'stripeElementsOptions'],
  data () {
    return {
      errors: [],
      loading: false,
      planIds: null,
      products: {},
    }
  },
  components: {
    LayoutPrivate, ListErrors
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'guarantorPracticeName', 'guarantor_account']),
    members () {
      if(this.guarantor_account && this.guarantor_account.private.members) {
        return Object.values(this.guarantor_account.private.members);
      }
      return null;
    },
    totalMonthlyPrice () {
      return this.totalPrice('month');
    },
    totalYearlyPrice () {
      return this.totalPrice('year');
    },
  },
  watch: {
    // get products on page refresh
    user_props () {
      this.getPlanIds();
    },
    // once we have a list of planIds, load them all
    planIds () {
      // load all the plans
      let planPromises = [];
      if (this.planIds) {
        for (const planId in this.planIds) {
          // console.log(`getting plan ${planId}`);
          let planPromise = Vue.db.ref(`/plans/${planId}`);
          planPromises.push(planPromise);
          planPromise.on('value', (snapshot) => {
            let plan = snapshot.val();
            // store as an object for easier lookup
            // this.products[planId] = plan; // not reactive
            this.$set(this.products, planId, plan) // reactive version
            // console.log('plan loaded',this.products);
          });
        }
      }
      Promise.all(planPromises)
        .then(() => {
          this.loading = false;
        })
    },
  },
  methods: {
    totalPrice (interval) {
      let total = 0;
      if(!Object.values(this.products).length) {
        return total;
      }
      this.members.forEach(mem => {
        if(mem.plan_id && mem.plan_price_id && this.products[mem.plan_id]) {
          if(this.products[mem.plan_id].prices[mem.plan_price_id].interval == interval) {
            total += this.products[mem.plan_id].prices[mem.plan_price_id].amount;
          }
        }
      })
      if(total > 0) {
        return total/100;
      } else {
        return total;
      }
    },
    getPlanIds () {
      if(!this.user_props || !this.user_props.practice_account_id) {
        return;
      }
      // get plan ids from practice account public info
      Vue.db.ref(`/accounts/${this.user_props.practice_account_id}/public/plans`)
        .on('value', (plansSnapshot) => {
          if(plansSnapshot.exists()) {
            // set planIds to trigger watch method to load them all in a loop
            this.planIds = plansSnapshot.val();
            // console.log("planIds", this.planIds)
          }
        });
    },
    createStripeToken () {
      // reset errors
      this.errors = [];
      this.loading = true;
      // create token
      stripe.createToken(card).then((result) => {
        // console.log(result);
        // return;
        if (result.error) {
          console.log(result.error.message);
          this.errors.push(result.error.message);
          this.loading = false;
          return;
        }
        let data = {};
        // if they already own an guarantor_account, update its payment method
        if(this.guarantor_account) {
          data[`guarantor_accounts/${this.user_props.owned_guarantor_account_id}/private/stripe_token`] = result.token.id;
        }
        // if they don't have an guarantor_account, show an error
        else {
          this.errors.push("You haven't added your subscription details yet. Please visit the account page to start yours!");
          this.loading = false;
          return;
        }
        // save all updates to database
        Vue.db.ref().update(data)
          .then(result => {
            // console.log(result);
            this.$router.push('/guarantor-account');
          })
          .catch(err => {
            console.log("error saving payment method",err);
            this.loading = false;
            this.errors.push(err);
          })

      })
      .catch(err => {
        console.log(err.message);
        this.errors.push(err.message);
        this.loading = false;
      })
      ; // /createToken.then
    } // /createStripeToken
  },
  mounted () {
    // get products on router navigation
    this.getPlanIds();
    //  create Stripe elements form
    if(!card) {
      card = elements.create('card',  this.stripeElementsOptions);
    }
    // only mount if the element exists
    if(this.$refs.card) {
      card.mount(this.$refs.card);
      // listen for card errors
      card.addEventListener('change', (event) => {
        // reset errors
        this.errors = [];
        // add any new errors
        if (event.error) {
          this.errors.push(event.error.message);
        }
      });
    }

  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

// summary box
.membership-summary {
  background-color: @box-color;
  padding: 20px;
  margin: 30px 0;

  h4 {
    margin: 0 0 10px;
  }
  p {
    margin: 0;
  }
}
</style>
