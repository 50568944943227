<template lang="html">
  <layout-private>
    <div class="row">
      <div v-show="this.guarantor_account" class="col-12 col-md-8 offset-md-2 text-center">
        <h2>Update Your Payment Method</h2>
      </div> <!-- /.col-x -->
      <div v-show="this.guarantor_account" class="col-12 col-md-6 offset-md-3 text-center">
        <div ref="card"></div>
        <list-errors :errors="errors"></list-errors>
        <p>
          <button v-if="!loading"  @click.once.prevent="createStripeToken" class="btn btn-app">Save New Payment Method<i class="far fa-angle-right fa-fw fa-right"></i></button>
          <i v-if="loading" class="far fa-spinner-third fa-spin fa-2x"></i>
        </p>
        <p><router-link to="/guarantor-account" class="snug">back to account</router-link></p>
      </div> <!-- /.col-x -->
      <div v-if="!this.guarantor_account" class="col-12 col-md-8 offset-md-2 text-center">
        <p><i class="far fa-spinner-third fa-spin fa-2x"></i></p>
        <h2>Loading Your Subscription</h2>
        <p>If you don't have an active subscription, you can start one here:</p>
        <p><router-link class="btn btn-app" to="/guarantor-account">Continue Account Setup<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
      </div> <!-- /.col-x -->
    </div> <!-- /.row -->
  </layout-private>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import ListErrors from '../components/ListErrors.vue'

// set up Stripe
let stripe = Stripe(process.env.VUE_APP_STRIPE_KEY),
    elements = stripe.elements(),
    card = undefined;

export default {
  props: ['plans', 'stripeElementsOptions'],
  data () {
    return {
      errors: [],
      loading: false,
    }
  },
  components: {
    LayoutPrivate, ListErrors
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'guarantor_account']),
  },
  methods: {
    createStripeToken () {
      // reset errors
      this.errors = [];
      this.loading = true;
      // create token
      stripe.createToken(card).then((result) => {
        // console.log(result);
        // return;
        if (result.error) {
          console.log(result.error.message);
          this.errors.push(result.error.message);
          this.loading = false;
          return;
        }
        let data = {};
        // if they already own an guarantor_account, update its payment method
        if(this.guarantor_account) {
          data[`guarantor_accounts/${this.user_props.owned_guarantor_account_id}/private/stripe_token`] = result.token.id;
          // data[`guarantor_accounts/${this.user_props.owned_guarantor_account_id}/private/account_status`] = 'pending';
        }
        // if they don't have an account, show an error
        else {
          this.errors.push("You don't have a subscription yet. Please visit the account page to start yours!");
          this.loading = false;
          return;
        }
        // save all updates to database
        Vue.db.ref().update(data)
          .then(result => {
            // console.log(result);
            this.$router.push('/guarantor-account');
          })
          .catch(err => {
            console.log("error updating guarantor payment method",err);
            this.loading = false;
            this.errors.push(err);
          })

      })
      .catch(err => {
        console.log(err.message);
        this.errors.push(err.message);
        this.loading = false;
      })
      ; // /createToken.then
    } // /createStripeToken
  },
  mounted () {
    //  create Stripe elements form
    if(!card) {
      card = elements.create('card',  this.stripeElementsOptions);
    }
    // only mount if the element exists
    if(this.$refs.card) {
      card.mount(this.$refs.card);
      // listen for card errors
      card.addEventListener('change', (event) => {
        // reset errors
        this.errors = [];
        // add any new errors
        if (event.error) {
          this.errors.push(event.error.message);
        }
      });
    }
  },
}
</script>

<style lang="less" scoped>
</style>
