import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firebaseAction } from 'vuexfire'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    user_props: null,
    account: null,
    guarantor_account: null,
    guarantor_practice_name: null,
    guarantor_practice_contact: null,
    guarantor_practice_url: null,
    admin_practice_name: null,
  },
  getters: {
    user: state => state.user,
    user_props: state => state.user_props,
    account: state => state.account,
    guarantor_account: state => state.guarantor_account,
    guarantorPracticeName: state => state.guarantor_practice_name,
    guarantorPracticeContact: state => state.guarantor_practice_contact,
    guarantorPracticeUrl: state => state.guarantor_practice_url,
    adminPracticeName: state => state.admin_practice_name,
    onboardingComplete: state => {
      if(state.account && state.account.private && ['active', 'pastDue', 'pending'].includes(state.account.private.subscription_status) && state.account.public && state.account.public.sales_page_meta_id) {
        return true;
      }
      return false;
    },
    isPracticeUser: state => {
      if(state.user_props && state.user_props.user_type && state.user_props.user_type == 'practice') {
        return true;
      }
      return false;
    },
  },
  mutations: {
    ...vuexfireMutations,
    UPDATE_USER (state, user_payload) {
      state.user = user_payload;
    },
    RESET_STATE (state, account_payload) {
      state.user = null;
      state.user_props = null;
      state.account = null;
      state.guarantor_account = null;
      state.guarantor_practice_name = null;
      state.guarantor_practice_contact = null;
      state.guarantor_practice_url = null;
      state.admin_practice_name = null;
    },
    SET_GUARANTOR_PRACTICE_NAME (state, practice_name_payload) {
      state.guarantor_practice_name = practice_name_payload;
    },
    SET_GUARANTOR_PRACTICE_CONTACT (state, practice_contact_payload) {
      state.guarantor_practice_contact = practice_contact_payload;
    },
    SET_GUARANTOR_PRACTICE_URL (state, practice_url_payload) {
      state.guarantor_practice_url = practice_url_payload;
    },
    SET_ADMIN_PRACTICE_NAME (state, practice_name_payload) {
      state.admin_practice_name = practice_name_payload;
    },
  },
  actions: {
    updateUser (context, user_payload) {
      context.commit('UPDATE_USER', user_payload)
    },
    resetState (context) {
      context.commit('RESET_STATE')
    },
    setGuarantorPracticeName (context, practice_name_payload) {
      context.commit('SET_GUARANTOR_PRACTICE_NAME', practice_name_payload)
    },
    setGuarantorPracticeContact (context, practice_contact_payload) {
      context.commit('SET_GUARANTOR_PRACTICE_CONTACT', practice_contact_payload)
    },
    setGuarantorPracticeUrl (context, practice_url_payload) {
      context.commit('SET_GUARANTOR_PRACTICE_URL', practice_url_payload)
    },
    setAdminPracticeName (context, practice_name_payload) {
      context.commit('SET_ADMIN_PRACTICE_NAME', practice_name_payload)
    },
    // bind state to firebase using vuexfire
    setUserPropsRef: firebaseAction(({ bindFirebaseRef, unbindFirebaseRef }, { ref }) => {
      bindFirebaseRef('user_props', ref)
    }),
    setAccountRef: firebaseAction(({ bindFirebaseRef, unbindFirebaseRef }, { ref }) => {
      bindFirebaseRef('account', ref)
    }),
    setGuarantorAccountRef: firebaseAction(({ bindFirebaseRef, unbindFirebaseRef }, { ref }) => {
      bindFirebaseRef('guarantor_account', ref)
    }),
  }
})
