<template lang="html">
  <layout-private>
    <div class="">
      <h2 v-if="!plan || !plan.prices">Create Plan</h2>
      <h2 v-if="plan && plan.prices">Edit Plan</h2>
      <hr />
      <div v-if="plan && plan.prices">
        <ul class="nav nav-sub">
          <li class="nav-item">
            <router-link :to="'/plan-details/' + planId" exact-active-class="active" class="nav-link">Plan Details</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="'/plan-prices/' + planId" exact-active-class="active" class="nav-link">Plan Prices</router-link>
          </li>
        </ul>
      </div>

      <div v-if="!published || !plan || !plan.prices">
        <h4>Step 2 of 2</h4>
        <h3>Plan Settings &amp; Benefits</h3>
      </div>

      <div v-if="!isAccountReady">
        <p>Finish setting up your account to begin creating member plans.</p>
        <p><router-link class="btn btn-app" to="/set-up">Continue Set Up<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
      </div>

      <div v-if="isAccountReady">
        <p><em>Note:</em> plan prices cannot be edited after they are saved to prevent surprise charges to your members. To change pricing, you can archive an old price and add a new price anytime.</p>

        <div class="row">
          <div class="col-4 col-lg-2">
            <p><strong>Price Per Member</strong></p>
          </div> <!-- /.col-x -->
          <div class="col-4 col-lg-2">
            <p><strong>Billing Interval</strong></p>
          </div> <!-- /.col-x -->
        </div>
        <div v-for="(price, index) in prices" class="row">
          <div class="col-4 col-lg-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">$</div>
              </div>
              <input
                v-model.number="price.amount"
                @input="setEdited"
                :disabled="price.stripe_patient_plan_id"
                class="form-control" placeholder="Enter number" type="number" maxlength="4"
              />
            </div>
          </div> <!-- /.col-x -->
          <div class="col-4 col-lg-2">
            <select
              v-model="price.interval"
              @input="setEdited"
              :disabled="price.stripe_patient_plan_id"
              class="form-control"
            >
              <option disabled :value="null">select one</option>
              <option :disabled="activeMonthlyPrice" value="month">Monthly</option>
              <option :disabled="activeYearlyPrice" value="year">Yearly</option>
              <option v-if="environment !== 'production'" :disabled="activeYearlyPrice" value="day">Daily</option>
            </select>
          </div> <!-- /.col-x -->
          <div v-if="price.stripe_patient_plan_id" class="col-2 col-lg-1">
            <div class="form-check form-check-inline">
              <input v-model="price.archived" @input="setEdited" class="form-check-input" :id="'checkbox'+index" type="checkbox" />
              <label class="form-check-label" :for="'checkbox'+index">archived</label>
            </div>
          </div> <!-- /.col-x -->
          <div v-if="!price.stripe_patient_plan_id && index !== 0" class="col-2 col-lg-1">
            <button class="btn btn-outline" @click="removePrice(index)"><i class="far fa-times"></i></button>
          </div> <!-- /.col-x -->
        </div>

        <p><a href="#addPrice" v-if="activePriceCount < 2" @click.prevent="addEmptyPrice" class=""><i class="far fa-plus-circle"></i> Add Another Price</a></p>

        <list-errors :errors="errors"></list-errors>

        <p>
          <button @click.prevent="publishPrices" :disabled="!edited" class="btn btn-app">Save &amp; Publish <i class="far fa-angle-right fa-fw fa-right"></i></button>
          <router-link v-if="published" to="/plans" class="btn btn-outline">Cancel</router-link>
        </p>
      </div>


    </div>
  </layout-private>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import ListErrors from '../components/ListErrors.vue'

export default {
  props: [''],
  data () {
    return {
      errors: [],
      planId: null,
      published: false,
      edited: false,
      plan: null,
      prices: [{
        amount: null,
        interval: null,
        archived: null,
      }],
    }
  },
  components: {
    LayoutPrivate, ListErrors
  },
  watch: {
    // load plan once account is set on page reload
    account () {
      if(this.account) {
        this.loadPlan();
      }
    },
  },
  computed: {
    ...mapGetters(['account']),
    environment () {
      if(process.env.NODE_ENV === "development") {
        return 'development'
      }
      if(process.env.VUE_APP_ENV === "staging") {
        return 'staging'
      }
      return 'production'
    },
    isAccountReady () {
      if( this.account && this.account.private && ['active', 'pastDue', 'pending'].includes(this.account.private.subscription_status) && this.account.restricted && this.account.restricted.stripe_connected_account_id ) {
        return true;
      }
      return false;
    },
    activePriceCount () {
      return this.prices.filter(price => !price.archived).length || 0;
    },
    activeMonthlyPrice() {
      // check if there is a price with an interval of monthly
      return this.prices.some(price => {
        return !price.archived && price.interval == 'month';
      });
    },
    activeYearlyPrice() {
      // check if there is a price with an interval of monthly
      return this.prices.some(price => {
        return !price.archived && price.interval == 'year';
      });
    },
  },
  methods: {
    setEdited () {
      if(!this.edited) {
        this.edited = true;
      }
    },
    addEmptyPrice () {
      if(this.activePriceCount<2) {
        this.prices.push({
          amount: null,
          interval: null,
          archived: null,
        })
      }
    },
    removePrice (index) {
      this.prices.splice(index, 1);
    },
    // load any pre-existing data
    loadPlan () {
      if(this.planId && this.isAccountReady && this.account.public.plans[this.planId]) {
        // load the existing data
        let planRef = Vue.db.ref(`/plans/${this.planId}`).once('value');
        planRef.then((planSnapshot) => {
          // set as published
          this.published = true;
          let savedPlan = planSnapshot.val();
          if(savedPlan) {
            this.plan = savedPlan;
            if(savedPlan.prices) {
              this.prices = [];
              for(const id in savedPlan.prices) {
                // console.log("inporting price " + id)
                this.prices.push({
                  amount: (savedPlan.prices[id].amount/100),
                  interval: savedPlan.prices[id].interval,
                  archived: savedPlan.prices[id].archived,
                  stripe_patient_plan_id: savedPlan.prices[id].stripe_patient_plan_id,
                  id: id,
                })
              }
            }
          }
        })
      }
    },
    publishPrices () {
      this.errors = [];
      let updates = {};
      // check number of prices
      if(this.activePriceCount > 2) {
        this.errors.push("You can only have 2 active prices at once.")
      }
      if(this.activePriceCount < 1) {
        this.errors.push("You must have at least 1 active price.")
      }
      if(this.prices.filter(price => !price.archived && price.interval == 'month').length > 1) {
        this.errors.push("You can only have 1 monthly price active at a time.")
      }
      if(this.prices.filter(price => !price.archived && price.interval == 'year').length > 1) {
        this.errors.push("You can only have 1 yearly price active at a time.")
      }
      // process each price
      this.prices.forEach(price => {
        // console.log(price)
        // update existing price if its archived status changed
        if(price.stripe_patient_plan_id && price.archived !== this.plan.prices[price.id].archived) {
          updates[`/plans/${this.planId}/prices/${price.id}/archived`] = price.archived;
        }
        // if it's a new plan,
          // validate then save the price and interval
        else if(!price.stripe_patient_plan_id) {
          if(price.amount < 1 || price.amount > 2000) {
            this.errors.push("The price per member must be between 1 and 2000.");
          }
          if(!Number.isInteger(price.amount)) {
            this.errors.push("The price per member must be a whole number.");
          }
          // don't invalidate for nulls, but don't save for nulls either
          if(!price.amount || !price.interval) {
            this.errors.push("All fields are required.");
          }
          let newPriceKey =  Vue.db.ref().child(`/plans/${this.planId}/prices/`).push().key;
          updates[`/plans/${this.planId}/prices/${newPriceKey}`] = {
            amount: price.amount * 100, // amount must be in cents, not dollars
            interval: price.interval,
            archived: false,
            stripe_patient_plan_id: 'pending',
          };
        }
      });
      if(!Object.keys(updates).length) {
        this.errors.push("Oops, there are no edits to save. Nothing has changed.");
      }
      // abort if found errors
      if(this.errors.length) {
        return;
      }
      // console.log("saving updates", updates)
      // publish updates
      Vue.db.ref().update(updates)
        .then(result => {
          // console.log("success saving prices",result);
          this.$router.push('/plans');
        })
        .catch(err => console.error("error saving prices",err))
    },
  },
  mounted () {
    // if an id was passed, store it
    if(this.$route.params.id) {
      this.planId = this.$route.params.id;
      this.loadPlan();
    }
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

h4 {
  margin-bottom: 5px;
}
.btn + .btn {
  margin-left: 10px;
}
.form-check {
  line-height: 50px;
}
</style>
