import store from './store'
import router from './router'
// import Firebase from 'firebase'
import Firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
// import 'firebase/functions'
import 'firebase/storage'
import 'firebase/analytics'

const config = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG);
let redirectUrl;

if(process.env.NODE_ENV === "production") {
  redirectUrl = 'https://app.healthassuranceplan.com/log-in';
}
// staging has both NODE_ENV production and Vue_APP_ENV, so this overwrites and must come 2nd
if(process.env.VUE_APP_ENV === "staging") {
  redirectUrl = 'https://health-assurance-staging.firebaseapp.com/log-in';
}
if(process.env.NODE_ENV === "development") {
  redirectUrl = "http://localhost:8080/log-in"
}


// via https://medium.com/@MichalBujalski/reactive-firebase-auth-with-vue-92577ab78ff6
export default {
  install: (Vue) => {
    //  store as global properties for easy access
    Vue.firebase = Firebase.initializeApp(config)
    Vue.auth = Vue.firebase.auth()
    Vue.db = Vue.firebase.database()
    Vue.storage = Vue.firebase.storage()
    Vue.analytics = Vue.firebase.analytics()
    const redirectSettings = {
      url: redirectUrl
    }
    Vue.prototype.$firebase = {
      logIn: async (email, password) => {
        return await Vue.auth.signInWithEmailAndPassword(email, password)
          .catch((err) => {
            // console.log(err);
            return err
          });
      },
      logOut: async () => {
        return await Vue.auth.signOut()
          .then(() => {
            store.dispatch('resetState');
            router.push('/log-in')
          })
          .catch((err) => {
            // console.log(err);
            return err
          });
      },
      signUp: async (email, password) => {
        let uid;
        return await Vue.auth.createUserWithEmailAndPassword(email, password)
          // after user created, send email verification
          .then(userCredential => {
            uid = userCredential.user.uid;
            // console.log("practice user saved",uid);
          })
          .then(() => {
            return Vue.db.ref(`user_props/${uid}/user_type`).set("practice");
          })
          .catch((err) => {
            // console.log(err);
            return err
          });
      },
      resetPassword: async (email) => {
        // console.log(resetPasswordRedirectSettings)
        return await Vue.auth.sendPasswordResetEmail(email, redirectSettings)
          .then(() => {
            return true
          })
          .catch((err) => {
            // console.log(err);
            return err
          });
      },
      verifyEmail: async () => {
        let user = Vue.auth.currentUser;
        return await user.sendEmailVerification(redirectSettings)
          .then(() => {
            // console.log("email sent");
            return true
          })
          .catch((err) => {
            // console.log(err);
            return false
          });
      },
      // patient-specific
      signUpGuarantor: async (email, password, practiceAccountId) => {
        // console.log('practiceAccountId',practiceAccountId);
        let uid;
        return await Vue.auth.createUserWithEmailAndPassword(email, password)
          // after user created, send email verification
          .then(userCredential => {
            uid = userCredential.user.uid;
            console.log("guarantor user saved",uid);
          })
          .then(() => {
            console.log('practiceAccountId',practiceAccountId);
            return Vue.db.ref(`user_props/${uid}/practice_account_id`).set(practiceAccountId);
          })
          .catch((err) => {
            console.log(err);
            return err
          });
      },
    }
    Vue.auth.onAuthStateChanged( async user => {
      store.dispatch('updateUser', user);
      if(user) {
        let userPropsRef = Vue.db.ref(`/user_props/${user.uid}`);
        store.dispatch('setUserPropsRef', userPropsRef);
        // track user in analytics
        Vue.analytics.setUserId(user.uid);
      }
    })
  }
}
