import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

// practice facing
import Members from './views/Members.vue'
import MemberSearch from './views/MemberSearch.vue'
import GuarantorMemberProfile from './views/GuarantorMemberProfile.vue'
import MembersExpired from './views/MembersExpired.vue'
import SalesEdit from './views/SalesEdit.vue'
import SalesView from './views/SalesView.vue'
import StripeConnect from './views/StripeConnect.vue'
import Plans from './views/Plans.vue'
import PlanEdit from './views/PlanEdit.vue'
import PlanPrices from './views/PlanPrices.vue'

// account & billing
import Account from './views/Account.vue'
import AccountBilling from './views/AccountBilling.vue'
import AccountUser from './views/AccountUser.vue'
import Subscription from './views/Subscription.vue'
import Cancel from './views/Cancel.vue'
import UpdateCard from './views/UpdateCard.vue'
import Onboarding from './views/Onboarding.vue'

// practice auth
import LogIn from './views/LogIn.vue'
import SignUp from './views/SignUp.vue'
import ResetPassword from './views/ResetPassword.vue'

// patient facing
import GuarantorSignUp from './views/GuarantorSignUp.vue'
import GuarantorAccount from './views/GuarantorAccount.vue'
import GuarantorUser from './views/GuarantorUser.vue'
import GuarantorProfile from './views/GuarantorProfile.vue'
import GuarantorAgreementForm from './views/GuarantorAgreementForm.vue'
import GuarantorMembership from './views/GuarantorMembership.vue'
import GuarantorAgreementSigned from './views/GuarantorAgreementSigned.vue'
import GuarantorCheckout from './views/GuarantorCheckout.vue'
import GuarantorUpdateCard from './views/GuarantorUpdateCard.vue'

// 404
import PageNotFound from './views/PageNotFound.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  // always scroll to top of new page
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    // --------------------
    // Practice Facing
    // --------------------
    // --------------------
    // Practice Billing
    {
      path: '/subscription',
      name: 'subscription',
      component: Subscription,
      meta: {
        authRequired: true,
        practiceUserRequired: true,
        pageTitle: 'Subscription',
      }
    },
    {
      path: '/cancel',
      name: 'cancel',
      component: Cancel,
      meta: {
        authRequired: true,
        pageTitle: 'Cancel',
      }
    },
    {
      path: '/update-card',
      name: 'updateCard',
      component: UpdateCard,
      meta: {
        authRequired: true,
        pageTitle: 'Update Card',
      }
    },
    {
      path: '/stripe-connect',
      name: 'stripeConnect',
      component: StripeConnect,
      meta: {
        authRequired: true,
        practiceUserRequired: true,
        pageTitle: 'Connect to Stripe',
      }
    },
    // Auth
    {
      path: '/account',
      component: Account,
      meta: {
        authRequired: true,
        practiceUserRequired: true,
      },
      children: [
        {
          path: '/account',
          name: 'account',
          component: AccountBilling,
          meta: {
            pageTitle: 'Billing | Account',
          }
        },
        {
          path: '/profile',
          name: 'profile',
          component: AccountUser,
          meta: {
            pageTitle: 'Profile | Account',
          }
        },
      ]
    },
    {
      path: '/log-in',
      name: 'logIn',
      component: LogIn,
      meta: {
        pageTitle: 'Log In',
      }
    },
    {
      path: '/',
      name: 'logInRoot',
      component: LogIn,
      meta: {
        pageTitle: 'Log In',
      }
    },
    {
      path: '/sign-up',
      name: 'signUp',
      component: SignUp,
      meta: {
        pageTitle: 'Dental Professionals Sign Up',
      }
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: ResetPassword,
      meta: {
        pageTitle: 'Reset Password',
      }
    },
    // Sales Pages
    {
      path: '/edit',
      name: 'salesEdit',
      component: SalesEdit,
      meta: {
        authRequired: true,
        practiceUserRequired: true,
        pageTitle: 'Edit Sales Page',
      }
    },
    // Membership Plans
    {
      path: '/plans',
      name: 'plans',
      component: Plans,
      meta: {
        authRequired: true,
        practiceUserRequired: true,
        pageTitle: 'Membership Plans',
      }
    },
    {
      path: '/plan-details/:id?', // :id is optional
      name: 'planDetails',
      component: PlanEdit,
      meta: {
        authRequired: true,
        practiceUserRequired: true,
        pageTitle: 'Membership Plan Details',
      }
    },
    {
      path: '/plan-prices/:id?', // :id is optional
      name: 'planPrices',
      component: PlanPrices,
      meta: {
        authRequired: true,
        practiceUserRequired: true,
        pageTitle: 'Membership Plan Prices',
      }
    },
    {
      path: '/set-up',
      name: 'onboarding',
      component: Onboarding,
      meta: {
        authRequired: true,
        practiceUserRequired: true,
        pageTitle: 'Set Up',
      }
    },
    // Members
    {
      path: '/members',
      component: Members,
      meta: {
        authRequired: true,
        practiceUserRequired: true,
      },
      children: [
        {
          path: '/members',
          name: 'members',
          component: MemberSearch,
          meta: {
            pageTitle: 'Member Search',
          }
        },
        {
          path: '/guarantor-profile/:id',
          name: 'guarantorProfile',
          component: GuarantorProfile,
          meta: {
            pageTitle: 'Guarantor Profile',
          }
        },
        {
          path: '/guarantor-user/:id',
          name: 'guarantorProfile',
          component: GuarantorUser,
          meta: {
            pageTitle: 'Guarantor User',
          }
        },
        {
          path: '/member-profile/:id',
          name: 'memberProfile',
          component: GuarantorMemberProfile,
          meta: {
            pageTitle: 'Member Profile',
          }
        },
        {
          path: '/expired-members',
          name: 'expiredMembers',
          component: MembersExpired,
          meta: {
            pageTitle: 'Expired Accounts',
          }
        },
      ]
    },
    {
      path: '/membership/:id/edit',
      name: 'editMembership',
      component: GuarantorMembership,
      meta: {
        authRequired: true,
        practiceUserRequired: true,
        pageTitle: 'Edit Membership',
      }
    },
    // --------------------
    // Patient Facing
    // --------------------
    // --------------------
    {
      path: '/guarantor-account',
      component: GuarantorAccount,
      meta: {
        authRequired: true,
        guarantorUserRequired: true,
      },
      children: [
        {
          path: '/guarantor-account',
          name: 'guarantorBilling',
          component: GuarantorProfile,
          meta: {
            pageTitle: 'Membership Account | Guarantor',
          }
        },
        {
          path: '/guarantor-user',
          name: 'guarantor',
          component: GuarantorUser,
          meta: {
            pageTitle: 'User Account | Guarantor',
          }
        },
      ]
    },
    {
      path: '/agreement',
      name: 'agreement',
      component: GuarantorAgreementForm,
      meta: {
        authRequired: true,
        guarantorUserRequired: true,
        pageTitle: 'Sign the Agreement',
      }
    },
    {
      path: '/agreements/:id',
      name: 'agreements',
      component: GuarantorAgreementSigned,
      meta: {
        pageTitle: 'View Signed Agreement',
      }
    },
    {
      path: '/membership',
      name: 'membership',
      component: GuarantorMembership,
      meta: {
        authRequired: true,
        guarantorUserRequired: true,
        pageTitle: 'Membership',
      }
    },
    {
      path: '/payment-method',
      name: 'paymentMethod',
      component: GuarantorCheckout,
      meta: {
        authRequired: true,
        guarantorUserRequired: true,
        pageTitle: 'Payment Method',
      }
    },
    {
      path: '/update-payment-method',
      name: 'updatePaymentMethod',
      component: GuarantorUpdateCard,
      meta: {
        authRequired: true,
        guarantorUserRequired: true,
        pageTitle: 'Update Card | Guarantor',
      }
    },
    // place these after other pages so other routes match before the root w/ ID param
    {
      path: '/:id/',
      name: 'salesView',
      component: SalesView,
    },
    {
      path: '/:id/sign-up',
      name: 'patientSignUp',
      component: GuarantorSignUp,
      meta: {
        pageTitle: 'Patient Sign Up',
      }
    },
    // 404
    // explicit path used for redirects
    {
      path: "/page-not-found",
      name: '404',
      component: PageNotFound,
      meta: {
        pageTitle: 'Page Not Found',
      }
    },
    // catchall path
    {
      path: "*",
      component: PageNotFound,
      meta: {
        pageTitle: 'Page Not Found',
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  // if page requires authentication
  if ( to.matched.some(record => record.meta.authRequired) ) {
    if (!store.state.user) {
      next({
        path: '/log-in',
        query: { redirect: to.fullPath }
      })
    }
    // if practice User Required
      // only 404 if user_props is loaded, or all pages will 404 on refresh
      // every page route checked in App.vue and 404'd if incorrect
    else if (store.state.user_props && to.matched.some(record => record.meta.practiceUserRequired) && !store.getters.isPracticeUser) {
      // console.log('practice user required')
      next({
        path: '/guarantor-account'
      })
    }
    // if guarantor User Required
      // only 404 if user_props is loaded, or all pages will 404 on refresh
      // every page route checked in App.vue and 404'd if incorrect
    else if (store.state.user_props && to.matched.some(record => record.meta.guarantorUserRequired) && store.getters.isPracticeUser) {
      // console.log('guarantor user required')
      next({
        name: '404'
      })
    }
    else {
      // console.log('passed route reqs')
      next()
    }
  }
  else {
    // console.log('no route reqs')
    next()
  }
})

// update page title
const DEFAULT_TITLE = 'Health Assurance';
router.afterEach((to, from) => {
  if(to.meta.pageTitle) {
    document.title = `${to.meta.pageTitle} | ${DEFAULT_TITLE}`;
  } else {
    document.title = DEFAULT_TITLE;
  }
  // track in GA
  Vue.analytics.logEvent('page_view');
});

export default router
