<template lang="html">
  <layout-public class="log-in">
    <div>
      <h1>Log In</h1>

      <input v-model="email" name="email" class="form-control" placeholder="Enter email" type="text" @keyup.enter="logIn"/>
      <input v-model="password" name="password" class="form-control" placeholder="Enter password" type="password" @keyup.enter="logIn"/>

      <list-errors :errors="errors"></list-errors>

      <div class="d-flex flex-row justify-content-between">
        <p><button @click.prevent="logIn" class="btn btn-app">Log In<i class="far fa-angle-right fa-fw fa-right"></i></button></p>
        <p><router-link to="/reset-password">Forgot password?</router-link></p>
      </div>

      <hr class="hr-minor-color hr-form-footer" />

      <p v-if="!guarantorPracticeUrl">Is your dental group new to Health Assurance? <router-link :to="{path: '/sign-up', query: { redirect: this.$route.query.redirect } }">Sign Up</router-link>.</p>
      <p v-if="guarantorPracticeUrl">Ready to join Health Assurance? <router-link :to="guarantorSignupRoute">Sign Up</router-link>.</p>
    </div>
  </layout-public>
</template>

<script>
import {mapGetters} from 'vuex'
import LayoutPublic from '../components/LayoutPublic.vue'
import ListErrors from '../components/ListErrors.vue'

export default {
  data () {
    return {
      email: '',
      password: '',
      errors: []
    }
  },
  components: {
    LayoutPublic, ListErrors
  },
  computed: {
    ...mapGetters(['user','guarantorPracticeUrl']),
    // get next route from querystring, set in FirebaseApp.js, or default to root
    nextRoute () {
      return this.$route.query.redirect || '/members'
    },
    // guarantor signup route
    guarantorSignupRoute () {
      if(this.guarantorPracticeUrl) {
        return `/${this.guarantorPracticeUrl}/sign-up?redirect=%2Fagreement`;
      }
      return null;
    }
  },
  watch: {
    //  when signUp completes, go to requested page
    user (auth) {
      if(auth) {
        this.$router.replace(this.nextRoute)
      }
    }
  },
  mounted () {
    if(this.user) {
      this.$router.replace(this.nextRoute)
    }
  },
  methods: {
    async logIn () {
      // reset errors
      this.errors = [];
      //  attempt logIn
      let result = await this.$firebase.logIn(this.email, this.password);
      // if result doesn't contain user, it's an error
      // console.log(result);
      if(!result.user) {
        if(result.code == "auth/invalid-email") {
          this.errors.push("Oops, that's not a valid email address.")
        }
        else if(result.code == "auth/wrong-password") {
          this.errors.push("Oops, the password or email address is incorrect.")
        }
        else if(result.code == "auth/user-not-found") {
          this.errors.push("Oops, there is no account using that email address.")
        }
        else {
          this.errors.push(result.message);
        }
      }
    }
  },
}
</script>

<style lang="less" scoped>
</style>
