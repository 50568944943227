<template lang="html">
  <div class="layout-private">
    <div class="container">
      <div class="row row-header">
        <div class="col-4">
          <router-link :to="isPracticeUser ? '/members' : '/guarantor-account'" class="logo">
						<img src="../assets/img/logo-ha.svg" alt="Health Assurance" width="150px" height="52px"><br>
            <p v-if="adminPracticeName" class="light-weight snug logo-practice-name"><em>{{ adminPracticeName }}</em></p>
					</router-link>
        </div> <!-- /.col -->
        <div class="col-8 text-right">
          <p class="d-lg-none snug"><button @click="toggleNav" class="btn btn-nav-toggle btn-app"><i class="far fa-bars"></i></button></p>
          <div id="nav-container" class="nav-container" v-show="showNav">
            <ul class="nav nav-main">
              <li class="nav-item" v-if="isPracticeUser && !onboardingComplete"><router-link to="/set-up" @click.native="toggleNav" class="nav-link" exact-active-class="active">
                Set Up
              </router-link></li>
              <li class="nav-item" v-if="isPracticeUser && onboardingComplete"><router-link to="/members" @click.native="toggleNav" class="nav-link" exact-active-class="active">
                Members
              </router-link></li>
              <li class="nav-item" v-if="isPracticeUser && onboardingComplete"><router-link to="/plans" @click.native="toggleNav" class="nav-link" exact-active-class="active">
                Plans
              </router-link></li>
              <li class="nav-item" v-if="isPracticeUser && onboardingComplete"><router-link to="/edit" @click.native="toggleNav" class="nav-link" exact-active-class="active">
                Sales Page
              </router-link></li>
              <li class="nav-item" v-if="isPracticeUser"><router-link to="/account" @click.native="toggleNav" class="nav-link" exact-active-class="active" :class="{active: ['account','profile'].includes($route.name)}">
                  Account
                </router-link></li>
                <li class="nav-item" v-if="!isPracticeUser"><router-link to="/guarantor-account" @click.native="toggleNav" class="nav-link" exact-active-class="active" :class="{active: ['guarantor-account','guarantor-user'].includes($route.name)}">
                    Account
                  </router-link></li>
              <li class="nav-item"><a class="nav-link" href="#log-out" @click.prevent="logOut">
                Log Out
              </a></li>
            </ul>
          </div> <!-- /.nav-container -->
        </div> <!-- /.col -->
      </div> <!-- /.row -->
      <div class="row row-content">
        <div class="col">
          <slot></slot>
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </div> <!-- /.section -->
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  data () {
    return {
      showNav: false,
    }
  },
  computed: {
    ...mapGetters(['onboardingComplete','isPracticeUser','adminPracticeName']),
  },
  methods: {
    logOut () {
      this.$firebase.logOut()
    },
    toggleNav () {
      this.showNav = !this.showNav;
    },
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.layout-private {
  border-top: 10px solid @box-color;
  background-color: @light-color;
}
.row-header {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: @screen-lg-min) {
  .nav-main {
    margin-top: 14px;
  }
}

.row-content {
  padding-top: 70px;
  padding-bottom: 50px;
}
.logo-practice-name {
  text-decoration: none;
  padding-left: 35px;
  color: @major-color;
  margin-top: 5px;
}
</style>
