<template lang="html">
  <em class="">
    <span v-if="loading" class="loading text-center"><i class="far fa-pulse fa-spinner"></i></span>
    <span v-if="member">{{ memberStatusPretty }}</span>
    <span v-if="accountStatusPretty">{{ accountStatusPretty }}</span>
  </em>
</template>

<script>
import Vue from 'vue'

export default {
  props: ['uid','userType', 'guarantorAccountId'],
  data () {
    return {
      loading: true,
      member: null,
      accountStatus: null,
    }
  },
  computed: {
    accountStatusPretty () {
      if(this.accountStatus && this.accountStatus == "pastDue") {
        return "past due";
      }
      return this.accountStatus;
    },
    memberStatusPretty () {
      if(this.member.subscription_status && this.member.subscription_status == "pastDue") {
        return "past due";
      }
      return this.member.subscription_status;
    },
  },
  mounted() {
    if(this.uid && this.userType && this.guarantorAccountId) {
      // if it's a guarantor, load all their members
      if(this.userType == 'guarantor') {
        Vue.db.ref(`guarantor_accounts/${this.guarantorAccountId}/private/account_status`)
          .once('value')
          .then(snapshot => {
            if(snapshot.val()) {
              this.accountStatus = snapshot.val();
              this.loading = false;
            } else {
              this.accountStatus = "awaiting payment";
              this.loading = false;
              // console.log("error loading guarantor's account status");
            }
          })
          .catch(err => console.log(err));
      }
      // if it's a member, just load the 1 member from guarantor account
      else if(this.userType == 'member') {
        Vue.db.ref(`guarantor_accounts/${this.guarantorAccountId}/private/members/${this.uid}`)
          .once('value')
          .then(snapshot => {
            if(snapshot.val()) {
              this.member = snapshot.val();
              this.loading = false;
            } else {
              // console.log("error loading member's status");
            }
          })
          .catch(err => console.log(err));
      }
    } else {
      // console.error("no guarantor data to load")
    }
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";
</style>
