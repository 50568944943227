<template lang="html">
  <div class="layout-public">
    <div class="container">
      <div class="row">
        <div class="col-auth col-xl-7 col-lg-6 offset-lg-4 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
          <router-link to="/log-in" class="logo">
						<img src="../assets/img/logo-ha.svg" alt="Health Assurance" width="150px" height="52px">
					</router-link>
          <p v-if="practiceName" class="lead practice-name">by {{ practiceName }}</p>
          <hr />
          <slot></slot>
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </div> <!-- /.section -->
</template>

<script>
export default {
  props: ['practiceName'],
  methods: {
    logOut () {
      this.$firebase.logOut()
    },
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.layout-public {
  background-color: @light-color;
}
.layout-public .container {
  overflow: visible;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    right: 91.666%;
    top: 0;
    width: 100%;
    min-height: 100%;
    max-height: 1000px;

    @media (min-width: @screen-md-min) {
      right: 83.333%;
    }
    @media (min-width: @screen-lg-min) {
      right: 66%;
    }
    // @media (min-width: @screen-lg-min) {
    //   right: 66%;
    // }
  }
}

@media (min-width: @screen-sm-min) {
  .layout-public .container:before,
  .layout-public.sign-up .container:before {
    background: url('../assets/img/ha-splash-faces.png') right center no-repeat;
    background-size: 635px 1000px;
  }
  .layout-public.sign-up-practice .container:before {
    background: url('../assets/img/ha-splash-dentists.png') right center no-repeat;
    background-size: auto 1050px;
  }
  .layout-public.log-in .container:before {
    background: url('../assets/img/ha-splash-commuter.png') right center no-repeat;
    background-size: 635px 1000px;
  }
  .layout-public.reset .container:before {
    background: url('../assets/img/ha-splash-writing.png') right top no-repeat;
    background-size: 635px 1000px;
  }
}


.col-auth {
  padding: 100px 15px 100px;


  @media (min-width: @screen-sm-min) {
    padding-left: 8.333%;
    border-left: 10px solid @box-color;
  }
  @media (min-width: @screen-md-min) {
    padding-left: 8.333%;
    border-left: 10px solid @box-color;
  }

  hr {
    margin: 50px 0;
  }
}

.practice-name {
  margin: 15px 0 0;
}
</style>
