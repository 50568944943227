<template lang="html">
  <component v-bind:is="layout">
    <div :class="layout == 'LayoutPrivate' ? 'text-center' : ''">
      <h2>Page not found!</h2>
      <p class="lead">Sorry! The page you were looking for doesn't exist.</p>
      <p>You can <router-link to="/">head back to the homepage</router-link> or <a href="mailto:info@healthassuranceplan.com">drop us a line</a> if you need help.</p>
    </div>
  </component>
</template>

<script>
import {mapGetters} from 'vuex'
import LayoutPublic from '../components/LayoutPublic.vue'
import LayoutPrivate from '../components/LayoutPrivate.vue'

export default {
  components: {
    LayoutPublic, LayoutPrivate
  },
  computed: {
    ...mapGetters(['user']),
    // choose layout depending on login status
    layout () {
      if(this.user) {
        return LayoutPrivate;
      }
      return LayoutPublic;
    },
  },
}
</script>

<style lang="less" scoped>
</style>
