<template lang="html">
  <div class="member-search">

    <h3>Search Members</h3>
    <p class="sm"><em>Search members and guarantors by name or email address</em></p>

    <p v-if="!user_props || !user_props.owned_account_id" class="loading"><i class="far fa-pulse fa-2x fa-spinner major-color"></i></p>

    <ais-instant-search
      v-if="user_props && user_props.owned_account_id"
      :search-client="searchClient"
      :index-name="algoliaIndexName"
      class="row"
    >
      <ais-configure
        :hits-per-page.camel="10"
        :filters="`practice_account_id:${user_props.owned_account_id}`"
      />
      <ais-search-box
        autofocus
        placeholder="Enter a name or email address"
        :show-loading-indicator="true"
        class="col-lg-6 col-md-8"
        :class-names="{
          'ais-SearchBox-form': 'input-group d-flex',
          'ais-SearchBox-input': 'form-control order-1',
          'ais-SearchBox-submit': 'btn btn-outline order-0',
          'ais-SearchBox-reset': 'btn btn-outline order-2',
        }"
      >
        <div slot="submit-icon"><i class="far fa-search"></i></div>
        <div slot="reset-icon"><i class="far fa-times"></i></div>
        <div slot="loading-indicator"><i class="far fa-pulse fa-spinner major-color"></i></div>
      </ais-search-box>

      <div class="col-12 results">
        <div class="results-inner">
          <div class="row row-th">
            <div class="col">name</div>
            <div class="col-4">email</div> <!-- widest col, others resize around it -->
            <div class="col">user type</div>
            <div class="col">account status</div>
            <div class="col-1"></div> <!-- placeholder column -->
          </div>
          <ais-hits class="search-results">
            <router-link
              :to="item.user_type == 'guarantor' ? `/guarantor-profile/${item.guarantor_account_id}` : `/member-profile/${item.objectID}`"
              slot="item"
              slot-scope="{ item }"
              class="row"
            >
              <span class="col">{{ item.name }}</span>
              <span class="col-4"><span v-if="item.email">{{ item.email | trimText(35) }}</span></span>
              <span class="col"><em>{{ item.user_type }}</em></span>
              <membership-status
                class="col"
                :uid="item.objectID"
                :userType="item.user_type"
                :guarantorAccountId="item.guarantor_account_id"
              ></membership-status>
              <span class="indicator-hover col col-1 text-right"><i class="far fa-angle-right"></i></span>
            </router-link>
          </ais-hits>
          <ais-state-results class="search-footer">
            <div slot-scope="{ nbPages, hits }">
              <p v-if="!hits.length" class="no-results"><em>No results found.</em></p>
              <ais-pagination v-if="nbPages > 1" />
            </div>
          </ais-state-results>
        </div> <!-- /.results-inner -->
      </div> <!-- /.col-12 -->

    </ais-instant-search>



  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import MembershipStatus from '../components/MembershipStatus.vue'
import algoliasearch from 'algoliasearch/lite';
import { AisInstantSearch, AisSearchBox, AisHits, AisPagination, AisConfigure, AisStateResults } from 'vue-instantsearch';

export default {
  props: [''],
  data () {
    return {
      algoliaIndexName: process.env.VUE_APP_ALGOLIA_INDEX_NAME,
      searchClient: algoliasearch(
        '02W1HNGNYE', // App ID
        '887613aba6a51e30f13719845a12fe50', // Search-only API Key
      ),
    }
  },
  computed: {
    ...mapGetters(['user_props']),
  },
  components: {
    AisInstantSearch, AisSearchBox, AisHits, AisPagination, AisConfigure, AisStateResults,
    MembershipStatus,
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

h3 {
  margin-bottom: 5px;
}

.member-search /deep/ .ais-SearchBox {
  .form-control {
    margin: 0;
  }
  .ais-SearchBox-submit {
    margin-right: -1px;
  }
  .ais-SearchBox-reset {
    margin-left: -1px;
  }
  // hide browser search input X button
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }
}
@media (max-width: @screen-md-min) {
  .results-inner {
    min-width: 800px;
  }
  .results {
    overflow-x: auto;
  }
}
.row-th {
  font-size: 16px;
  padding: 10px;
  margin: 30px 0 0;
  border-top: 1px solid @text-color;
  color: #666;

  > .col:first-child {
    padding-left: 0;
  }
  > .col:last-child {
    padding-right: 0;
  }
}
.search-results {

  /deep/ ol.ais-Hits-list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    > li a.row {
      color: @text-color;
      text-decoration: none;
      background-color: none;
      padding: 10px;
      margin: 0 0 10px;

      > .col:first-child {
        padding-left: 0;
      }
      > .col:last-child {
        padding-right: 0;
      }

      &:hover {
        color: @text-color;
        background-color: @box-color;
      }
      .indicator-hover {
        visibility: hidden;
      }
      &:hover .indicator-hover {
        visibility: visible;
      }
    }
  }
}
.member-search /deep/ .ais-Pagination {
  margin-top: 100px;

  > ul.ais-Pagination-list {
    list-style-type: none;
    text-align: center;
    margin: 0;
    padding: 0;

    > li {
      display: inline-block;
      margin: 0 10px;

      span {
        padding: 5px 10px;
        color: #ccc;
      }
      a {
        text-decoration: none;
        padding: 5px 10px;
      }
      a:hover {
        color: @dark-color;
      }
    }
    > li.ais-Pagination-item--selected {

      a {
        color: @light-color;
        background-color: @dark-color;
      }
    }
  }
}

.search-footer {
  .no-results {
    margin-top: 30px;
    color: #666;
  }
}
</style>
