<template lang="html">
  <layout-private>
    <div class="">
      <h2>Plans</h2>
    </div>
    <div v-if="!isAccountReady">
      <p>Finish setting up your account to begin creating member plans.</p>
      <p><router-link class="btn btn-app" to="/set-up">Continue Set Up<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
    </div>
    <div v-if="account && isAccountReady && account.restricted && (!plans || account.restricted.plan_count < account.restricted.plan_count_limit || account.restricted.additional_plan_count_limit == 'unlimited' || account.restricted.additional_plan_count < account.restricted.additional_plan_count_limit)" class="plan-create">
      <h4 v-if="plans">
        {{ account.restricted.plan_count }} of {{ account.restricted.plan_count_limit }} Primary Plans Created
        <span v-if="account.restricted.additional_plan_count_limit == 'unlimited'"> + Unlimited Additional Plans</span>
        <span v-if="account.restricted.additional_plan_count_limit !== 'unlimited' && account.restricted.additional_plan_count < account.restricted.additional_plan_count_limit"> + {{ account.restricted.additional_plan_count }} of {{ account.restricted.additional_plan_count_limit }} Additional Plans</span>
      </h4>
      <h4 v-if="!plans">Create Your First Membership Plan</h4>
      <p>Your Health Assurance account allows you to create up to {{ account.restricted.plan_count_limit }} primary membership plans<span v-if="account.restricted.additional_plan_count_limit"> and {{ account.restricted.additional_plan_count_limit }} additional plans</span>.</p>
      <router-link to="/plan-details" class="btn btn-app">Create Plan <i class="far fa-angle-right fa-fw fa-right"></i></router-link>
    </div>
    <div v-if="plans" class="">
      <plan-preview-private v-for="(value, plan) in plans" :planId="plan" :key="plan"></plan-preview-private>
    </div>
  </layout-private>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import PlanPreviewPrivate from '../components/PlanPreviewPrivate.vue'

export default {
  props: [''],
  data () {
    return {
      loading: true,
      plans: null,
    }
  },
  components: {
    LayoutPrivate, PlanPreviewPrivate
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'account']),
    isAccountReady () {
      if( this.account && this.account.private && ['active', 'pastDue', 'pending'].includes(this.account.private.subscription_status) && this.account.restricted && this.account.restricted.stripe_connected_account_id ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    account () {
      this.getPlans();
    },
  },
  methods: {
    getPlans () {
      if(this.account && this.account.public && this.account.public.plans && Object.keys(this.account.public.plans).length) {
        this.plans = this.account.public.plans;
      }
    },
  },
  mounted () {
    this.getPlans();
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.plan-create {
  background-color: @box-color;
  margin: 0 0 50px;
  padding: 50px;
  border-top: 1px solid @major-color;

  h4 {
    margin-bottom: 5px;
  }
}
</style>
