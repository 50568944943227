<template lang="html">
  <div class="col">
    <div v-if="!reauthenticate">

      <h3 class="light-weight">Change Your Email Address</h3>
      <input v-model="newEmail" name="email" class="form-control" placeholder="Enter email" type="text" />

      <list-errors :errors="errors"></list-errors>

      <p v-if="updated"><i class="far fa-check"></i> account successfully updated</p>

      <button v-if="!loading" @click.prevent="updateUser" class="btn btn-app">Save All Changes<i class="far fa-angle-right fa-fw fa-right"></i></button>
      <i v-if="loading" class="far fa-spinner-third fa-spin fa-2x"></i>
    </div>
    <div v-if="reauthenticate">
      <h3>Please Log In Again</h3>
      <ul class="list-errors" v-if="errors.length">
        <li v-for="(error, index) in errors" :key="index">
          <i class="far fa-fw fa-times"></i>
          <small>{{ error }}</small>
        </li>
      </ul>
      <button @click.prevent="logOut" class="btn btn-app">Log In<i class="far fa-angle-right fa-fw fa-right"></i></button>
    </div>

    <hr />

    <h3 class="light-weight">Change Your Password</h3>
    <router-link to="/reset-password" class="btn btn-app">Reset Password<i class="far fa-angle-right fa-fw fa-right"></i></router-link>
  </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import Firebase from 'firebase/app'
import 'firebase/auth'
import ListErrors from '../components/ListErrors.vue'

export default {
  props: ['plans'],
  components: {
    ListErrors
  },
  data () {
    return {
      newEmail: null,
      errors: [],
      loading: false,
      updated: false,
      reauthenticate: false,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'account']),
    email() {
      return this.user.email
    },
  },
  methods: {
    logOut () {
      this.$firebase.logOut()
    },
    updateUser() {
      let promises = [];
      let user = Firebase.auth().currentUser;
      this.loading = true;
      this.updated = false;
      let disallowedNames = ["", " ", "  "];
      // reset errors
      this.errors = [];

      let data = {};

      // if email changed, update it
      if(this.newEmail !== this.user.email) {
        let updateEmailPromise = user.updateEmail(this.newEmail);
        data[`/user_props/${user.uid}/email`] = this.newEmail;
        promises.push(updateEmailPromise)
      }

      if(promises.length) {
        Promise.all(promises)
          .then(results => {
            // const [emailResult, profileResult] = results;
            // console.log("updateUser results:",results);
            this.loading = false;
            this.updated = true;
            // if email update w/ firebase auth as successful, then store it in user_props
            if(Object.keys(data).length) {
              return Vue.db.ref().update(data);
            }
            return true;
          })
          .catch(error => {
            // console.log("updateUser error:",error);
            this.loading = false;

            if(error.code == "auth/invalid-email") {
              this.errors.push("Oops, that's not a valid email address.")
            }
            else if(error.code =="auth/requires-recent-login") {
              this.errors.push("To protect your security, you need to log in again before you can update your email address.")
              this.reauthenticate = true;
            }
            else if(error.code =="auth/network-request-failed") {
              this.errors.push("We couldn't udpate your account because of a network error. Please check your internet connection and try again.")
            }
            else if(error.message) {
              this.errors.push(error.message);
            }
          });
      } else {
        this.errors.push("No changes to save. Edit your email address or display name first.")
        this.loading = false;
      }

    },
  },
  mounted () {
    // fill email box on page load
    if(this.user) {
      this.newEmail = this.user.email;
    }
  },

}
</script>

<style lang="less" scoped>

.input-display-name {
  margin: 0;
}
h3 {
  margin: 0 0 10px;
}
h3.tall {
  margin: 50px 0 10px;
}
a .fa-fw {
  margin-right: 5px;
}
hr {
  margin: 50px 0;
}
</style>
