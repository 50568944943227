<template lang="html">
  <div class="plan-preview-private">
    <hr />
    <p v-if="loading" class="loading text-center"><i class="far fa-pulse fa-2x fa-spinner major-color"></i></p>

    <div v-if="plan" class="row">
      <div class="col-md-4">
        <span v-if="isPracticeUser && plan.archived" class="badge badge-app">archived</span>
        <h3>{{ plan.name }}</h3>
        <div v-if="plan.prices && Object.keys(plan.prices).length">
          <h4 v-for="price in plan.prices" v-if="!price.archived">${{ price.amount/100 }} / {{ price.interval }}</h4>
        </div>
        <p v-if="isPracticeUser"><em>{{ plan.plan_type }} plan</em></p>
        <p v-if="!plan.prices || !Object.keys(plan.prices).length" class="error-color"><i class="far fa-exclamation-circle"></i> No active prices</p>
        <p v-if="isPracticeUser"><router-link :to="'/plan-details/' + planId" class="btn btn-app">Edit Plan <i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
      </div> <!-- .col -->
      <div class="col-md-8 benefits">
        <p><strong>Assured Preventive Health Services</strong></p>
        <div v-html="$sanitize(plan.benefits_preventative)"></div>
        <p><strong>Health Restorative Services</strong></p>
        <div v-html="$sanitize(plan.benefits_restorative)"></div>
        <p><strong>Special Additions</strong></p>
        <div v-html="$sanitize(plan.benefits_special)"></div>
        <p><strong>Who is this good for?</strong></p>
        <div v-html="$sanitize(plan.benefits_patient_type)"></div>
      </div> <!-- .col -->
    </div> <!-- .row -->

  </div> <!-- .row -->
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'

export default {
  props: ['planId'],
  data () {
    return {
      loading: true,
      plan: null,
    }
  },
  computed: {
    ...mapGetters(['isPracticeUser']),
  },
  mounted () {
    Vue.db.ref(`/plans/${this.planId}`)
      .once('value')
      .then(snapshot => snapshot.val())
      .then(planVal => {
        if(planVal) {
          // console.log("found plan", planVal);
          this.plan = planVal;
          this.loading = false;
        } else {
          // console.log("couldn't find that plan", planVal);
        }
      })
      .catch(err => console.log(err));
  },
}
</script>

<style lang="less" scoped>
.plan-preview-private {
  padding-bottom: 50px;

  > hr {
    margin: 0 0 50px;
  }
  h3, h4 {
    margin: 0 0 20px;
    line-height: 1em;
  }
}
.badge {
  margin-bottom: 10px;
}
.benefits {
  > p {
    margin: 20px 0;
  }
  ul {
    margin: 0;
  }
}
</style>
