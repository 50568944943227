<template lang="html">
  <layout-private>
    <div class="row">

    <div class="col-12 text-center">
      <h2 v-if="!this.account">Start Your Subscription</h2>
      <h2 v-if="this.account && !this.isReactivating">Change Your Subscription</h2>
      <h2 v-if="this.account && this.isReactivating">Restart Your Subscription</h2>
    </div> <!-- /.col-x -->

    <div class="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2">
      <div v-for="(plan, index) in plans" :key="plan.id">
        <a
          class="plan"
          :class="[
            plan.highlighted ? 'plan-highlighted' : '',
            currentPlanId == plan.id ? 'plan-current' : '',
            selectedPlan && selectedPlan.id == plan.id ? 'plan-selected' : ''
            ]"
          :href="'subscription/?plan=' + plan.name"
          @click.prevent="selectPlan(plan)"
        >
          <p v-if="currentPlanId == plan.id" class="current tag"><strong>current</strong></p>
          <!-- <span>{{ currentPlanId }} // {{ plan.id }}</span> -->
          <h3>
            <i class="selected far fa-fw fa-check"></i>
            <i v-if="index==0" class="icon-plan far fa-thumbs-up"></i>
            <i v-if="index==1" class="icon-plan far fa-chart-line"></i>
            <i v-if="index==2" class="icon-plan fas fa-trophy"></i>
            <strong>{{ plan.name }}</strong> Plan
          </h3>
          <div class="details">
            <ul class="list-unstyled">
              <li v-for="detail in plan.details">{{ detail }}</li>
            </ul>
            <p>{{ plan.price }}</p>
          </div>
        </a>
      </div> <!-- /v-for -->
    </div> <!-- /.col-x -->

    <div v-if="!this.account && !this.isReactivating && selectedPlan" class="col-12 col-lg-8 offset-lg-2 text-center">
      <h3>Payment</h3>
      <p>You'll pay {{ selectedPlan.price }}, starting today. Cancel anytime.</p>
    </div>
    <!-- /.col-x -->


    <div v-if="this.account && !this.isSwitchingPlan && this.isReactivating && selectedPlan" class="col-12 col-lg-8 offset-lg-2 text-center">
      <h3>Verify Payment Method</h3>
      <p>You'll pay {{ selectedPlan.price }}. Cancel anytime. <br class="d-none d-md-inline" /><span v-if="this.account.restricted.stripe_current_period_end">You won’t be charged until {{ this.currentPeriodEndPretty }}.</span></p>
    </div>
    <!-- /.col-x -->

    <div v-if="this.account && this.isSwitchingPlan && !this.isReactivating && selectedPlan" class="col-12 col-lg-6 offset-lg-3 text-center">
      <h3>Confirm Payment</h3>
      <p><strong>Your current card</strong> will be billed {{ selectedPlan.price }} from now on.</p>
      <p><strong>No charge today.</strong> Your next payment on {{ this.currentPeriodEndPretty }} will be prorated for the rest of this month.</p>
    </div>
    <!-- /.col-x -->

    <div v-show="!this.account || this.isReactivating" class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center">
      <div ref="card"></div>
      <list-errors :errors="errors"></list-errors>
    </div> <!-- /.col-x -->

    <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center">
      <p v-if="canDowngrade">
        <button v-if="!loading" v-show="!this.account" @click.once.prevent="createStripeToken" class="btn btn-app">Pay Now<i class="far fa-angle-right fa-fw fa-right"></i></button>
        <button v-if="!loading" v-show="this.account && this.isSwitchingPlan" @click.once.prevent="switchPlan" class="btn btn-app">Switch to the {{this.selectedPlan.name}} Plan<i class="far fa-angle-right fa-fw fa-right"></i></button>
        <button v-if="!loading" v-show="this.account && this.isReactivating" @click.once.prevent="createStripeToken" class="btn btn-app">Restart with the {{this.selectedPlan.name}} Plan<i class="far fa-angle-right fa-fw fa-right"></i></button>
        <i v-if="loading" class="far fa-spinner-third fa-spin fa-2x"></i>
      </p>
      <div v-if="!canDowngrade" class="alert alert-app">
        <p class="snug"><strong>Before you can downgrade your subscription,</strong> you need to archive membership plans to match your new plan's limits.</p>
      </div>
      <p><router-link to="/account" class="snug">back to account</router-link></p>
    </div> <!-- /.col-x -->

  </div> <!-- /.row -->
  </layout-private>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import moment from 'moment'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import ListErrors from '../components/ListErrors.vue'

// set up Stripe
let stripe = Stripe(process.env.VUE_APP_STRIPE_KEY),
    elements = stripe.elements(),
    card = undefined;

export default {
  props: ['plans', 'stripeElementsOptions'],
  data () {
    return {
      selectedPlan: this.plans[0],
      currentPlanId: null,
      errors: [],
      loading: false,
    }
  },
  components: {
    LayoutPrivate, ListErrors
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'account']),
    canDowngrade () {
      // if no account, let them through
      if(!this.account) {
        return true;
      }
      // allow downgrade if within new plan's limits
      if(this.account && this.account.restricted && this.selectedPlan && this.account.restricted.plan_count <= this.selectedPlan.plan_count_limit && (!this.account.restricted.additional_plan_count || this.selectedPlan.additional_plan_count_limit == 'unlimited' || this.account.restricted.additional_plan_count < this.selectedPlan.additional_plan_count_limit) ) {
        return true;
      }
      return false;
    },
    isSwitchingPlan () {
      if(this.account) {
        // only upgrading/downgrading if in the active or trialing status_transitions
          // status of pending, cancelled, or closed is not an upgrade, and should be able to pick any plan
        if(["active","trialing"].includes(this.account.private.subscription_status)) {
          return true;
        }
      }
      return false;
    },
    isReactivating () {
      if(this.account) {
        // only upgrading/downgrading if in the active or trialing status_transitions
          // status of pending, cancelled, or closed is not an upgrade, and should be able to pick any plan
        if(["expired","closed","cancelled"].includes(this.account.private.subscription_status)) {
          return true;
        }
      }
      return false;
    },
    currentPeriodEndPretty() {
      return this.prettyDate(this.account.restricted.stripe_current_period_end);
    },
  },
  watch: {
    // watching for account in store to set up page
      // note: this.account will be set to false when app mounts, then set again in app.vue if user_props data found in FirebaseApp.js
    account (newValue, oldValue) {
      if(this.account) {
        this.setupPlans(newValue);
      }
    }
  },
  methods: {
    prettyDate(timestamp) {
      return moment.unix(timestamp).format("MMM Do, YYYY");
    },
    setupPlans(acct) {
      // set current plan if we find a plan
      if(acct.private.stripe_practice_plan_id) {
        // if they are not reactivating, highlight their current plan
        if(!this.isReactivating) {
          this.setCurrentPlan();
        }
      }
    },
    selectPlan (plan) {
      // console.log(this.currentPlanId, plan.id)
      if(!this.currentPlanId || this.currentPlanId !== plan.id) {
        this.selectedPlan = plan;
      } else {
        // console.log("can't choose currently active plan");
      }
    },
    setCurrentPlan () {
      // set the current plan, but only if the subscription isn't currently cancelled, pending, or closed
      if(!["cancelled","pending","closed","expired"].includes(this.account.private.subscription_status)) {
        this.currentPlanId = this.account.private.stripe_practice_plan_id;
      }
      // if the current plans turns out to be the selected plan, select a diff plan
      if(this.currentPlanId == this.selectedPlan.id) {
        // if the current plan is the usual default, choose team plan
        if(this.currentPlanId == this.plans[1].id) {
          this.selectedPlan = this.plans[2];
        }
        // otherwise set usual default plan (pro)
        else {
          this.selectedPlan = this.plans[1];
        }
      }
    },
    switchPlan () {
      this.loading = true;
      let data = {};
      data[`accounts/${this.user_props.owned_account_id}/private/stripe_practice_plan_id`] = this.selectedPlan.id;
      data[`accounts/${this.user_props.owned_account_id}/private/subscription_status`] ='pending';
      // save all updates to database
      Vue.db.ref().update(data)
        .then(result => {
          // console.log(result);
          this.$router.push('/account');
        })
        .catch(err => {
          console.log("error creating account",err);
          this.loading = false;
          this.errors.push(err);
        })
    },
    createStripeToken () {
      // reset errors
      this.errors = [];
      this.loading = true;
      // create token
      stripe.createToken(card).then((result) => {
        // console.log(result);
        // return;
        if (result.error) {
          console.log(result.error.message);
          this.errors.push(result.error.message);
          this.loading = false;
          return;
        }
        let data = {};
        // if they already own an account, this is a 2nd attempt to process payment info
        if(this.account) {
          // updating stripe_practice_plan_id fires updateSubscription function
          data[`accounts/${this.user_props.owned_account_id}/private/stripe_practice_plan_id`] = this.selectedPlan.id;
          // store new token in case they are reactivating subscription to fire updateCustomer cloud func
          data[`accounts/${this.user_props.owned_account_id}/private/stripe_token`] = result.token.id;
          data[`accounts/${this.user_props.owned_account_id}/private/subscription_status`] ='pending';
        }
        // if they don't have an account, create one and set as owner
        else {
          // creating stripe_token fires createCustomer function, which sets a stripe_practice_customer_id which then fires createSubscription function
          let newAccountKey = Vue.db.ref().child('accounts').push().key;
          // add account id to user_props
          data[`user_props/${this.user.uid}/owned_account_id` ] = newAccountKey;
          // add new account data
          data[`accounts/${newAccountKey}`] = {
            private: {
              stripe_practice_plan_id: this.selectedPlan.id,
              stripe_token: result.token.id,
              owner_uid: this.$store.state.user.uid,
              subscription_status: 'pending'
            }
          }
        }
        // save all updates to database
        Vue.db.ref().update(data)
          .then(result => {
            // console.log(result);
            this.$router.push('/account');
          })
          .catch(err => {
            console.log("error creating account",err);
            this.loading = false;
            this.errors.push(err);
          })

      })
      .catch(err => {
        console.log(err.message);
        this.errors.push(err.message);
        this.loading = false;
      })
      ; // /createToken.then
    } // /createStripeToken
  },
  mounted () {
    this.selectedPlan = this.plans[0];
    // attempt to setup plans, otherwise wait for account prop watcher to setup
    if(this.account) {
      this.setupPlans(this.account);
    }
    // set selectedPlan from querystring
    if(this.$route.query.plan) {
      let defaultPlan = this.plans.find(plan => plan.name.toLowerCase() == this.$route.query.plan.toLowerCase());
        // console.log(defaultPlan);
      if(defaultPlan) {
        this.selectedPlan = defaultPlan;
        // console.log("plan set from querystring", this.selectedPlan);
      }
    }
    // create Stripe elements form
    if(!card) {
      card = elements.create('card',  this.stripeElementsOptions);
    }
    // only mount if the element exists
    if(this.$refs.card) {
      card.mount(this.$refs.card);
      // listen for card errors
      card.addEventListener('change', (event) => {
        // reset errors
        this.errors = [];
        // add any new errors
        if (event.error) {
          this.errors.push(event.error.message);
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

a.plan {
  display: block;
  padding: 20px;
  border: 1px solid @major-color;
  margin-bottom: 20px;
  position: relative;
  text-decoration: none;
  transition: all 250ms ease-out;
  background-color: @light-color;

  h3, ul li, p {
    color: @dark-color;
  }
  ul {
    margin: 0;
  }
  p {
    margin: 0;
  }
  h3 {
    text-transform: capitalize;
    font-size: 24px;
    line-height: 1em;
    margin: 0;
  }
  .icon-plan {
    font-size: 20px;
    margin-right: 15px;
  }
  .selected {
    // for animation
    opacity: 0;
    transform: scale(.5);
    transition: all 125ms ease-out;
    width: 0;
  }
  .details {
    display: none;
  }
}
a.plan:hover {
  background-color: @major-color;
  border-color: @major-color;

  h3, ul li, p {
    color: @light-color;
  }
}
a.plan.plan-highlighted {
  border-color: @major-color;
  // background-color: @box-color;
}
a.plan.plan-selected {
  background-color: @dark-color;
  border-color: transparent;
  cursor: default;

  h3, ul li, p {
    color: @light-color;
  }
  h3 {
    margin: 0 0 10px;
  }
  .icon-plan {
    display: none;
  }
  .selected {
    transform: scale(1);
    opacity: 1;
    width: 1.25em;
    margin-right: 5px;
  }
  .details {
    display: block;
  }
}
a.plan.plan-current {
  .details {
    display: block;
  }
}
a.plan.plan-current,
a.plan.plan-current:hover,
a.plan.plan-highlighted.plan-current:hover {
  cursor: default;
  border-color: #ccc;
  background-color: @light-color;

  h3, ul li, p {
    color: @dark-color;
  }
  .current.tag {
    margin: 0 10px 10px 0;
    color: @light-color;
    background-color: @dark-color;
    padding: 10px;
    font-size: 14px;
    line-height: 1em;
    display: inline-block;
    vertical-align: middle;
  }
  h3 {
    display: inline-block;
    line-height: 24px;

    .icon-plan {
      display: none;
    }
  }
}
.fa-spin {
  animation: fa-spin 1s infinite linear;
}


</style>
