<template lang="html">
  <div v-if="plan && plan.prices && Object.keys(plan.prices).length && !plan.archived" class="plan-preview-public">
    <div class="row">
      <div class="col-sm-10 offset-sm-1">
        <h3 class="major-color"><strong>{{ plan.name }}</strong> Plan</h3>
        <hr>
      </div> <!-- /.col-x -->
    </div> <!-- /.row -->

    <div class="row row-plan-details">
      <div class="col-sm-5 offset-sm-1 benefits">
        <p><strong>Assured Preventive Health Services</strong></p>
        <div v-html="$sanitize(plan.benefits_preventative)"></div>
        <p><strong>Health Restorative Services</strong></p>
        <div v-html="$sanitize(plan.benefits_restorative)"></div>
        <p><strong>Special Additions</strong></p>
        <div v-html="$sanitize(plan.benefits_special)"></div>
        <p><strong>Who is this good for?</strong></p>
        <div v-html="$sanitize(plan.benefits_patient_type)"></div>
      </div> <!-- /.col-x -->
      <div class="col-sm-5">
        <h4 class="snug">Price Per Member</h4>
        <div>
          <p v-for="price in plan.prices" v-if="!price.archived" class="plan-price">${{ price.amount/100 }} / {{ price.interval }}</p>
        </div>
      </div> <!-- /.col-x -->
    </div> <!-- /.row -->

  </div> <!-- .row -->
</template>

<script>
import Vue from 'vue'

export default {
  props: ['plan', 'pageUrl'],
  data () {
    return {
      loading: true,
    }
  },
}
</script>

<style lang="less" scoped>
.plan-preview-public {
  margin-top: 50px;
}
h3 {
  margin-bottom: 20px;
}
.plan-price {
  margin: 0;
}
.plan-price:last-child:not(:first-child):before {
  content: 'or';
  display: block;
  font-style: italic;
}
.benefits {
  > p {
    margin: 20px 0;
  }
  > p:first-child {
    margin-top: 0;
  }
  ul {
    margin: 0;
  }
}
</style>
