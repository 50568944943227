<template lang="html">
  <ul class="list-errors" v-if="errors.length">
    <li v-for="(error, index) in errors" :key="index">
      <small><i class="far fa-fw fa-times"></i>{{ error }}</small>
    </li>
  </ul>
</template>

<script>
export default {
  props: ['errors'],
}
</script>

<style lang="less" scoped>
</style>
