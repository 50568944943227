<template lang="html">
  <layout-private>
    <div class="">
      <h2>Sales Page</h2>
      <hr />
      <div v-if="!isAccountReady">
        <p>Finish setting up your account to publish your sales page.</p>
        <p><router-link class="btn btn-app" to="/set-up">Continue Set Up<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
      </div>
      <div class="row" v-if="isAccountReady">
        <div class="col-lg-8">
          <p class="lead">Edit the content for your practice sales page below. <span v-if="!published">We've added some default text to get you started. </span></p>
          <p class="instructions"><em><strong>Tip:</strong></em> You can type <code>&lbrace;practiceName&rbrace;</code> to automatically insert the name of your dental practice into your sales page text.</p>

          <div v-if="published" class="sales-url-reference">
            <p v-if="!disabled" class="lead">Sales Page Status: <strong>Active</strong></p>
            <p v-if="disabled" class="lead">Sales Page Status: <strong>Unpublished</strong></p>
            <p><a :href="fullSalesUrl" target="_blank" class="btn btn-app">View Your Membership Sales Page <i class="far fa-fw fa-external-link fa-right"></i></a></p>
          </div>

          <label>Practice Name <small><em>35 characters max</em></small></label>
          <input v-model="practiceName" @input="setEdited" class="form-control" placeholder="Enter practice name" type="text" />

          <label>Sales Page URL <small><em>35 characters max, no spaces or special characters</em></small></label>
          <input v-model="salesURL" @input="setEdited" class="form-control input-url" placeholder="Enter practice name" type="text" />
          <p class="small url-meta">
            <i v-if="checkingUrl" class="far fa-pulse fa-spinner major-color"></i>
            <span v-if="urlTaken && salesURL" class="error-color"><i class="far fa-times"></i> taken</span>
            <span v-if="!urlTaken && salesURL" class="success-color"><i class="far fa-check"></i> available</span>
            <span class="dark-color">Preview:</span> {{ salesDomain }}/<strong>{{ salesURL }}</strong>
          </p>

          <label>Headline</label>
          <input v-model="headline" @input="setEdited" class="form-control" placeholder="Enter headline" type="text" />

          <label>Lead Paragraph</label>
          <textarea v-model="lead" @input="setEdited" class="form-control" placeholder="Enter lead paragraph" rows="6"></textarea>
          <!-- <p>{{ lead | fillPracticeName(practiceName) }}</p> -->

          <label>Icon #1 Label</label>
          <input v-model="icon1Label" @input="setEdited" class="form-control" placeholder="Enter icon #1 label" type="text" />

          <label>Icon #2 Label</label>
          <input v-model="icon2Label" @input="setEdited" class="form-control" placeholder="Enter icon #1 label" type="text" />

          <label>Icon #3 Label</label>
          <input v-model="icon4Label" @input="setEdited" class="form-control" placeholder="Enter icon #1 label" type="text" />

          <label>Icon #4 Label</label>
          <input v-model="icon4Label" @input="setEdited" class="form-control" placeholder="Enter icon #1 label" type="text" />

          <label>FAQ Headline</label>
          <input v-model="faqHeadline" @input="setEdited" class="form-control" placeholder="Enter FAQ headline" type="text" />

          <label>FAQ text</label>
          <rich-editor v-on:editor-html="faqText = $event" :defaultText="faqText"></rich-editor>
          <!-- <p>{{ faqText | fillPracticeName(practiceName) }}</p> -->

          <label>Contact Headline</label>
          <input v-model="contactHeadline" @input="setEdited" class="form-control" placeholder="Enter contact headline" type="text" />

          <label>Contact Phone Number or Detail</label>
          <input v-model="contactDetail" @input="setEdited" class="form-control" placeholder="Enter contact phone number or detail" type="text" />

          <list-errors :errors="errors"></list-errors>

          <div v-if="updated && fullSalesUrl" class="alert alert-app"><i class="far fa-fw fa-check"></i>Success! Your sales page was published successfully. <a :href='fullSalesUrl' target="_blank">View Now</a></div>

          <p><button @click.prevent="publishMeta" :disabled="!edited && !disabled" class="btn btn-app">
            <span v-if="!published">Save &amp; Publish</span>
            <span v-if="published">Save Updates</span>
            <i class="far fa-angle-right fa-fw fa-right"></i></button></p>
        </div> <!-- /.col-x -->
      </div> <!-- /.row -->

    </div>
  </layout-private>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import ListErrors from '../components/ListErrors.vue'
import RichEditor from '../components/RichEditor.vue'

export default {
  props: [''],
  components: {
    LayoutPrivate, ListErrors, RichEditor,
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'account']),
    isAccountReady () {
      if( this.account && this.account.private && ['active', 'pastDue', 'pending'].includes(this.account.private.subscription_status) && this.account.restricted && this.account.restricted.stripe_connected_account_id ) {
        return true;
      }
      return false;
    },
    salesDomain () {
      if(process.env.VUE_APP_ENV === "staging") {
        return "https://health-assurance-staging.firebaseapp.com"
      }
      if(process.env.NODE_ENV === "production") {
        return "https://app.healthassuranceplan.com"
      }
      if(process.env.NODE_ENV === "development") {
        return "http://localhost:8080"
      }
    },
    fullSalesUrl () {
      if(this.salesURL) {
        return `${this.salesDomain}/${this.salesURL}`
      }
      return false;
    }
  },
  data () {
    return {
      errors: [],
      editor: null,
      page_id: null,
      updated: false,
      edited: false,
      urlTaken: false,
      checkingUrl: false,
      savedMeta: null,
      // sales page content
      published: false,
      disabled: null,
      practice_account_id: null,
      practiceName: null,
      salesURL: null,
      headline: "Better dental benefits made affordable for everyone",
      lead: "Join the Health Assurance membership plan at {practiceName} and save money on dental care. You pay a monthly fee just like insurance, and you get the discounts and included services listed below. It’s like dental insurance, but better. No dealing with insurance claims, confusing coverage, or waiting periods. Just go to your dentist and save money. Get the care you need without breaking your budget.",
      icon1Label: "Better Dental Benefits, No Surprise Bills",
      icon2Label: "Need Help? Just Talk to Your Dentist, Not a Third Party",
      icon3Label: "Get Affordable Dental Care without Insurance",
      icon4Label: "Or, Get New Benefits Your Insurance Doesn’t Offer",
      faqHeadline: "Questions? We have answers.",
      faqText: `<h3>How do I use it?</h3>
      <p>Your dentist has opted to make this membership available to you. Just purchase your subscription on this website, and next time you visit your dentist tell them you are a Health Assurance member. They'll look you up, confirm your subscription, and give you the appropriate discounts and services. You'll also get a card you can print out to keep in your wallet.</p>
      <h3>Who can join?</h3>
      <p>Individuals, families, and even companies are welcome to join.</p>
      <p>Health Assurance is only available to patients who do not have dental insurance. Health Assurance membership benefits cannot be used alongside dental insurance.</p>
      <h3>Is this insurance?</h3>
      <p>No, this is not insurance. Health Assurance is a discount membership plan offered by your dentist. Health Assurance will not pay for any dental services or require you to apply.</p>
      <p>Simply purchase a membership, and then get discounts at your dentist. That's it! No paperwork or claims process.</p>
      <h3>What if I let my membership lapse?</h3>
      <p>If your membership lapses, you will no longer receive the benefits included in the membership. Any dental services that happen while your membership is lapsed will not be eligible for discounts.</p>
      <p>In addition, any guarantees your dentist offers as part of the membership will end. For example, if your dentist offers a 3-year guarantee on all dentistry to Health Assurance members, and your membership lapses after 1 year, the guarantee will no longer be valid after the lapse.</p>
      <h3>What does the 3-year guarantee cover?</h3>
      <p>The guarantee covers all dentistry performed during the Health Assurance membership up to 3 years from the date it occurred. If more advanced treatment is required to reach desired outcome, your original investment will be applied as a discount against additionally needed care.</p>
      <h3>Can I buy Health Assurance for a friend?</h3>
      <p>Yes, you can purchase a Health Assurance membership on behalf of others. Simply purchase the subscription and you will have an opportunity to enter your friend's name as the member who receives benefits.</p>
      <h3>What if I need a service that is not listed on the menu?</h3>
      <p>If you need a service not listed on or covered by the membership, your dentist will charge you the normal price.</p>
      <h3>What if the membership fee or benefits change?</h3>
      <p>You will never be charged an increased membership fee without your direct permission. Your dentist will notify you of any plan changes. You can cancel your membership by choosing not to renew your annual membership.</p>
      <h3>How much could dental care cost with vs without Health Assurance?</h3>
      <p>Your dentist can give you a treatment plan with normal fees as well as a treatment plan with health assurance fees so you can transparently understand cost difference.</p>
      <h3>Can I use my Health Assurance membership with other dental practices?</h3>
      <p>No, your membership is only valid at {practiceName}.</p>`,
      contactHeadline: "Need help? Just call us at {practiceName}:",
      contactDetail: "",
    }
  },
  watch: {
    // when rich text editor updates faqText, set edited
    faqText (n, o) {
      if(n !== o && !this.edited) {
        this.setEdited()
      }
    },
    // when account is set, load page meta info
    account () {
      this.loadMeta();
    },
    // auto format as url
    salesURL (newVal) {
      // strip special chars
      let urlEncoded = newVal
        .replace(/\s/gi,"-")
        .replace(/[^\w\s\-]/gi, '')
        .toLowerCase();
      // limit length
      if(urlEncoded.length > 35) {
        urlEncoded = urlEncoded.slice(0,35);
      }
      // return encodeURI(urlEncoded);
      this.$set(this, 'salesURL', urlEncoded );

      // if none exists or doesn't match previously saved meta, check if this url is taken
      if(!this.savedMeta || (this.savedMeta && this.savedMeta.salesURL !== this.salesURL)) {
        let isUniqueURLPromise = Vue.db.ref(`/sales_page_meta/`)
          .orderByChild('salesURL')
          .equalTo(this.salesURL)
          .on('value', (metaSnapshot) => {
            // console.log('checked!')
            if(metaSnapshot.val()) {
              this.urlTaken = true;
            } else {
              this.urlTaken = false;
            }

          });
      }

    },
    // autofill sales URL if it hasn't been published
    practiceName (newVal) {
      if(!this.published) {
        this.$set(this, 'salesURL', this.practiceName );
      }
    },
  },
  methods: {
    setEdited () {
      if(!this.edited) {
        this.edited = true;
      }
    },
    // load any pre-existing data
    loadMeta () {
      if(this.account && this.account.public && this.account.public.sales_page_meta_id) {
        // set page id for when we call publishMeta
        this.page_id = this.account.public.sales_page_meta_id
        // load the existing data
        let metaRef = Vue.db.ref(`/sales_page_meta/${this.account.public.sales_page_meta_id}`).once('value');
        metaRef.then((metaSnapshot) => {
          let meta = metaSnapshot.val();
          // save a copy
          this.savedMeta = meta;
          // console.log('found a sales page', meta);
          // fill all data vars with the pre-existing data
          Object.keys(meta).forEach(prop => this[prop] = meta[prop]);
        })
      }
    },
    validateMeta () {
      let emptyValuesArray = [null,""," ", "  ","   ","    ","     "];
      // validate each
      // -----
      // practiceName
      if(emptyValuesArray.includes(this.practiceName)) {
        this.errors.push('You must enter a Practice Name.')
      }
      if(this.practiceName && (this.practiceName.length < 4 || this.practiceName.length > 35)) {
        this.errors.push('The Practice Name must be longer than 4 characters and less than 35.')
      }
      // salesURL
      if(emptyValuesArray.includes(this.salesURL)) {
        this.errors.push('You must enter a Sales Page URL.')
      }
      if(this.urlTaken) {
        this.errors.push('This Sales Page URL is already taken. Please choose a different one.')
      }
      // headline
      if(emptyValuesArray.includes(this.headline)) {
        this.errors.push('You must enter a Headline.')
      }
      // lead
      if(emptyValuesArray.includes(this.lead)) {
        this.errors.push('You must enter a Lead Paragraph.')
      }
      // icon labels
      if(emptyValuesArray.includes(this.icon1Label)) {
        this.errors.push('You must enter text for Icon #1 Label.')
      }
      if(emptyValuesArray.includes(this.icon2Label)) {
        this.errors.push('You must enter text for Icon #2 Label.')
      }
      if(emptyValuesArray.includes(this.icon3Label)) {
        this.errors.push('You must enter text for Icon #3 Label.')
      }
      if(emptyValuesArray.includes(this.icon4Label)) {
        this.errors.push('You must enter text for Icon #4 Label.')
      }
      if(emptyValuesArray.includes(this.faqHeadline)) {
        this.errors.push('You must enter a headline for the FAQ Headline.')
      }
      if(emptyValuesArray.includes(this.faqText)) {
        this.errors.push('You must enter text for the FAQ Section.')
      }
      if(emptyValuesArray.includes(this.contactHeadline)) {
        this.errors.push('You must enter a headline for the Contact Headline.')
      }
      if(emptyValuesArray.includes(this.contactDetail)) {
        this.errors.push('You must enter text for the Contact Phone Number or Detail.')
      }
    },
    publishMeta () {
      this.errors = [];
      this.validateMeta();
      if(!this.user_props.owned_account_id) {
        this.errors.push("You must start your subscription before you can publish your sales page.")
      }
      if(this.errors.length) {
        return;
      }
      // console.log("validation passed!")
      let data = {};
      let sales_page_meta = {
        published: true,
        disabled: false,
        practice_account_id: this.user_props.owned_account_id,
        practiceName: this.practiceName,
        salesURL: this.salesURL,
        headline: this.headline,
        lead: this.lead,
        icon1Label: this.icon1Label,
        icon2Label: this.icon2Label,
        icon3Label: this.icon3Label,
        icon4Label: this.icon4Label,
        faqHeadline: this.faqHeadline,
        faqText: this.faqText,
        contactHeadline: this.contactHeadline,
        contactDetail: this.contactDetail,
      }
      if(!this.published) {
        this.page_id =  Vue.db.ref().child('sales_page_meta').push().key;
        // add to account
        data[`accounts/${this.user_props.owned_account_id}/public/sales_page_meta_id`] = this.page_id;
      }
      data[`sales_page_meta/${this.page_id}/`] = sales_page_meta;
      // console.log("data", data)
      // save all updates to database
      Vue.db.ref().update(data)
        .then(result => {
          // console.log(result);
          // this.$router.push('/account');
          this.updated = true;
        })
        .catch(err => {
          // console.log("error publishing page",err);
          this.loading = false;
          this.errors.push(err);
        })
    },
  },
  mounted () {
    // Check for this.account.public.sales_page_meta on account, if it exists, load it and then set this.page_meta to it
    this.loadMeta();
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

p.instructions {
  margin-bottom: 50px;
}
.input-url {
  margin-bottom: 5px;
}
label {
  width: 100%;
}
label small {
  float: right;
}
.url-meta {
  span:first-child {
    margin-right: 15px;
  }
}
.sales-url-reference {
  padding: 30px;
  background-color: @box-color;
  margin-bottom: 50px;

  p:last-child {
    margin: 0;
  }
}
</style>
