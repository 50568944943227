import { render, staticRenderFns } from "./LayoutPrivate.vue?vue&type=template&id=0aad8fe3&scoped=true&lang=html&"
import script from "./LayoutPrivate.vue?vue&type=script&lang=js&"
export * from "./LayoutPrivate.vue?vue&type=script&lang=js&"
import style0 from "./LayoutPrivate.vue?vue&type=style&index=0&id=0aad8fe3&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0aad8fe3",
  null
  
)

export default component.exports