<template lang="html">
  <layout-private>
    <div class="">
      <h2>Members</h2>
      <hr />

      <div v-if="!isAccountReady">
        <p>Finish setting up your account to manage your members.</p>
        <p><router-link class="btn btn-app" to="/set-up">Continue Set Up<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
      </div>

      <ul class="nav nav-sub" v-if="isAccountReady">
        <li class="nav-item">
          <router-link to="/members" exact-active-class="active" class="nav-link">Search</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ path: '/expired-members', name: 'expiredMembers' }" exact-active-class="active" class="nav-link">Expired Accounts</router-link>
        </li>
        <li class="nav-item nav-item-right">
          <a target="_blank" href="https://dashboard.stripe.com">Open Stripe Dashboard<i class="far fa-fw fa-external-link-square-alt"></i></a>
        </li>
      </ul>

      <router-view :userId="userId" v-if="isAccountReady"></router-view>

    </div>
  </layout-private>
</template>

<script>
import LayoutPrivate from '../components/LayoutPrivate.vue'
import {mapGetters} from 'vuex'

export default {
  props: [''],
  data () {
    return {
      userId: null,
    }
  },
  components: {
    LayoutPrivate
  },
  computed: {
    ...mapGetters(['account']),
    isAccountReady () {
      if( this.account && this.account.private && ['active', 'pastDue', 'pending'].includes(this.account.private.subscription_status) && this.account.restricted && this.account.restricted.stripe_connected_account_id ) {
        return true;
      }
      return false;
    },
  },
  mounted () {
    if(this.$route.params.id) {
      this.userId = this.$route.params.id;
    }
  },
  updated () {
    if(this.$route.params.id) {
      this.userId = this.$route.params.id;
    }
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.nav-sub .nav-item-right {
  margin-left: auto;
}
</style>
