<template lang="html">
  <div class="salesPage">
    <p v-if="loading" class="loading text-center"><i class="far fa-pulse fa-2x fa-spinner major-color"></i></p>
    <p v-if="salesMeta && salesMeta.disabled" class="text-center notice-inactive"><em>This dental practice's Health Assurance page is currently inactive.</em></p>
    <div class="" v-if="salesMeta && !salesMeta.disabled">

      <div class="ha-header">
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <img src="../assets/img/logo-ha.svg" alt="Health Assurance" width="200px" height="83px" class="logo-ha"><br>
              <h3 class="major-color">by {{ salesMeta.practiceName }}</h3>
            </div> <!-- /.col-x -->
          </div> <!-- /.row -->
        </div> <!-- /.container -->
      </div> <!-- /.ha-header -->

      <div class="ha-hero inverse-dark">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-summary">
    					<h1>{{ salesMeta.headline | fillPracticeName(salesMeta.practiceName) }}</h1>
    					<p>{{ salesMeta.lead | fillPracticeName(salesMeta.practiceName) }}</p>
              <p><router-link :to="{ path: `/${pageUrl}/sign-up`, query: { redirect: `/agreement` } }" class="btn btn-light">Join Now <i class="fal fa-fw fa-angle-right"></i></router-link></p>
            </div> <!-- /.col-x -->
            <div class="col-lg-6 col-benefits">
              <div class="row">
                <div class="col-md-6 col-icon text-center">
      						<p><img src="../assets/img/icon-money-wallet-open.svg" width="40px" height="42px" alt=""></p>
      						<p>{{ salesMeta.icon1Label | fillPracticeName(salesMeta.practiceName) }}</p>
      					</div>
      					<div class="col-md-6 col-icon text-center">
      						<p><img src="../assets/img/icon-chat-mood-happy.svg" width="40px" height="40px" alt=""></p>
      						<p>{{ salesMeta.icon2Label | fillPracticeName(salesMeta.practiceName) }}</p>
      					</div>
      					<div class="col-md-6 col-icon text-center">
      						<p><img src="../assets/img/icon-care-provider.svg" width="40px" height="40px" alt=""></p>
      						<p>{{ salesMeta.icon3Label | fillPracticeName(salesMeta.practiceName) }}</p>
      					</div>
      					<div class="col-md-6 col-icon text-center">
      						<p><img src="../assets/img/icon-card-plus.svg" width="40px" height="32px" alt=""></p>
      						<p>{{ salesMeta.icon4Label | fillPracticeName(salesMeta.practiceName) }}</p>
      					</div>
              </div> <!-- /.row -->
            </div> <!-- /.col-x -->
          </div> <!-- /.row -->
        </div> <!-- /.container -->
      </div> <!-- /.ha-hero -->

      <div class="ha-plans" v-if="patientPlanIds && Object.keys(patientPlanIds).length">
        <div class="container">

          <div v-if="primaryPlans && primaryPlans.length && additionalPlans && additionalPlans.length" class="row row-plan-toggle">
            <div class="col-6 col-lg-4 offset-lg-2 preset" :class="{ 'active': showPrimaryPlans }">
              <a href="#preset" class="btn-preset" @click.prevent="showPrimaryPlans=true">
                <h4 class="serif-font">
                  <i class="active-indicator far fa-fw fa-check"></i>
                  <strong>Primary Plans</strong>
                </h4>
                <p>Get affordable preventative &amp; restorative care without insurance</p>
              </a>
            </div>
            <div class="col-6 col-lg-4 preset" :class="{ 'active': !showPrimaryPlans }">
              <a href="#preset" class="btn-preset" @click.prevent="showPrimaryPlans=false">
                <h4 class="serif-font">
                  <i class="active-indicator far fa-fw fa-check"></i>
                  <strong>Additional Plans</strong>
                </h4>
                <p>Add additional benefits to your primary plan for your unique needs</p>
              </a>
            </div>
          </div> <!-- /.row -->

          <p class="text-center swipe-indicator dark-color"><i class="far fa-long-arrow-left"></i> swipe to see all plans <i class="far fa-long-arrow-right"></i></p>

          <div v-if="showPrimaryPlans && primaryPlans && primaryPlans.length" class="plans-table">
            <div class="plans-table-inner">
              <div class="row row-plans-header">
                <div v-for="(plan, index) in primaryPlans" class="col">
                  <h4>{{ plan.name }}</h4>
                </div>
              </div> <!-- /.row -->

              <div class="row row-dk">
                <div class="col">
                  <p>Who is this good for?</p>
                </div>
              </div> <!-- /.row -->
              <div class="row row-plans-benefits">
                <div v-for="(plan, index) in primaryPlans" class="col benefits" v-html="$sanitize(plan.benefits_patient_type)"></div>
              </div> <!-- /.row -->

              <div class="row row-dk">
                <div class="col">
                  <p>Preventative Health Services</p>
                </div>
              </div> <!-- /.row -->
              <div class="row row-plans-benefits">
                <div v-for="(plan, index) in primaryPlans" class="col benefits" v-html="$sanitize(plan.benefits_preventative)"></div>
              </div> <!-- /.row -->

              <div class="row row-dk">
                <div class="col">
                  <p>Health Restorative Services</p>
                </div>
              </div> <!-- /.row -->
              <div class="row row-plans-benefits">
                <div v-for="(plan, index) in primaryPlans" class="col benefits" v-html="$sanitize(plan.benefits_restorative)"></div>
              </div> <!-- /.row -->

              <div class="row row-dk">
                <div class="col">
                  <p>Special Additions</p>
                </div>
              </div> <!-- /.row -->
              <div class="row row-plans-benefits">
                <div v-for="(plan, index) in primaryPlans" class="col benefits" v-html="$sanitize(plan.benefits_special)"></div>
              </div> <!-- /.row -->

              <div class="row row-dk">
                <div class="col">
                  <p>Pricing Per Person</p>
                </div>
              </div> <!-- /.row -->
              <div class="row row-plans-benefits">
                <div v-for="(plan, index) in primaryPlans" class="col">
                  <p v-for="price in plan.prices" v-if="!price.archived" class="plan-price">${{ price.amount/100 }} / {{ price.interval }}</p>
                </div>
              </div> <!-- /.row -->
            </div> <!-- /.plans-table-inner -->
          </div> <!-- /.plans-table -->

          <div v-if="!showPrimaryPlans && additionalPlans && additionalPlans.length">
            <plan-preview-public v-for="(plan, index) in additionalPlans" :plan="plan" :key="index" :pageUrl="pageUrl"></plan-preview-public>

            <div class="row">
              <div class="col-sm-10 offset-sm-1">
                <hr />
              </div> <!-- /.col-x -->
            </div> <!-- /.row -->
          </div>

          <div class="row">
            <div class="col text-center">
              <p><router-link :to="{ path: `/${pageUrl}/sign-up`, query: { redirect: `/agreement` } }" class="btn btn-app btn-lg">Join Now <i class="fal fa-angle-right"></i></router-link></p>
            </div>
          </div>

        </div> <!-- /.container -->
      </div> <!-- /.ha-plans -->

    	<hr>

      <div class="ha-faq">
        <div class="container">
          <div class="row">
            <div class="col text-center">
    					<h2>{{ salesMeta.faqHeadline | fillPracticeName(salesMeta.practiceName) }}</h2>
            </div> <!-- /.col-x -->
          </div> <!-- /.row -->
    			<div class="row">
            <div class="col-sm-10 offset-sm-1">
    					<div v-html="$sanitize($options.filters.fillPracticeName(salesMeta.faqText, salesMeta.practiceName))"></div>
            </div> <!-- /.col-x -->
          </div> <!-- /.row -->
        </div> <!-- /.container -->
      </div> <!-- /.ha-faq -->

      <div class="ha-contact inverse-dark">
        <div class="container">
          <div class="row">
            <div class="col text-center">
    					<h2>
    						{{ salesMeta.contactHeadline | fillPracticeName(salesMeta.practiceName) }}<br>
    						<span class="light-weight">{{ salesMeta.contactDetail | fillPracticeName(salesMeta.practiceName) }}</span>
    					</h2>
            </div> <!-- /.col-x -->
          </div> <!-- /.row -->
        </div> <!-- /.container -->
      </div> <!-- /.ha-contact -->

      <div class="ha-footer">
    	  <div class="container">
    			<div class="row">
    	      <div class="col-sm-5 offset-sm-1">
    					<img src="../assets/img/logo-ha.svg" alt="Health Assurance" width="200px" height="83px" class="logo-ha">
    	      </div> <!-- /.col-x -->
    				<div class="col-sm-5 tagline">
    					<h3 class="major-color">Provided by {{ salesMeta.practiceName }}</h3>
    				</div>
    	    </div> <!-- /.row -->
    	  </div> <!-- /.container -->
    	</div> <!-- /.ha-footer -->

    </div> <!-- /v-if -->
  </div> <!-- /.salesPage -->
</template>

<script>
import Vue from 'vue'
import PlanPreviewPublic from '../components/PlanPreviewPublic.vue'

export default {
  props: [''],
  data () {
    return {
      pageUrl: null,
      salesMeta: null,
      loading: true,
      patientPlanIds: [],
      showPrimaryPlans: true, // default so it can only be toggled off if additional plans present
      plans: [],
    }
  },
  computed: {
    primaryPlans () {
      if(this.plans && this.plans.length) {
        return this.plans.filter(plan => plan.plan_type == 'primary' && !plan.archived && plan.prices && Object.keys(plan.prices).length);
      }
      return null;
    },
    additionalPlans () {
      if(this.plans && this.plans.length) {
        return this.plans.filter(plan => plan.plan_type == 'additional' && !plan.archived && plan.prices && Object.keys(plan.prices).length);
      }
      return null;
    },
  },
  components: {
    PlanPreviewPublic
  },
  created () {
    if(this.$route.params.id) {
      this.pageUrl = this.$route.params.id
    }
  },
  watch: {
    // load the page when the pageUrl is set from params
    pageUrl () {
      if (this.pageUrl) {
        let metaRef = Vue.db.ref(`/sales_page_meta/`)
          .orderByChild('salesURL')
          .equalTo(this.pageUrl)
      	  .once('value', (metaSnapshot) => {
            // get first index in values array since orderByChild returns an object of objects
            if(metaSnapshot.val()) {
              this.salesMeta = Object.values(metaSnapshot.val())[0];
            } else {
              this.$router.push({ name: '404' })
            }
          });
      }
    },
    // after meta loaded
      // load practice account.public.plans
      // set html page title
    salesMeta () {
      if(this.salesMeta && this.salesMeta.practiceName) {
        document.title = `${this.salesMeta.practiceName} | Health Assurance`;

        this.$store.dispatch('setGuarantorPracticeName', this.salesMeta.practiceName);
        this.$store.dispatch('setGuarantorPracticeUrl', this.pageUrl);
      }
      // load plan ids
      Vue.db.ref(`/accounts/${this.salesMeta.practice_account_id}/public/plans`)
        .once('value', (plansSnapshot) => {
          if(plansSnapshot.exists()) {
            this.patientPlanIds = plansSnapshot.val();
          }
        });
    },
    // after plan Ids loaded, load the full plans
    patientPlanIds () {
      if(this.patientPlanIds) {
        let planPromises = [];
        for (let patientPlanId in this.patientPlanIds) {
          // console.log('patientPlanId',patientPlanId)
          let planProm = Vue.db.ref(`/plans/${patientPlanId}/`)
            .once('value')
            .then(planSnapshot => {
              if(planSnapshot.exists()) {
                this.plans.push(planSnapshot.val());
              }
            });
          planPromises.push(planProm);
        }
        Promise.all(planPromises)
          .then(() => {
            this.loading = false;
          })
      }
    }
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";
@import "../assets/less/page-home-patients.less";

.salesPage {
  background-color: @light-color;
}
.loading {
  margin: 100px 0;
}
.notice-inactive {
  margin-top: 50px;
}
.ha-faq /deep/ h3 {
  margin: 40px 0 20px;
  color: @dark-color;
}

// plans table
// -----
.swipe-indicator {
  display: none;
}
.plans-table {
  margin: 50px 0;
}
@media (max-width: @screen-md-min) {
  .plans-table-inner {
    min-width: 800px;
  }
  .plans-table {
    overflow-x: auto;
    padding-top: 100px;
  }
  .swipe-indicator {
    display: block;
    margin-top: 50px;
    position: relative;
    z-index: 100;
    margin-bottom: -125px;
    pointer-events: none;
  }
}

.row-plans-header {
  .col {
    background-color: @dark-color;
    padding: 30px;
    text-align: center;

    h4 {
      margin: 0;
      line-height: 1em;
      color: @light-color;
    }
  }
  .col:first-child {
    background-color: @box-color;

    h4 {
      color: @dark-color;
    }
  }
  .col:last-child {
    background-color: @major-color;

    h4 {
      color: @light-color;
    }
  }
}
.row-dk .col {
  background-color: #222;
  padding: 15px;
  text-align: center;

  p {
    color: @light-color;
    margin: 0;
  }
}
.row-plans-benefits {
  .col {
    background-color: fadeout(@dark-color, 90%);
    padding: 20px 30px;
  }
  .col:first-child {
    background-color: @box-color;
  }
  .col:last-child {
    background-color: fadeout(@major-color, 80%);
  }
}
.benefits {
  /deep/ p {
    margin: 20px 0;
  }
  /deep/ p:first-child {
    margin-top: 0;
  }
  /deep/ ul {
    margin: 0;

    > li:before {
      color: @text-color;
      // display: block;
      // float: left;
    }
    > li:last-child {
      margin: 0 !important;
    }
    > li > p {
      margin: 0;
      // display: inline-block;
      display: inline;
    }
  }
}
.plan-price {
  margin: 0;
}
.plan-price:last-child:not(:first-child):before {
  content: 'or';
  display: block;
  font-style: italic;
}

// Presets / plan toggles
.row-plan-toggle {
  margin-top: 50px;
}
.preset {
  margin-bottom: 20px;

  a {
    display: block;
    padding: 25px;
    border: 1px solid @major-color;
    // background-color: transparent;
    text-decoration: none;
  }
  h4 {
    margin-bottom: 10px;
  }
  p {
    // line-height: 1em;
    margin: 0;
  }
  p, small, i {
    color: @dark-color;
  }
  .active-indicator {
    // hide, for animation
    opacity: 0;
    transform: scale(.5);
    transition: all 125ms ease-out;
    width: 0;
  }
}
.preset a:hover {
  background-color: @major-color;

  h4, p, small, i {
    color: @light-color;
  }
}
.preset.active {
  .active-indicator {
    transform: scale(1);
    opacity: 1;
    width: 1.25em;
    margin-right: 5px;
  }
  a, a:hover {
    background-color: @dark-color;
    border-color: @dark-color;

    h4, p, small, i {
      color: @light-color;
    }
  }
  a:hover {
    cursor: pointer;
  }
}
</style>
