<template lang="html">
  <div class="rich-editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="editor-menubar">
        <button
          class="btn btn-icon"
          :class="{ 'active': isActive.paragraph() }"
          @click="commands.paragraph"
        >
          <i class="far fa-fw fa-paragraph"></i>
        </button>
        <button
          class="btn btn-icon"
          :class="{ 'active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
          v-if="!disableHeadings"
        >
          <i class="far fa-fw fa-h1"></i>
        </button>
        <button
          class="btn btn-icon"
          :class="{ 'active': isActive.heading({ level: 4 }) }"
          @click="commands.heading({ level: 4 })"
          v-if="!disableHeadings"
        >
          <i class="far fa-fw fa-h2"></i>
        </button>
        <button
          class="btn btn-icon"
          :class="{ 'active': isActive.bold() }"
          @click="commands.bold"
        >
          <i class="far fa-fw fa-bold"></i>
        </button>
        <button
          class="btn btn-icon"
          :class="{ 'active': isActive.italic() }"
          @click="commands.italic"
        >
          <i class="far fa-fw fa-italic"></i>
        </button>
        <button
          class="btn btn-icon"
          :class="{ 'active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <i class="far fa-fw fa-list-ul"></i>
        </button>
        <button
          class="btn btn-icon"
          :class="{ 'active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <i class="far fa-fw fa-list-ol"></i>
        </button>
      </div>
    </editor-menu-bar>
    <editor-content class="editor-content" :editor="editor" />
  </div> <!-- /.rich-editor -->
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { OrderedList, BulletList, ListItem, Bold, Italic, Placeholder, Heading } from 'tiptap-extensions'

export default {
  props: ['defaultText', 'disableHeadings'],
  components: {
    EditorContent, EditorMenuBar,
  },
  data () {
    return {
      editor: null,
      html: null,
    }
  },
  // watch for outside changes
  watch: {
    defaultText () {
      if(this.defaultText !== this.html) {
        this.editor.setContent(this.defaultText);
      }
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.defaultText,
      onUpdate: ({ getJSON, getHTML }) => {
          // this.json = getJSON()
          this.html = getHTML()
          this.$emit('editor-html', this.html)
        },
      extensions: [
          new BulletList(),
          new OrderedList(),
          new ListItem(),
          new Bold(),
          new Italic(),
          new Heading({ levels: [3, 4] }),
          new Placeholder({
            emptyNodeText: node => {
              return 'Enter text'
            },
          }),
        ],
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";
@import "../assets/less/elements.less";

.editor-menubar {
	background-color: @text-color;
}
.editor-content /deep/ .ProseMirror {
  border-radius: 0;
  box-shadow: none;
  outline: none !important;
  border: 1px solid @text-color;
  border-top: none;
  font-size: 18px;
  line-height: 24px;
  padding: 15px;
  height: 200px;
  margin-bottom: 30px;
  overflow-y: scroll;

  .plan-list();

  &:focus {
    box-shadow: none;
    outline: none !important;
    border-color: @major-color;
  }
  *.is-empty:nth-child(1)::before {
    content: attr(data-empty-text);
    float: left;
    color: #ccc;
    pointer-events: none;
    height: 0;
  }
}
</style>
