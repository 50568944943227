<template>
  <div id="app">
    <router-view :plans="Object.values(plans)" :stripeElementsOptions="stripeElementsOptions" />
  </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import router from './router'
import stripePlansTest from './assets/json/stripe-plans-test.json';
import stripePlansLive from './assets/json/stripe-plans-live.json';

export default {
  data () {
    return {
      showNav: false,
      plans: process.env.VUE_APP_STRIPE_MODE === "live" ? stripePlansLive : stripePlansTest,
      stripeElementsOptions: {
        iconStyle: "solid",
        style: {
          base: {
            iconColor: '#00b6d7',
            color: '#11788a',
            fontWeight: 400,
            fontFamily: "'karmina-sans', Arial, sans-serif",
            fontSize: '16px',

            '::placeholder': {
              color: '#666',
            },
            ':-webkit-autofill': {
              color: '#11788a',
              iconColor: '#00b6d7'
            }
          },
          complete: {
            color: '#333',
            ':-webkit-autofill': {
              color: '#333',
              iconColor: '#00b6d7'
            },
            ':-webkit-autofill:focus': {
              color: '#333',
              iconColor: '#00b6d7'
            }
          },
        },
        classes: {
          base: "stripe-form-base",
          focus: "stripe-form-focus"
        }
      }
    }
  },
  computed: {
    ...mapGetters(['user','user_props','account','isPracticeUser']),
  },
  watch: {
    account () {
      if(this.account && this.account.public.sales_page_meta_id) {
        console.log(`loading practiceName for ${this.account.public.sales_page_meta_id}`)
        Vue.db.ref(`sales_page_meta/${this.account.public.sales_page_meta_id}`)
          .once('value')
          .then(snap => {
            console.log(snap)
            if(snap.val() && snap.val().practiceName) {
              // console.log('found practiceName',snap.val().practiceName)
              this.$store.dispatch('setAdminPracticeName', snap.val().practiceName);
            }
          })
          .catch(err => console.log(err));
      }
    },
    user_props (newValue, oldValue) {
      // console.log("user_props changed:", oldValue, newValue);
      // if user_props changes via onAuthStateChanged, attempt to bind the account
      if(newValue && newValue.owned_account_id) {
        // only need ref for use in vuexfire, not snapshot
        let accountRef = Vue.db.ref(`/accounts/${newValue.owned_account_id}`);
        this.$store.dispatch('setAccountRef', accountRef);
      }
      // if user_props includes a practice id, load the practice's name
      if(newValue && newValue.practice_account_id) {
        // only need ref for use in vuexfire, not snapshot
        Vue.db.ref(`/sales_page_meta/`)
          .orderByChild('practice_account_id')
          .equalTo(newValue.practice_account_id)
          .once('value')
          .then(results => {
            if(results.val()) {
              this.$store.dispatch('setGuarantorPracticeName', Object.values(results.val())[0].practiceName);
              this.$store.dispatch('setGuarantorPracticeContact', Object.values(results.val())[0].contactDetail);
            }
          });
      }
      // if user_props changes via onAuthStateChanged, attempt to bind the account
      if(newValue && newValue.owned_guarantor_account_id) {
        // only need ref for use in vuexfire, not snapshot
        let guarantorAccountRef = Vue.db.ref(`/guarantor_accounts/${newValue.owned_guarantor_account_id}`);
        this.$store.dispatch('setGuarantorAccountRef', guarantorAccountRef);
      }
      // on page refresh, check user types for current route, redirect if needed
        // extra check to make sure user types don't access incorrect pages by directly entering url
      if (this.$route.matched.some(record => record.meta.practiceUserRequired) && !this.isPracticeUser) {
        // console.log('practice user required')
        this.$router.replace('/guarantor-account')
      }
      // if guarantor User Required
      else if (this.$route.matched.some(record => record.meta.guarantorUserRequired) && this.isPracticeUser) {
        // console.log('guarantor user required')
        this.$router.replace({ name:'404' })
      }
    },
  }
}
</script>

<style lang="less">
@import "assets/less/style.less";
</style>
