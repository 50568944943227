<template lang="html">
  <layout-private>
    <div class="">
      <h2 v-if="!this.guarantor_account && !this.guarantorId">Start Your Membership</h2>
      <h2 v-if="this.guarantorId || this.guarantor_account">Edit Membership</h2>
      <hr />

      <div class="row">
        <div class="col">
          <button class="btn btn-light float-right" @click="toggleModal"><i class="fas fa-external-link-square"></i> View Plan Benefits</button>
          <h4 class="snug" v-if="!this.guarantorId && !this.guarantor_account">Step 2 of 3</h4>
          <h3>Members &amp; Plans</h3>
        </div> <!-- /.col-x -->
      </div> <!-- /.row -->

      <div class="row">
        <div class="col-lg-8">
          <p v-if="!this.guarantorId && !this.guarantor_account"><strong>Note:</strong> Each member can join a separate membership plan, and you can even pay monthly or yearly separately too! Account owners do not receive benefits unless added as a member below.</p>
          <p v-if="this.guarantorId"><strong>Note:</strong> Switching plans will automatically create a prorated credit or fee based on unused time. Credits will be applied to future payments. Monthly to yearly upgrades will charge the card on file immediately. Otherwise, any prorated fees will be included in the next payment.</p>
          <p v-if="this.guarantor_account"><strong>Note:</strong> You can add members to your account anytime. Because all memberships include a 1-year agreement, to cancel or edit memberships you must contact the dental practice.</p>

          <div v-if="canEditMemberships || (user_props && !user_props.owned_guarantor_account_id)" class="same-plan form-inline" :class="{'same-plan-active': useSamePlan}">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="samePlan" v-model="useSamePlan">
              <label class="form-check-label" for="samePlan">Use same plan for everyone</label>
            </div>
            <div class="input-group flex-fill">
              <select
                v-model="samePlanProductId"
                class="form-control"
                :disabled="!useSamePlan"
                @change="samePlanPriceId=null"
              >
                <option disabled :value="null">select one</option>
                <option v-for="(product, id) in products" :value="id" :key="id">{{ product.name }}</option>
              </select>
            </div>
            <div class="input-group flex-fill">
              <p class="dim-color" v-if="!samePlanProductId"><em>select a plan first</em></p>
              <select
                v-model="samePlanPriceId"
                class="form-control"
                :disabled="!useSamePlan"
                v-if="samePlanProductId"
              >
                <option disabled :value="null">select one</option>
                <option
                  v-for="(plan, key) in products[samePlanProductId].prices"
                  :value="key"
                  v-if="!plan.archived"
                >
                  ${{ plan.amount/100 }} / {{ plan.interval }}
                </option>
              </select>
            </div>
          </div> <!-- /.same-plan.form-inline -->

        </div> <!-- /.col-x -->
      </div> <!-- /.row -->

      <p v-if="loading" class="loading text-center"><i class="far fa-pulse fa-2x fa-spinner major-color"></i></p>

      <div v-if="!loading" class="member-grid">
        <div class="row">
          <div class="col-2">
            <label>First Name</label>
          </div> <!-- /.col-x -->
          <div class="col-2">
            <label>Last Name</label>
          </div> <!-- /.col-x -->
          <div class="col-3" v-if="!useSamePlan">
            <label>Membership Plan Level</label>
          </div> <!-- /.col-x -->
          <div class="col-2" v-if="!useSamePlan">
            <label>Payment Plan</label>
          </div> <!-- /.col-x -->
        </div> <!-- /.row -->
        <div v-for="(member, index) in members" class="row" :key="index">
          <div class="col-2">
            <input
              v-model="member.name_first"
              :id="'first' + index"
              :disabled="member.closed || member.expired || (!canEditMemberships && member.id)"
              name="name_first"
              class="form-control"
              placeholder="Enter first name"
              type="text"
            />
          </div> <!-- /.col-x -->
          <div class="col-2">
            <input
              v-model="member.name_last"
              :id="'last' + index"
              :disabled="member.closed || member.expired || (!canEditMemberships && member.id)"
              name="name_last"
              class="form-control"
              placeholder="Enter last name"
              type="text"
            />
          </div> <!-- /.col-x -->
          <div class="col-3" v-if="!useSamePlan">
            <select
              v-model="member.plan_id"
              class="form-control"
              @change="clearMemberPlanId(index)"
              :disabled="member.closed || member.expired || (!canEditMemberships && member.id)"
            >
              <option disabled :value="null">select one</option>
              <option v-for="(product, id) in products" :value="id" :key="id">{{ product.name }}</option>
            </select>
          </div> <!-- /.col-x -->
          <div class="col-2" v-if="!useSamePlan">
            <p class="dim-color" v-if="!member.plan_id"><em>select a plan first</em></p>
            <select
              v-model="member.price_id"
              class="form-control"
              v-if="member.plan_id && products && products[member.plan_id]"
              :disabled="member.closed || member.expired || (!canEditMemberships && member.id)"
              @change="setRenew(member)"
            >
              <option disabled :value="null">select one</option>
              <option
                v-for="(plan, key) in products[member.plan_id].prices"
                :value="key"
                v-if="!plan.archived"
              >
                ${{ plan.amount/100 }} / {{ plan.interval }}
              </option>
            </select>
          </div> <!-- /.col-x -->
          <div class="col-3">
            <button v-if="!member.id && members.length > 1" class="btn btn-light" @click="removeMember(index)"><i class="far fa-times"></i></button>
            <em v-if="member.closed || member.expired" class="sm dim-color">Closed subscriptions can't be restarted, but you can add a new one instead.</em>
            <div v-if="canEditMemberships && member.id && !member.closed && !member.expired" class="form-inline form-inline-check-only">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" :id="member.id" v-model="member.cancel">
                <label class="form-check-label" :for="member.id">
                  Cancel
                  <span v-if="guarantor && guarantor.private.members[member.id] && guarantor.private.members[member.id].subscription_status == 'cancelled'" class="em light-weight instructions-renew">(uncheck to renew)</span>
                </label>
              </div>
            </div>
          </div> <!-- /.col-x -->
        </div> <!-- /.row -->
        <button class="btn btn-light" @click="addMember"><i class="far fa-plus-circle"></i> Add Another member</button>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="membership-summary">
            <h4>Summary</h4>
            <p v-if="activeMemberCount">{{ activeMemberCount }} member<span v-if="activeMemberCount>1">s</span> receive<span v-if="activeMemberCount==1">s</span> benefits</p>
            <p v-if="cancelledMemberCount">{{ cancelledMemberCount }} membership<span v-if="cancelledMemberCount>1">s</span> will be cancelled</p>
            <p v-if="closedMemberCount">{{ closedMemberCount }} membership<span v-if="closedMemberCount>1">s</span> ended</p>
            <p v-if="totalMonthlyPrice">${{ totalMonthlyPrice }} per month</p>
            <p v-if="totalYearlyPrice">${{ totalYearlyPrice }} once per year</p>
          </div>

          <list-errors :errors="errors"></list-errors>

          <p v-if="submitting" class="loading text-center"><i class="far fa-pulse fa-2x fa-spinner major-color"></i></p>
          <p v-if="!submitting"><button class="btn btn-app" @click="savePlans">
            <span v-if="!this.guarantorId && !this.guarantor_account">Next Step</span>
            <span v-if="this.guarantorId || this.guarantor_account">Save Edits</span>
            <i class="far fa-angle-right fa-fw fa-right"></i></button></p>
        </div> <!-- /.col-x -->
      </div> <!-- /.row -->


      <div class="modal modal-app fade" :class="{'show': showModal, 'display-modal': displayModal}" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button class="btn btn-light btn-modal-close" @click="toggleModal"><i class="far fa-times"></i></button>
              <plan-preview-private v-for="(product, id) in products" :planId="id" :key="id"></plan-preview-private>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade" :class="{'show': showModal, 'display-modal': displayModal}"></div>

    </div>
  </layout-private>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import PlanPreviewPrivate from '../components/PlanPreviewPrivate.vue'
import ListErrors from '../components/ListErrors.vue'

export default {
  props: [''],
  computed: {
    ...mapGetters(['user','user_props','guarantorPracticeName', 'guarantor_account']),
    totalMonthlyPrice () {
      return this.totalPrice('month');
    },
    totalYearlyPrice () {
      return this.totalPrice('year');
    },
    activeMemberCount () {
      return this.members.filter(mem => mem.plan_id && mem.price_id && !mem.cancel && !mem.closed && !mem.expired).length;
    },
    cancelledMemberCount () {
      return this.members.filter(mem => mem.plan_id && mem.price_id && mem.cancel && !mem.closed && !mem.expired).length;
    },
    closedMemberCount () {
      return this.members.filter(mem => mem.plan_id && mem.price_id && (mem.closed || mem.expired)).length;
    },
    canEditMemberships () {
      if(this.user_props && this.user_props.user_type == "practice") {
        return true;
      }
      return false;
    },
  },
  data () {
    return {
      loading: true,
      submitting: false,
      errors: [],
      guarantorId: null,
      guarantor: null,
      showModal: false, // toggle for animation
      displayModal: false, // toggle on timeout for display: none/block
      members: [
        {
          id: null,
          name_first: null,
          name_last: null,
          plan_id: null,
          price_id: null,
        }
      ],
      planIds: null,
      products: {},
      useSamePlan: false,
      samePlanProductId: null,
      samePlanPriceId: null,
    }
  },
  components: {
    LayoutPrivate, PlanPreviewPrivate, ListErrors
  },
  watch: {
    // update member plans when samePlan is enabled
    useSamePlan () {
      if(this.useSamePlan) {
        this.setMemberPlans();
      }
    },
    samePlanPriceId () {
      this.setMemberPlans();
    },
    // get products on page refresh
    user_props () {
      if(this.user_props) {
        if(this.user_props.owned_account_id && this.user_props.user_type) {
          this.getPlanIds(this.user_props.owned_account_id)
          // on router navigation, if id found, load that guarantor
          if(this.$route.params.id) {
            this.guarantorId = this.$route.params.id;
            this.loadGuarantorSubscription();
          }
        } else {
          this.getPlanIds(this.user_props.practice_account_id);
          // loadGuarantorSubscription called in watcher for guarantor_account
        }
      }
    },
    // for guarantor users, store members when the account is set
    guarantor_account () {
      if(this.guarantor_account) {
        this.loadGuarantorSubscription();
      }
    },
    // once we have a list of planIds, load them all
    planIds () {
      // load all the plans
      let planPromises = [];
      if (this.planIds) {
        for (const planId in this.planIds) {
          // console.log(`getting plan ${planId}`);
          let planPromise = Vue.db.ref(`/plans/${planId}`);
          planPromises.push(planPromise);
          planPromise.on('value', (snapshot) => {
            let plan = snapshot.val();
            // only store if the plan isn't archived
            if(!plan.archived) {
              // store as an object for easier lookup
              // this.products[planId] = plan; // not reactive
              this.$set(this.products, planId, plan) // reactive version
            }
            // console.log('plan loaded',this.products);
          });
        }
      }
      Promise.all(planPromises)
        .then(() => {
          this.loading = false;
        })
    },
  },
  methods: {
    totalPrice (interval) {
      if(!this.products || !this.members) {
        return 0;
      }
      let total = 0;
      this.members.forEach((mem,index) => {
        if(mem.plan_id && mem.price_id && !mem.cancel && !mem.closed && !mem.expired) {
          if(this.products[mem.plan_id] && this.products[mem.plan_id].prices[mem.price_id].interval == interval) {
            total += this.products[mem.plan_id].prices[mem.price_id].amount;
          }
        }
      })
      if(total > 0) {
        return total/100;
      } else {
        return null;
      }
    },
    setMemberPlans () {
      this.members.forEach((member, index) => {
        this.members[index].plan_id = this.samePlanProductId;
        this.members[index].price_id = this.samePlanPriceId;
      });
    },
    clearMemberPlanId (index) {
      this.members[index].price_id = null;
    },
    getPlanIds (practice_account_id) {
      if(!this.user_props || !practice_account_id) {
        return;
      }
      // get plan ids from practice account public info
      Vue.db.ref(`/accounts/${practice_account_id}/public/plans`)
        .on('value', (plansSnapshot) => {
          if(plansSnapshot.exists()) {
            // set planIds to trigger watch method to load them all in a loop
            this.planIds = plansSnapshot.val();
            // console.log("planIds", this.planIds)
          }
        });
    },
    addMember () {
      let newMember;
      if(this.useSamePlan) {
        newMember = {
          id: null,
          name_first: null,
          name_last: null,
          plan_id: this.samePlanProductId,
          price_id: this.samePlanPriceId,
        };
      } else {
        newMember = {
          id: null,
          name_first: null,
          name_last: null,
          plan_id: null,
          price_id: null,
        };
      }
      this.members.push(newMember)
    },
    removeMember (index) {
      this.members.splice(index,1);
    },
    toggleModal () {
      const el = document.body;
      // if modal is hidden, display immediately to show animation
      if(!this.displayModal) {
        this.displayModal = !this.displayModal
        // delay animation barely until display changed
        setTimeout(() => this.showModal = !this.showModal, 1)
        el.classList.add('modal-open'); // set overflow hidden on body
      }
      // else if modal is showing, use timeout to turn display to none
      else {
        setTimeout(() => this.displayModal = !this.displayModal, 500)
        this.showModal = !this.showModal;
        el.classList.remove('modal-open'); // remove overflow hidden on body
      }
    },
    storeGuarantorMembers () {
      if(this.guarantor) {
        // empty array so we only edit existing members
        this.members = [];
        let memberPromises = [];
        // load each member to get their names
        Object.keys(this.guarantor.private.members).forEach(id => {
          let memProm = Vue.db.ref(`members/${id}`).once('value');
          memberPromises.push(memProm);
          memProm.then(memberSnapshot => {
            if(memberSnapshot.exists()) {
              // store for display
              this.members.push({
                id: id,
                name_first: memberSnapshot.val().name_first,
                name_last: memberSnapshot.val().name_last,
                plan_id: this.guarantor.private.members[id].plan_id,
                price_id: this.guarantor.private.members[id].plan_price_id,
                cancel: this.guarantor.private.members[id].subscription_status === "cancelled",
                closed: this.guarantor.private.members[id].subscription_status === "closed",
                expired: this.guarantor.private.members[id].subscription_status === "expired"
              });
            }
          });
        });
        Promise.all(memberPromises)
          .then(() => {
            // console.log("loaded all members' meta");
            this.loading = false;
          })
          .catch(err => console.log(err));
      }
    },
    loadGuarantorSubscription () {
      if(this.guarantor_account) {
        this.guarantor = this.guarantor_account;
        this.storeGuarantorMembers();
      }
      else if(this.guarantorId && this.user_props && this.user_props.user_type == "practice") {
        Vue.db.ref(`/guarantor_accounts/${this.guarantorId}`)
          .once('value', (snapshot) => {
            if(snapshot.exists()) {
              this.guarantor = snapshot.val();
              this.storeGuarantorMembers();
            }
          });
      }
    },
    // if the member was previously cancelled and the plan changed, display as renewing
    setRenew(member) {
      if(member && member.id) {
        member.cancel = false;
      }
    },
    savePlans () {
      this.errors = [];
      let emptyValuesArray = [null,""," ", "  ","   ","    ","     "];
      // validate each member
      this.members.forEach(member => {
        // validate names
        if(!member.name_first || member.name_first.length < 2 || emptyValuesArray.includes(member.name_first)) {
          this.errors.push("Member first name must be at least two characters and not blank.")
        }
        if(!member.name_last || member.name_last.length < 2 || emptyValuesArray.includes(member.name_last)) {
          this.errors.push("Member last name must be at least two characters and not blank.")
        }
        // validate product id
        if(!member.plan_id || !this.products[member.plan_id]) {
          this.errors.push("Please select a valid plan level for every member.")
        }
        // validate plan id
        if(!member.price_id || !this.products[member.plan_id].prices[member.price_id]) {
          this.errors.push("Please select a valid payment plan for every member.")
        }
      });
      if(this.errors.length) {
        // strip duplicate values via https://medium.com/dailyjs/how-to-remove-array-duplicates-in-es6-5daa8789641c
        this.errors = [...new Set(this.errors)];
        return;
      }
      // show loading / hide button
      this.submitting = true;
      // create guarantor account
      let updates = {};
      let guarantorAccountKey;
      // if editing
      if(this.guarantorId) {
        guarantorAccountKey = this.guarantorId;
      } else if(this.guarantor_account) {
        guarantorAccountKey = this.user_props.owned_guarantor_account_id;
      }
      // if new
      else {
        guarantorAccountKey = Vue.db.ref().child('guarantor_accounts').push().key;
        updates[`guarantor_accounts/${guarantorAccountKey}/private/guarantor_uid`] = this.user.uid;
        updates[`guarantor_accounts/${guarantorAccountKey}/private/practice_account_id`] = this.user_props.practice_account_id;
        updates[`user_props/${this.user.uid}/owned_guarantor_account_id`] = guarantorAccountKey;
      }

      // store all members and plans
      this.members.forEach(member => {
        // don't do anything if this member was previously closed
        if(member.closed || member.expired) {
          return true;
        }
        let memberKey;
        // if editing
        if(member.id) {
          memberKey = member.id;

          // if a guarantor, do not allow any edits to prexisting members
          if(this.guarantor_account || !this.user_props.user_type || this.user_props.user_type !== "practice") {
            console.log("you can't edit that")
            return true;
          }

          // if member already has an id && is set to be cancelled, save cancelled status
            // and not already cancelled
          if(member.cancel && this.guarantor.private.members[member.id].subscription_status !== "cancelled") {
            updates[`guarantor_accounts/${guarantorAccountKey}/private/members/${memberKey}/subscription_status`] = "cancelled";
          }
          // if member was marked to renew / undo the existing cancel
          if(!member.cancel && this.guarantor.private.members[member.id].subscription_status == "cancelled") {
            updates[`guarantor_accounts/${guarantorAccountKey}/private/members/${memberKey}/subscription_status`] = "active";
          }
        }
        // if new
        else {
          memberKey = Vue.db.ref().child('members').push().key;
          // if a practice user saving a new member
          if(this.guarantorId) {
            updates[`members/${memberKey}/practice_account_id`] = this.user_props.owned_account_id;
          }
          // otherwise, get practice is from guarantor
          else {
            updates[`members/${memberKey}/practice_account_id`] = this.user_props.practice_account_id;
          }

          updates[`members/${memberKey}/guarantor_uid`] = this.user.uid;
          updates[`members/${memberKey}/guarantor_account_id`] = guarantorAccountKey;
        }
        // both: update whether editing or creating
        updates[`members/${memberKey}/name_first`] = member.name_first;
        updates[`members/${memberKey}/name_last`] = member.name_last;
        // only save these if we're not cancelling
        if(!member.cancel) {
          updates[`guarantor_accounts/${guarantorAccountKey}/private/members/${memberKey}/plan_id`] = member.plan_id;
          updates[`guarantor_accounts/${guarantorAccountKey}/private/members/${memberKey}/plan_price_id`] = member.price_id;
          updates[`guarantor_accounts/${guarantorAccountKey}/private/members/${memberKey}/stripe_product_id`] = this.products[member.plan_id].stripe_product_id;
          updates[`guarantor_accounts/${guarantorAccountKey}/private/members/${memberKey}/stripe_patient_plan_id`] = this.products[member.plan_id].prices[member.price_id].stripe_patient_plan_id;
        }
      });
      // save all updates
      // console.log('saving updates', updates);
      Vue.db.ref().update(updates)
        .then(result => {
          // console.log(result);
          if(this.user_props && this.user_props.user_type) {
            this.$router.push(`/guarantor-profile/${this.guarantorId}`);
          } else {
            if(this.guarantor_account && this.guarantor_account.private.stripe_token) {
              this.$router.push(`/guarantor-account`);
            } else {
              this.$router.push(`/payment-method`);
            }

          }
          this.submitting = false;
        })
        .catch(err => {
          // console.log("error saving plans",err);
          this.submitting = false;
          this.errors.push(err);
        });
    },
  },
  mounted () {
    if(this.user_props) {
      // get products on router navigation
      if(this.user_props.owned_account_id && this.user_props.user_type) {
        this.getPlanIds(this.user_props.owned_account_id)
      } else {
        this.getPlanIds(this.user_props.practice_account_id);
      }
    }
    // on router navigation, if id found, load that guarantor
    if(this.$route.params.id) {
      this.guarantorId = this.$route.params.id;
    }
    this.loadGuarantorSubscription();
  }
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

hr {
  margin-bottom: 50px;
}
p.dim-color {
  line-height: 1em;
  margin: 15px 0 0;
}
// modals, override bootstrap
.modal.fade.show {
  opacity: 1;
}
.display-modal {
  display: block !important;
}
.btn-modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
}
.modal-app {
  transition: opacity .25s linear;
  overflow-y: auto;

  .modal-content {
    border-radius: 0;
    border: none;
  }
  .modal-body {
    padding: 0;
  }
}
.modal-backdrop {
  background-color: @box-color;
  transition: opacity .5s linear;
  display: none;
}
.modal-backdrop.show {
  opacity: 0.9;
}
.plan-preview-private /deep/ .row {
  padding-left: 50px;
  padding-right: 50px;
}
// summary box
.membership-summary {
  background-color: @box-color;
  padding: 20px;
  margin: 30px 0;

  h4 {
    margin: 0 0 10px;
  }
  p {
    margin: 0;
  }
}
// same plan box
.same-plan {
  padding: 20px;
  border: 1px solid #999;
  margin: 30px 0;

  > div {
    margin-right: 30px;
  }
  > div:last-child {
    margin-right: 0;
  }
  p.dim-color {
    margin-top: 0;
  }
}
.same-plan-active {
  border-color: @major-color;
}
.form-inline-check-only {
  height: 50px;
}
.instructions-renew {
  margin-left: 0.25em;
}
</style>
