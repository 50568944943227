<template lang="html">
  <layout-private>
    <div class="">
      <h2>Start Your Membership</h2>
      <hr />

      <div v-if="!user_props || !user_props.practice_account_id" class="row">
        <div class="col-lg-9">
          <p><strong class="error-color">Error:</strong> your user account is not currently associated with a dental office. Please contact your dental office and ask them to check your user account.</p>
        </div>
      </div>

      <div class="row" v-if="user_props && user_props.practice_account_id">
        <div class="col-lg-9">
          <h4 class="snug">Step 1 of 3</h4>
          <h3>Membership Agreement</h3>
          <p>The Health Assurance membership agreement is a 1-year contract between you and {{ guarantorPracticeName }} that locks in membership details so you can avoid price increases and changes to your benefits. It’s required for all accounts. Please note, this is a legally binding eSignature.</p>

          <div class="agreement-text">
            <h5>Dental Practice Name</h5>
            <p v-if="guarantorPracticeName">{{ guarantorPracticeName }}</p>

            <h5>Membership Contract</h5>
            <p>Health Assurance is not insurance. It is an alternative for patients without dental insurance. Members receive discounted preventive dental care for an annual fee per enrolled member along with discounted, transparent pricing for other dental services. Enrollment is effective immediately upon the dental practice's receipt and acceptance of this Agreement and either the annual or monthly fee for each enrolled Member. Membership will renew automatically subject to the terms and conditions contained in this Agreement. Health Assurance members receive benefits as detailed in the categories of “Assured Preventive Health Services, "Health Restorative Services" and or any special additions. Dental care included in the fee along with discounted pricing for all other dental services based on patient's chosen Health Assurance Plan. Membership in the Health Assurance program is subject to the terms and conditions contained in this Agreement.</p>

            <p>Your member information should be supplied on your dentist's health assurance website for purposes of managing your membership(s). </p>

            <p>Payment Authorization: I authorize the dental practice to bill by credit/debit card or bank account for this program; said authorization shall remain in effect until I notify the dental practice in writing to cancel my membership in the Health Assurance program. </p>

            <p>TERMS AND CONDITIONS</p>

            <p>1. Health Assurance is not insurance. This Agreement applies only to dental services provided by the dental practice. Members must pay for membership in advance to receive discounted services. </p>

            <p>2. The annual fee for a Health Assurance program is described on the practice's Health Assurance website for each enrolled Member, payable annually upon enrollment or in equal monthly installments.</p>

            <p>3. The term of this Agreement is one year from the effective date of enrollment. Enrollment is effective immediately upon the dental practice receipt and acceptance of this Agreement and payment of either the annual or first monthly fee for each enrolled Member. All fees are non-refundable. The term of this Agreement shall automatically renew for consecutive one-year periods unless the dental practice receives written notice of cancellation from each Member wishing to cancel their membership. the dental practice reserves the right to change the terms and conditions of membership prior to renewal. Members shall receive written notification prior to the renewal of this Agreement, which shall contain the membership fees and Price Menu applicable to the renewal term. Provided that a notice to cancel has not been received by the dental practice by the date for renewal, the credit/debit card above will be automatically charged or drafted upon renewal of each membership. </p>

            <p>4. No person other than an enrolled Member is entitled to receive discounted dental services. Neither this Agreement nor the Health Assurance program is transferable. Dentists employed by Schon Dental are subject to change; membership does not guarantee access to a particular dentist.</p>

            <p>5. Health Assurance is intended only for patients without dental insurance. the dental practice reserves the right to cancel this Agreement and retain all fees for any Member who has dental insurance or other coverage through a state or federal healthcare program.</p>

            <p>6. This Agreement constitutes the entire agreement for membership in the Health Assurance program. No enrolled Member is relying upon any other agreement or representation related to membership in the Health Assurance program which is not detailed on the dental practice Health Assurance page, and any such other agreements or representations are hereby superseded. No addition, clarification, or other change to this Agreement will be effective unless in writing signed by the enrolled Member and an authorized representative of the dental practice. Health Assurance can't be used at other practices. Health Assurance is an agreement solely between Member and the dental practice.</p>

            <p>7. If an enrolled Member does not make any payment when due, for any reason, then the remaining balance owed on the annual membership fee will become immediately due and payable, interest will accrue on the outstanding balance at a rate of 1.5% per month until paid in full, and the membership will automatically be terminated. If the dental practice refers any account to a collection agency and/or files suit for collection of unpaid fees, will be entitled to recover all collection costs, including but not limited to collection agency fees and attorneys' fees.</p>

            <p>The Guarantor must sign below, indicating his/her agreement that:</p>
            <ul>
              <li>I have read and understand the terms and conditions of this Membership Enrollment Agreement. </li>
              <li>I have reviewed and understand the benefits of the membership I have chosen for the dental practice.</li>
              <li>I do not have dental insurance or other coverage through a state or federal healthcare program.</li>
            </ul>
          </div>

          <h4>Name</h4>
          <div class="row">
            <div class="col">
              <input v-model="nameFirst" name="nameFirst" class="form-control" placeholder="First Name" type="text"/>
            </div>
            <div class="col">
              <input v-model="nameLast" name="nameLast" class="form-control" placeholder="Last Name" type="text"/>
            </div>
          </div>

          <h4>Signature</h4>

          <p>
            <label class="normal-weight form-check">
              <input type="checkbox" name="esignatureConsent" v-model="esignatureConsent" value="esignatureConsent" class="form-check-input">
              <strong>I consent to use an electronic signature.</strong>
            </label>
          </p>

          <div ref="signatureContainer" class="signature-container" @mouseup="updateSignatureEmpty" @touchend="updateSignatureEmpty">
            <vueSignature :key="signatureKey" ref="signature" :w="signatureWidth" :h="'200px'"></vueSignature>
          </div>
          <div class="row signature-instructions">
            <div class="col">
              <p><small><em>Use your mouse or finger to draw your signature above</em></small></p>
            </div>
            <div class="col text-right">
              <button class="btn btn-outline btn-sm" @click.prevent="clearSignature">Clear Signature</button>
            </div>
          </div>

          <list-errors :errors="errors"></list-errors>

          <p v-if="!loading"><button class="btn btn-app" :disabled="!esignatureConsent || !nameFirst || !nameLast || signatureEmpty" @click.prevent="signAgreement">Sign Now<i class="far fa-angle-right fa-fw fa-right"></i></button></p>

          <p v-if="loading" class="loading text-center"><i class="far fa-pulse fa-2x fa-spinner major-color"></i></p>

        </div> <!-- /.col-x -->
      </div> <!-- /.row -->

    </div>
  </layout-private>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import vueSignature from "vue-signature"
import LayoutPrivate from '../components/LayoutPrivate.vue'
import ListErrors from '../components/ListErrors.vue'

export default {
  props: [''],
  computed: {
    ...mapGetters(['user','user_props','guarantorPracticeName']),
    environment () {
      if(process.env.VUE_APP_ENV === "staging" || process.env.NODE_ENV === "development") {
        return 'staging'
      }
      return 'production'
    },
  },
  data () {
    return {
      nameFirst: null,
      nameLast: null,
      esignatureConsent: false,
      errors: [],
      signatureWidth: '825',
      resizeTimeout: null,
      signatureKey: 0, // key for signature component to destroy & recycle it after screen resizes so the canvas will be resized
      signatureEmpty: true,
      loading: false
    }
  },
  components: {
    LayoutPrivate, ListErrors, vueSignature
  },
  methods: {
    clearSignature() {
			this.$refs.signature.clear();
      // also update signatureEmpty boolean
      this.updateSignatureEmpty();
		},
    updateSignatureEmpty (event) {
      console.log('event',event.type);
      this.signatureEmpty = this.$refs.signature.isEmpty();
      console.log('signatureEmpty',this.signatureEmpty)
    },
    timeoutResize () {
      // use timeout function so that the siognature component's key is not reset at too high a rate
      // console.log('timeoutResize')
      if(this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(this.handleResize,300);
    },
    handleResize() {
      // console.log('handleResize')
      let oldWidth = this.signatureWidth;
      let width;
      // console.log('window.innerWidth',window.innerWidth)
      // Calculate new canvas size based on window size & Bootstrap brakpoints
        // xl -  1200px min-width ==> 1140px
        // lg - 992px min-width ==> 960px
        // md - 768px min-width ==> 720px
        // sm - 576px min-width ==> 540px
        // col-lg-9 is 75% of container
        // multiply by .75 for col-lg-9
        // subtract 30 for column padding
        // subtract 2 for signature box border
      if(window.innerWidth >= 1200) {
        width = (1140 * .75) - 30 - 2;
      }
      else if(window.innerWidth >= 992) {
        width = (960 * .75) - 30 - 2;
      }
      else if(window.innerWidth >= 768) {
        width = 720 - 30 - 2;
      }
      else if(window.innerWidth >= 576) {
        width = 540 - 30 - 2;
      }
      // fallback size for smallest screens
      else {
        width = 450;
      }
      // conditional so signature component is only recycled if the size actually changed
      if(String(width) !== oldWidth) {
        this.signatureWidth = String(width);
        this.signatureKey += 1;
        this.signatureEmpty = true;
      }
    },
    signAgreement() {
      this.errors = [];
      if(this.signatureEmpty) {
        this.errors.push('Please use your mouse or finger to draw your signature above')
      }
      if(!this.nameFirst || !this.nameLast) {
        this.errors.push('Please enter your full name')
      }
      if(!this.esignatureConsent) {
        this.errors.push('Please check the box consenting to use an electronic signature')
      }
      if(this.errors.length) {
        return;
      }

      console.log("sign!")
      let data = {};
      this.loading = true;

      // save signature image
      let signatureImageURI = this.$refs.signature.save();
      // console.log('signatureImageURI', signatureImageURI);
      // create timestamp
      let timestamp = Math.floor(Date.now() / 1000);
      // console.log('timestamp', timestamp);

      // // storage path structure: practice_account_id/signatures/uid.png
      let storagePath = `practices/${this.user_props.practice_account_id}/signatures/${this.user.uid}.png`;

      // store data as new agreement
      let newAgreementKey = Vue.db.ref().child(`agreements/`).push().key;
      data[`agreements/${newAgreementKey}`] = {
        guarantor_uid: this.user.uid,
        practice_id: this.user_props.practice_account_id,
        esignature_submission: {
          name_first: this.nameFirst,
          name_last: this.nameLast,
          signature_img_url: storagePath,
          timestamp: timestamp
        }
      }
      // add agreement id to user_props for userId from form
      data[`user_props/${this.user.uid}/agreement_id`] = newAgreementKey;
      // store guarantor's name too
      data[`user_props/${this.user.uid}/name_first`] = this.nameFirst;
      data[`user_props/${this.user.uid}/name_last`] = this.nameLast;
      console.log("data", data)

      // save image to firebase storage

      // return;
      // vvvvvvv UNTESTED vvvvvvv


      // save img storage url
      let ref = Vue.firebase.storage().ref().child(storagePath);
      ref.putString(signatureImageURI, 'data_url')
        .then((snapshot) => {
          console.log('done uploading signature');

          // save all updates to database
          return Vue.db.ref().update(data);
        })
        .then(result => {
          console.log(result);
          this.$router.push('/membership');
          this.loading = false;
        })
        .catch(err => {
          // console.log("error publishing page",err);
          this.loading = false;
          this.errors.push(err);
        })
    },
  },
  mounted () {
    window.addEventListener('resize', this.timeoutResize);
    this.timeoutResize();
  }
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

hr {
  margin-bottom: 50px;
}
.agreement-text {
  overflow-y: scroll;
  height: 300px;
  border: 1px solid @major-color;
  padding: 30px;
  margin-bottom: 30px;
}
.signature-container {
  margin-bottom: 10px;

  > div {
    border: 1px solid @text-color;
    // width: 100% !important;
  }
  /deep/ canvas {
    // width: 100% !important;
  }
}
.signature-instructions {
  margin-bottom: 30px;
}
</style>
