<template lang="html">
  <layout-private>
    <div class="row">
      <div class="col-12">
        <h2>Account</h2>
        <hr />

        <ul class="nav nav-sub">
          <li class="nav-item">
            <router-link to="/account" exact-active-class="active" class="nav-link">Billing</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/profile" exact-active-class="active" class="nav-link">Edit User Profile</router-link>
          </li>
        </ul>

        <p v-if="!this.verified" class="alert alert-app">
          <span v-if="!verifyEmailSent"><strong>Your account isn't verified.</strong> Please check your email to verify your account and protect your data. <a href="#" @click.prevent="verifyEmail">Resend the email.</a></span>
          <span v-if="verifyEmailSent">Email verification sent to {{ this.email }}. Please check your inbox!</span>
        </p>

        <div v-if="this.account && this.account.restricted && !this.account.restricted.stripe_connected_account_id" class="stripe-connect">
          <h3 class="light-weight">Stripe Account</h3>
          <p><a :href="'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=' + stripeConnectClientId + '&scope=read_write&state=' + account['.key']" class="btn-stripe-connect"><img src="../assets/img/btn-stripe-blue-on-light@2x.png" width="190px" height="33px" alt="Connect with Stripe" /></a></p>
        </div>

        <div v-if="this.account && this.account.restricted && this.account.restricted.stripe_connected_account_id" class="stripe-connect">
          <h3 class="light-weight">Stripe Account</h3>
          <p><strong>Status: </strong><em>connected</em></p>
        </div>

      </div> <!-- /.col -->

    <router-view :plans="plans"></router-view>

  </div> <!-- /.row -->
  </layout-private>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import Firebase from 'firebase/app'
import 'firebase/auth'
import LayoutPrivate from '../components/LayoutPrivate.vue'

export default {
  props: ['plans'],
  components: {
    LayoutPrivate
  },
  data () {
    return {
      verifyEmailSent: false,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'account']),
    stripeConnectClientId () {
      if(process.env.VUE_APP_STRIPE_MODE === 'live') {
        return "ca_FridlESuQVpasKXXqvgE5AOW0Q2f3RnQ"
      }
      return "ca_FridJeDc2JSfIibMbTTmSZ9VBndSl2ot"
    },
    email() {
      return this.user.email
    },
    verified() {
      return this.user.emailVerified
    }
  },
  methods: {
    async verifyEmail () {
      let result = await this.$firebase.verifyEmail()
      if(result == true) {
        this.verifyEmailSent = true;
      }
    },
  },

}
</script>

<style lang="less" scoped>

.stripe-connect h3 {
  margin-bottom: 10px;
}
</style>
