<template lang="html">
  <div>
    <p v-if="loading"><i class="far fa-fw fa-pulse fa-spinner"></i></p>

    <div v-if="!loading && !isUserAllowedToView">
      <p><em>Sorry, you don't have permission to view this page.</em></p>
    </div>

    <div class="row">
      <div v-if="isUserAllowedToView" class="account-meta col-8">
        <p class="dark-color snug">Guarantor</p>
        <h3 v-if="guarantorProps">{{ guarantorProps.name_first }} {{ guarantorProps.name_last }}</h3>
        <p v-if="user_props && user_props.user_type && guarantorProps"><strong>Email Address:</strong> {{ guarantorProps.email }}</p>
        <p v-if="guarantorProps">
          <strong>Membership Agreement: </strong>
          <span v-if="guarantorProps.agreement_id"><em>Signed </em><router-link :to="'/agreements/' + guarantorProps.agreement_id" class="small">view</router-link></span>
          <strong v-if="!guarantorProps.agreement_id"><router-link to="/agreement">Awaiting Signature</router-link></strong>
        </p>
        <p v-if="hasAccountPrivate && guarantorAccount.private.account_status"><strong>Status: </strong><em>{{ accountStatusPretty }}</em></p>
        <p v-if="user_props && user_props.user_type && guarantorAccount">
          <a
            v-if="environment == 'production'"
            target="_blank"
            :href="'https://dashboard.stripe.com/customers/' + guarantorAccount.restricted.stripe_guarantor_customer_id"
          >
            <i class="far fa-fw fa-external-link-square-alt"></i>View Account in Stripe Dashboard
          </a>
          <a
            v-if="environment !== 'production'"
            target="_blank"
            :href="'https://dashboard.stripe.com/test/customers/' + guarantorAccount.restricted.stripe_guarantor_customer_id"
          >
            <i class="far fa-fw fa-external-link-square-alt"></i>View Account in Stripe Dashboard
          </a>
        </p>
        <p v-if="user_props && user_props.user_type">
          <router-link :to="'/guarantor-user/' + userId"><i class="far fa-fw fa-pencil"></i>Edit Guarantor User Account</router-link>
        </p>

        <p v-if="memberCardUrl && user_props && !user_props.user_type && guarantorProps && guarantorProps.agreement_id && hasAccountPrivate && guarantorAccount.private.account_status">
          <a :href="memberCardUrl" target="_blank"><i class="far fa-fw fa-file-download"></i>Download Membership Card</a><br />
        </p>


        <p v-if="hasAccountRestricted && guarantorAccount.restricted.stripe_error" class="alert alert-app"><strong>Card Error:</strong> {{ guarantorAccount.restricted.stripe_error }} Please update the payment method.</p>

        <p v-if="hasAccountPrivate && guarantorAccount.private.account_status == 'pastDue'" class="alert alert-app"><strong>Payment failed and account is past due.</strong> Please update the payment method to reattempt payment.</p>

        <p v-if="hasAccountPrivate && guarantorAccount.private.account_status == 'expired'" class="alert alert-app"><strong>Payment failed and account has expired.</strong> To continue the membership, update the payment method and create a new subscription for each member.</p>

        <p v-if="hasAccountPrivate && guarantorAccount.private.account_status == 'incomplete'" class="alert alert-app"><strong>Payment failed during account creation.</strong> Please update the payment method to reattempt payment.</p>

        <p v-if="hasAccountPrivate && guarantorAccount.private.account_status == 'pending' && !guarantorAccount.restricted.stripe_error" class="alert alert-app"><i class="far fa-fw fa-pulse fa-spinner"></i> Please wait, processing payment information</p>

        <p v-if="user_props && !user_props.user_type && guarantorProps && guarantorProps.agreement_id && hasAccountPrivate && guarantorAccount.private.account_status">
          <router-link to="/update-payment-method"><i class="fas fa-fw fa-credit-card-front"></i>Update Payment Method</router-link><br />
        </p>
      </div> <!-- /.account-meta -->
      <div v-if="!userId && guarantorPracticeName && guarantorPracticeContact" class="col-4">
        <div class="practice-contact">
          <p class="dark-color">Questions? Contact your dental practice</p>
          <p class="snug">
            {{ guarantorPracticeName }}<br />
            {{ guarantorPracticeContact }}
          </p>
        </div>
      </div> <!-- /.col -->
    </div> <!-- /.row -->

    <div v-if="isUserAllowedToView && hasAccountPrivate && guarantorAccount.private.members && Object.keys(guarantorAccount.private.members).length" class="account-table">
      <h4>Subscription &amp; Members</h4>
      <div class="row row-th">
        <div class="col">member name</div>
        <div class="col">plan</div>
        <div class="col">price</div>
        <div class="col">status</div>
        <div class="col">next payment</div>
        <div class="col col-1"><!-- empty for arrow placeholder --></div>
      </div> <!-- /.row -->
      <member-status
        v-for="(member, key) in guarantorAccount.private.members"
        :memberId="key"
        :guarantorAccount="guarantorAccount"
        :useProfileLink="user_props && user_props.user_type"
        :key="key"
      ></member-status>
      <router-link v-if="user_props && user_props.user_type && userId" class="btn btn-app" :to="'/membership/' + userId + '/edit'">Edit Subscription &amp; Members<i class="far fa-angle-right fa-fw fa-right"></i></router-link>
      <router-link v-if="user_props && !user_props.user_type" class="btn btn-app" to="/membership">Manage Subscription &amp; Members<i class="far fa-angle-right fa-fw fa-right"></i></router-link>
    </div>

    <div v-if="user_props && !user_props.user_type && guarantorProps" class="onboarding">
      <h3 v-if="guarantorProps.agreement_id && hasAccountPrivate && guarantorAccount.private.stripe_token && !guarantorAccount.private.account_status" class="light-weight"><i class="far fa-pulse fa-spinner"></i> Please wait, processing your account information</h3>

      <!-- onboard step 1 -->
      <div v-if="!guarantorProps.agreement_id" class="start-here">
        <h4 class="snug">Start Here</h4>
        <h3 class="light-weight">Sign The Membership Agreement</h3>
        <p>To get started, sign the membership agreement, then you'll choose your membership plan.</p>
        <p><router-link class="btn btn-app" to="/agreement">Join Now<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
      </div>

      <!-- onboard step 2 -->
      <div v-if="guarantorProps.agreement_id && !hasAccountPrivate" class="start-here">
        <h3 class="light-weight">Start Your Membership</h3>
        <p v-if="!guarantorAccount">Get membership benefits at {{ guarantorPracticeName }} and choose the right plan for yourself and/or everyone in your family or group.</p>
        <p v-if="!guarantorAccount"><router-link class="btn btn-app" to="/membership">Join Now<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
      </div>

      <!-- onboard step 3 -->
      <div v-if="guarantorProps.agreement_id && hasAccountPrivate && (!guarantorAccount.private.account_status || guarantorAccount.private.account_status !== 'active')" class="start-here">
        <h3 class="light-weight">Start Your Membership</h3>
        <p>All that's left is to add your payment method to begin your membership benefits at {{ guarantorPracticeName }}. Click below to finish where you left off!</p>
        <p><router-link class="btn btn-app" to="/payment-method">Continue Membership Setup<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
      </div>
    </div> <!-- /.onboarding -->

    <div v-if="isUserAllowedToView && hasAccountRestricted && guarantorAccount.restricted.transactions && Object.keys(guarantorAccount.restricted.transactions).length" class="account-table">
      <h4>Transaction History</h4>
      <table class="table table-transactions">
        <tr v-for="transaction in guarantorAccount.restricted.transactions">
          <td v-if="transaction.timestamp">{{ transaction.timestamp | prettyDate }}</td>
          <td v-if="transaction.status"><em>{{ transaction.status }}</em></td>
          <td v-if="transaction.amount">{{ formatStripePrice(transaction.amount) }}</td>
          <td><a v-if="transaction.receipt_url" :href="transaction.receipt_url" target="_blank"><i class="far fa-fw fa-receipt"></i>receipt</a></td>
        </tr>
      </table>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import MemberStatus from '../components/MemberStatus.vue'

export default {
  props: ['userId'],
  data () {
    return {
      loading: false,
      memberCardUrl: null,
      // define separately so it can be redefined when used as guarantor profile page
      guarantorAccount: this.guarantor_account,
      guarantorProps: null,
    }
  },
  components: {
    LayoutPrivate, MemberStatus
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'guarantor_account', 'guarantorPracticeName', 'guarantorPracticeContact']),
    accountStatusPretty () {
      if(this.guarantorAccount.private.account_status == "pastDue") {
        return "past due";
      }
      return this.guarantorAccount.private.account_status;
    },
    // verify logged in user has permission to view this page as a profile
    isUserAllowedToView () {
      if(this.user_props) {
        // if a practice user
        if(this.user_props.user_type && this.hasAccountPrivate) {
          // if the guarantor belongs to this practice
          if(this.user_props.owned_account_id && this.user_props.owned_account_id == this.guarantorAccount.private.practice_account_id) {
            return true;
          }
        }
        // if not a practice user, and we're loading via userId, check we're not a guarantor trying to view another guarantor
        // guarantor's own profile route will override one with a param but we need to let them see their own profile!
        else if(!this.userId || (this.userId && this.user_props.owned_guarantor_account_id == this.userId) ) {
          return true;
        }
      }
      return false;
    },
    // to shorten long conditionals and avoid TypeError: Cannot read property 'X' of undefined
    hasAccountPrivate () {
      if(this.guarantorAccount && this.guarantorAccount.private){
        return true;
      }
      return false
    },
    hasAccountRestricted () {
      if(this.guarantorAccount && this.guarantorAccount.restricted){
        return true;
      }
      return false
    },
    environment () {
      return process.env.NODE_ENV;
    },
  },
  watch: {
    // set guarantorAccount on page refresh
    guarantor_account() {
      if(this.guarantor_account) {
        this.guarantorAccount = this.guarantor_account;
      }
    },
    // set guarantorProps on page refresh
    user_props() {
      if(this.user_props && !this.user_props.user_type) {
        this.guarantorProps = this.user_props;
      }
    },
    // load guarantorAccount if id set by props via route params
    userId () {
      this.loadGuarantorAccount();
    },
    // load guarantorProps if undefined after guarantorAccount was loaded
      // when this page used as profile by practice user
    guarantorAccount () {
      this.loadGuarantorProps();
    }
  },
  methods: {
    formatStripePrice(price) {
      return `$${price/100}`;
    },
    loadGuarantorAccount () {
      if(this.userId) {
        Vue.db.ref(`guarantor_accounts/${this.userId}`)
          .once('value')
          .then(snapshot => {
            if(snapshot.val()) {
              this.guarantorAccount = snapshot.val();
              this.loading = false;
            } else {
              console.log("error loading guarantorAccount");
            }
          })
          .catch(err => console.log(err));
      }
    },
    loadGuarantorProps () {
      if(this.guarantorAccount && !this.guarantorProps) {
        this.loading = true;
        Vue.db.ref(`user_props/${this.guarantorAccount.private.guarantor_uid}`)
          .once('value')
          .then(snapshot => {
            if(snapshot.val()) {
              this.guarantorProps = snapshot.val();
              this.loading = false;
            } else {
              console.log("error loading guarantorProps");
            }
          })
          .catch(err => console.log(err));
      }
    },
  },
  mounted () {
    // set guarantorAccount on router navigation
    if(this.guarantor_account) {
      this.guarantorAccount = this.guarantor_account;
    }
    // set guarantorProps on router navigation
    if(this.user_props && !this.user_props.user_type) {
      this.guarantorProps = this.user_props;
    }
    // load guarantorAccount -> guarantorProps on router navigation for practice users
    this.loadGuarantorAccount();
    // get url to member card pdf if not a practice user
    if(!this.userId) {
      Vue.storage.ref().child('assets/ha-member-card.pdf').getDownloadURL()
          .then((result) => {
            // console.log(result)
            this.memberCardUrl = result;
          })
          .catch(e => console.log(e));
    }
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.account-meta {
  margin-bottom: 50px;

  > p {
    margin-bottom: 10px;
  }
}
.start-here {
  padding: 50px;
  background-color: @box-color;
}
ul.list-unstyled li {
  margin: 0;
}
h3 {
  margin: 0 0 10px;
}
h3.tall {
  margin: 50px 0 10px;
}
a .fa-fw {
  margin-right: 5px;
}
.account-table {
  margin-top: 50px;

  h4 {
    margin: 0 0 10px;
  }
}
.row-th {
  font-size: 16px;
  padding: 10px;
  margin: 0;
  border-top: 1px solid @text-color;
  color: #666;

  > .col:first-child {
    padding-left: 0;
  }
  > .col:last-child {
    padding-right: 0;
  }
}
.table-transactions {
  border-top: 1px solid @text-color;

  // remove borders to match members table
  > tr td {
    border: none;
  }
}

.practice-contact {
  background-color: @box-color;
  padding: 20px;

  p.dark-color {
    margin-bottom: 10px;
  }
}
.onboarding {
  margin-top: 50px;
}
</style>
