<template lang="html">
  <div class="col">
    <div v-if="this.account">
      <div v-if="this.account.private.subscription_status == 'active'">
        <h3 class="light-weight">Current Plan</h3>
        <p class="plan-name snug">{{ this.activePlan.name }} Plan</p>
        <ul class="list-unstyled">
          <li v-for="detail in activePlan.details">{{ detail }}</li>
          <li>{{ activePlan.price }}</li>
        </ul>
        <p v-if="this.stripeError"><strong>Card Error:</strong> {{ this.stripeError }} Please update your payment method.</p>
        <p>
          <router-link to="/update-card"><i class="fas fa-fw fa-credit-card-front"></i>Update Payment Method</router-link><br />
          <router-link to="/subscription"><i class="far fa-fw fa-random"></i>Switch Plans</router-link><br />
          <router-link to="/cancel"><i class="far fa-fw fa-ban"></i>Cancel Subscription</router-link>
        </p>
        <h3 class="tall light-weight">Next Payment</h3>
        <p>{{ this.currentPeriodEnd | prettyDate }}</p>
      </div>
      <div v-if="this.account.private.subscription_status == 'trialing'">
        <h3 class="light-weight">Current Plan</h3>
        <p class="plan-name snug">Free Trial: {{ this.activePlan.name }} Plan</p>
        <ul class="list-unstyled">
          <li v-for="detail in activePlan.details">{{ detail }}</li>
          <li>{{ activePlan.price }}</li>
        </ul>
        <p>
          <router-link to="/update-card"><i class="fas fa-fw fa-credit-card-front"></i>Update Payment Method</router-link><br />
          <router-link to="/subscription"><i class="far fa-fw fa-random"></i>Switch Plans</router-link><br />
          <router-link to="/cancel"><i class="far fa-fw fa-ban"></i>Cancel Subscription</router-link>
        </p>
        <h3 class="tall light-weight">First Payment</h3>
        <p>Your free trial will end and your first payment will occur on {{ this.currentPeriodEnd | prettyDate }}.</p>
      </div>
      <div v-if="this.account.private.subscription_status == 'cancelled'">
        <h3 class="light-weight">Current Plan</h3>
        <p class="plan-name snug">Cancelled: {{ this.activePlan.name }} Plan</p>
        <ul class="list-unstyled">
          <li v-for="detail in activePlan.details">{{ detail }}</li>
          <li>{{ activePlan.price }}</li>
        </ul>
        <h3 class="tall light-weight">Your Data Will Be Deleted Soon</h3>
        <p>Your account will be closed and data deleted at the end of your subscription period on {{ this.currentPeriodEnd | prettyDate }}.</p>
        <p><router-link class="btn btn-app" to="/subscription">Restart Your Plan<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
      </div>
      <div v-if="this.account.private.subscription_status == 'pastDue'">
        <h3 class="light-weight">Current Plan</h3>
        <p class="plan-name snug">Past Due: {{ this.activePlan.name }} Plan</p>
        <ul class="list-unstyled">
          <li v-for="detail in activePlan.details">{{ detail }}</li>
          <li>{{ activePlan.price }}</li>
        </ul>
        <h3 class="tall light-weight"><i class="fas fa-exclamation-triangle"></i> Account Past Due. Your Data Will Be Deleted Soon.</h3>
        <p v-if="this.stripeError"><strong>Card Error:</strong> {{ this.stripeError }}</p>
        <p><strong>Please update your payment method.</strong> We'll automatically reattempt soon, but if that fails and you haven't added a new payment method, your subscription will end and data will be deleted at the end of your subscription period on {{ this.currentPeriodEnd | prettyDate }}.</p>
        <p><router-link class="btn btn-app" to="/update-card">Update Payment Method<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
      </div>
      <div v-if="this.account.private.subscription_status == 'pending' && !this.stripeError">
        <h3 class="light-weight">Current Plan</h3>
        <p class="plan-name snug">Pending: {{ this.activePlan.name }} Plan</p>
        <ul class="list-unstyled">
          <li v-for="detail in activePlan.details">{{ detail }}</li>
          <li>{{ activePlan.price }}</li>
        </ul>

        <h3 class="tall light-weight"><i class="far fa-spinner-third fa-spin"></i> Processing Your Payment Information</h3>
        <p>Securely processing your information. This will only take a moment. This page will update automatically when complete.</p>
      </div>
      <div v-if="this.account.private.subscription_status == 'pending' && this.stripeError">
        <h3 class="light-weight">Current Plan</h3>
        <p class="plan-name snug">Pending: {{ this.activePlan.name }} Plan</p>
        <ul class="list-unstyled">
          <li v-for="detail in activePlan.details">{{ detail }}</li>
          <li>{{ activePlan.price }}</li>
        </ul>
        <h3 class="tall light-weight">Error Activating Your Account</h3>
        <p><strong>Card Error:</strong> {{ this.stripeError }}</p>
        <p>Sorry, there was a problem processing your payment info. Please try a new payment method.</p>
        <p><router-link class="btn btn-app" to="/update-card">Update Payment Method<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
      </div>
      <div v-if="this.account.private.subscription_status == 'closed' || this.account.private.subscription_status == 'expired'">
        <h3 class="light-weight">Choose a Plan to Get Started</h3>
        <p><router-link class="btn btn-app" to="/subscription">Pick Your Plan<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
      </div>
      <div v-if="this.account.restricted && this.account.restricted.transactions">
        <h3 class="tall light-weight">Payment History</h3>
        <table class="table">
          <tr v-for="transaction in this.account.restricted.transactions">
            <td>{{ transaction.timestamp | prettyDate }}</td>
            <td><em>{{ transaction.status }}</em></td>
            <td>{{ planNameFromId(transaction.stripe_practice_plan_id) }} plan</td>
            <td>{{ formatStripePrice(transaction.amount) }}</td>
            <td v-if="transaction.receipt_url" ><a :href="transaction.receipt_url" target="_blank"><i class="far fa-fw fa-receipt"></i>receipt</a></td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="!this.account">
      <h3 class="light-weight">Choose a Plan to Get Started</h3>
      <p>Ready to start offering membership plans to your patients? Pick a plan below to get started.</p>
      <p><router-link class="btn btn-app" to="/subscription">Pick Your Plan<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import LayoutPrivate from '../components/LayoutPrivate.vue'

export default {
  props: ['plans'],
  data () {
    return {
      loading: false,
    }
  },
  components: {
    LayoutPrivate
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'account']),
    currentPeriodEnd() {
      return this.account.restricted.stripe_current_period_end;
    },
    activePlan() {
      if(this.account.private.stripe_practice_plan_id) {
        return this.plans.find(plan => plan.id == this.account.private.stripe_practice_plan_id)
      }
    },
    // show as computed property to simplify conditionals checking if 'restricted' exists
    stripeError () {
      if(this.account && this.account.restricted && this.account.restricted.stripe_error) {
        return this.account.restricted.stripe_error;
      }
      return false;
    },
  },
  methods: {
    planNameFromId(planId) {
      if(this.plans) {
        let plan = this.plans.find(plan => plan.id == planId);
        if(plan) {
          return plan.name;
        }
      }
      return null;
    },
    formatStripePrice(price) {
      return `$${price/100}`;
    },
  },
}
</script>

<style lang="less" scoped>

ul.list-unstyled li {
  margin: 0;
}
h3 {
  margin: 0 0 10px;
}
h3.tall {
  margin: 50px 0 10px;
}
a .fa-fw {
  margin-right: 5px;
}

</style>
