<template lang="html">
  <layout-private>
    <div class="">
      <h2>Signed Membership Agreement</h2>
      <hr />

      <p v-if="loading" class="loading text-center"><i class="far fa-pulse fa-2x fa-spinner major-color"></i></p>

      <div v-if="!loading" class="row">
        <div class="col-lg-10">

          <div v-if="agreement && (agreement.formstack_submission || agreement.esignature_submission)" class="agreement-fields">
            <h5>Dental Practice Name</h5>
            <p v-if="practiceName">{{ practiceName }}</p>

            <h5>Membership Contract</h5>
            <p>Health Assurance is not insurance. It is an alternative for patients without dental insurance. Members receive discounted preventive dental care for an annual fee per enrolled member along with discounted, transparent pricing for other dental services. Enrollment is effective immediately upon the dental practice's receipt and acceptance of this Agreement and either the annual or monthly fee for each enrolled Member. Membership will renew automatically subject to the terms and conditions contained in this Agreement. Health Assurance members receive benefits as detailed in the categories of “Assured Preventive Health Services, "Health Restorative Services" and or any special additions. Dental care included in the fee along with discounted pricing for all other dental services based on patient's chosen Health Assurance Plan. Membership in the Health Assurance program is subject to the terms and conditions contained in this Agreement.</p>

            <p>Your member information should be supplied on your dentist's health assurance website for purposes of managing your membership(s). </p>

            <p>Payment Authorization: I authorize the dental practice to bill by credit/debit card or bank account for this program; said authorization shall remain in effect until I notify the dental practice in writing to cancel my membership in the Health Assurance program. </p>

            <p>TERMS AND CONDITIONS</p>

            <p>1. Health Assurance is not insurance. This Agreement applies only to dental services provided by the dental practice. Members must pay for membership in advance to receive discounted services. </p>

            <p>2. The annual fee for a Health Assurance program is described on the practice's Health Assurance website for each enrolled Member, payable annually upon enrollment or in equal monthly installments.</p>

            <p>3. The term of this Agreement is one year from the effective date of enrollment. Enrollment is effective immediately upon the dental practice receipt and acceptance of this Agreement and payment of either the annual or first monthly fee for each enrolled Member. All fees are non-refundable. The term of this Agreement shall automatically renew for consecutive one-year periods unless the dental practice receives written notice of cancellation from each Member wishing to cancel their membership. the dental practice reserves the right to change the terms and conditions of membership prior to renewal. Members shall receive written notification prior to the renewal of this Agreement, which shall contain the membership fees and Price Menu applicable to the renewal term. Provided that a notice to cancel has not been received by the dental practice by the date for renewal, the credit/debit card above will be automatically charged or drafted upon renewal of each membership. </p>

            <p>4. No person other than an enrolled Member is entitled to receive discounted dental services. Neither this Agreement nor the Health Assurance program is transferable. Dentists employed by Schon Dental are subject to change; membership does not guarantee access to a particular dentist.</p>

            <p>5. Health Assurance is intended only for patients without dental insurance. the dental practice reserves the right to cancel this Agreement and retain all fees for any Member who has dental insurance or other coverage through a state or federal healthcare program.</p>

            <p>6. This Agreement constitutes the entire agreement for membership in the Health Assurance program. No enrolled Member is relying upon any other agreement or representation related to membership in the Health Assurance program which is not detailed on the dental practice Health Assurance page, and any such other agreements or representations are hereby superseded. No addition, clarification, or other change to this Agreement will be effective unless in writing signed by the enrolled Member and an authorized representative of the dental practice. Health Assurance can't be used at other practices. Health Assurance is an agreement solely between Member and the dental practice.</p>

            <p>7. If an enrolled Member does not make any payment when due, for any reason, then the remaining balance owed on the annual membership fee will become immediately due and payable, interest will accrue on the outstanding balance at a rate of 1.5% per month until paid in full, and the membership will automatically be terminated. If the dental practice refers any account to a collection agency and/or files suit for collection of unpaid fees, will be entitled to recover all collection costs, including but not limited to collection agency fees and attorneys' fees.</p>

            <p>The Guarantor must sign below, indicating his/her agreement that:</p>
            <ul>
              <li>I have read and understand the terms and conditions of this Membership Enrollment Agreement. </li>
              <li>I have reviewed and understand the benefits of the membership I have chosen for the dental practice.</li>
              <li>I do not have dental insurance or other coverage through a state or federal healthcare program.</li>
            </ul>

            <div class="name d-inline-block">
              <h5>First Name</h5>
              <p>{{ submission.name_first }}</p>
            </div>
            <div class="d-inline-block">
              <h5>Last Name</h5>
              <p>{{ submission.name_last }}</p>
            </div>

            <h5>Signature</h5>
            <img :src="signatureImgUrl" class="img-signature" />

            <h5>Date</h5>
            <p v-if="this.agreement && this.agreement.formstack_submission">{{ submission.timestamp }}</p>
            <p v-if="this.agreement && this.agreement.esignature_submission">{{ submission.timestamp | prettyDate }}</p>

          </div>

        </div> <!-- /.col-x -->
      </div> <!-- /.row -->

    </div>
  </layout-private>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import LayoutPrivate from '../components/LayoutPrivate.vue'

export default {
  props: [''],
  computed: {
    ...mapGetters(['user','user_props','guarantorPracticeName']),
    signatureImgUrl () {
      if(this.agreement && this.agreement.formstack_submission) {
        return this.agreement.formstack_submission.signature_img_url;
      }
      if(this.agreement && this.agreement.esignature_submission) {
        return this.bucketUrl;
      }
      return null;
    },
    submission () {
      if(this.agreement && this.agreement.formstack_submission) {
        return this.agreement.formstack_submission;
      }
      if(this.agreement && this.agreement.esignature_submission) {
        return this.agreement.esignature_submission;
      }
    }
  },
  data () {
    return {
      loading: true,
      bucketUrl: null,
      agreementId: null,
      agreement: null,
      practiceId: null,
      practiceName: null,
    }
  },
  components: {
    LayoutPrivate
  },
  methods: {
    getImage () {
      Vue.storage.ref().child(this.agreement.esignature_submission.signature_img_url).getDownloadURL()
        .then((result) => {
          this.bucketUrl = result;
          this.loading = false;
        })
        .catch(e => console.log(e));
    }
  },
  created () {
    // get agreement id from url
    if(this.$route.params.id) {
      this.agreementId = this.$route.params.id
    }
    // set practice name if we have it
    if(this.guarantorPracticeName) {
      this.practiceName = this.guarantorPracticeName;
    }
  },
  watch: {
    // load the page when the pageUrl is set from params
    agreementId () {
      if (this.agreementId) {
        let agreementRef = Vue.db.ref(`/agreements/${this.agreementId}`)
      	  .on('value', (agreementSnapshot) => {
            // this.loading = false;
            // get first index in values array since orderByChild returns an object of objects
            if(agreementSnapshot.val()) {
              this.agreement = agreementSnapshot.val();
            } else {
              this.$router.push({ name: '404' })
            }
          });
      }
    },
    // get practiceName & signature imaage after agreement is loaded
    agreement () {
      if(this.agreement && !this.practiceName && !this.guarantorPracticeName) {
        Vue.db.ref(`/sales_page_meta/`)
          .orderByChild('practice_account_id')
          .equalTo(this.agreement.practice_id)
          .once('value')
          .then(results => {
            if(results.val()) {
              // console.log('loaded practice name')
              this.$store.dispatch('setGuarantorPracticeName', Object.values(results.val())[0].practiceName);
              this.practiceName = Object.values(results.val())[0].practiceName;
            }
          });
      }
      if(this.agreement && this.agreement.esignature_submission) {
        // load signature image
        this.getImage();
      } else {
        this.loading = false;
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

hr {
  margin-bottom: 50px;
}
h5 {
  margin: 50px 0 20px 0;
}
.agreement-fields > h5:first-child {
  margin-top: 0;
}
.name {
  margin-right: 50px;
}
.img-signature {
  max-width: 300px;
  height: auto;
}

</style>
