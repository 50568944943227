<template lang="html">
  <router-link :to="memberProfileUrl" v-if="guarantorAccount && memberId" class="row row-member" :class="{'link-enabled': memberProfileUrl}">
    <div v-if="loading" class="loading text-center"><i class="far fa-pulse fa-spinner"></i></div>
    <div v-if="member" class="col">{{ member.name_first }} {{ member.name_last }}</div>
    <div v-if="plan" class="col">{{ plan.name }}</div>
    <div v-if="plan && memberPriceId" class="col">{{ formatStripePrice(plan.prices[memberPriceId].amount) }}/{{ planPriceInterval }}</div>
    <div class="col"><em>{{ subscriptionStatus }}</em></div>
    <div v-if="guarantorAccount.private && guarantorAccount.private.members" class="col">{{ guarantorAccount.private.members[memberId].stripe_current_period_end | prettyDate }}</div>
    <div class="col col-1 text-right"><span class="indicator-hover"><i class="far fa-angle-right"></i></span></div>
  </router-link>
</template>

<script>
import Vue from 'vue'

export default {
  props: ['memberId', 'guarantorAccount', 'useProfileLink'],
  data () {
    return {
      loading: true,
      member: null,
      plan: null,
    }
  },
  computed: {
    subscriptionStatus () {
      if(!this.guarantorAccount || !this.memberId) {
        return null;
      }
      let status = this.guarantorAccount.private.members[this.memberId].subscription_status;
      if(!status && !this.guarantorAccount.private.members[this.memberId].subscription_id) {
        return "awaiting payment";
      }
      if(status == "pastDue") {
        return "past due";
      }
      return status;
    },
    memberProfileUrl () {
      if(this.useProfileLink && this.memberId) {
        return `/member-profile/${this.memberId}`
      } else {
        return '';
      }
    },
    memberPlanId () {
      if(this.memberId && this.guarantorAccount && this.guarantorAccount.private && this.guarantorAccount.private.members && this.guarantorAccount.private.members[this.memberId]) {
        return this.guarantorAccount.private.members[this.memberId].plan_id
      }
      return null;
    },
    memberPriceId () {
      if(this.memberId && this.guarantorAccount && this.guarantorAccount.private && this.guarantorAccount.private.members && this.guarantorAccount.private.members[this.memberId]) {
        return this.guarantorAccount.private.members[this.memberId].plan_price_id
      }
      return null;
    },
    planPriceInterval () {
      if(this.plan && this.memberPriceId) {
        let interval = this.plan.prices[this.memberPriceId].interval;
        if(interval == 'month') {
          return 'mo';
        }
        if(interval == 'year') {
          return 'yr';
        }
        if(interval == 'day') {
          return 'day';
        }
      } else {
        return ''
      }
    },
  },
  methods: {
    formatStripePrice(price) {
      return `$${price/100}`;
    },
  },
  mounted() {
    if(this.memberId) {
      Vue.db.ref(`members/${this.memberId}`)
        .once('value')
        .then(snapshot => {
          if(snapshot.val()) {
            this.member = snapshot.val();
            this.loading = false;
          } else {
            // console.log("error loading member");
          }
        })
        .catch(err => console.log(err));
    }
    // if account includes data for this member, load the plan info
    if(this.memberPlanId) {
      Vue.db.ref(`plans/${this.memberPlanId}`)
        .once('value')
        .then(snapshot => {
          if(snapshot.val()) {
            this.plan = snapshot.val();
            this.loading = false;
          } else {
            // console.log("error loading plan");
          }
        })
        .catch(err => console.log(err));
    }
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

a.row-member {
  color: @text-color;
  text-decoration: none;
  background-color: none;
  padding: 10px;
  margin: 0 0 10px;
  cursor: default;

  > .col:first-child {
    padding-left: 0;
  }
  > .col:last-child {
    padding-right: 0;
  }
  .indicator-hover {
    visibility: hidden;
  }
}
a.row-member.link-enabled {
  cursor: pointer;
}
a.row-member.link-enabled:hover {
  color: @text-color;
  background-color: @box-color;

  .indicator-hover {
    visibility: visible;
  }
}
</style>
