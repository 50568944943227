var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"member-search"},[_c('h3',[_vm._v("Search Members")]),_vm._m(0),(!_vm.user_props || !_vm.user_props.owned_account_id)?_c('p',{staticClass:"loading"},[_c('i',{staticClass:"far fa-pulse fa-2x fa-spinner major-color"})]):_vm._e(),(_vm.user_props && _vm.user_props.owned_account_id)?_c('ais-instant-search',{staticClass:"row",attrs:{"search-client":_vm.searchClient,"index-name":_vm.algoliaIndexName}},[_c('ais-configure',{attrs:{"hitsPerPage":10,"filters":("practice_account_id:" + (_vm.user_props.owned_account_id))}}),_c('ais-search-box',{staticClass:"col-lg-6 col-md-8",attrs:{"autofocus":"","placeholder":"Enter a name or email address","show-loading-indicator":true,"class-names":{
        'ais-SearchBox-form': 'input-group d-flex',
        'ais-SearchBox-input': 'form-control order-1',
        'ais-SearchBox-submit': 'btn btn-outline order-0',
        'ais-SearchBox-reset': 'btn btn-outline order-2',
      }}},[_c('div',{attrs:{"slot":"submit-icon"},slot:"submit-icon"},[_c('i',{staticClass:"far fa-search"})]),_c('div',{attrs:{"slot":"reset-icon"},slot:"reset-icon"},[_c('i',{staticClass:"far fa-times"})]),_c('div',{attrs:{"slot":"loading-indicator"},slot:"loading-indicator"},[_c('i',{staticClass:"far fa-pulse fa-spinner major-color"})])]),_c('div',{staticClass:"col-12 results"},[_c('div',{staticClass:"results-inner"},[_c('div',{staticClass:"row row-th"},[_c('div',{staticClass:"col"},[_vm._v("name")]),_c('div',{staticClass:"col-4"},[_vm._v("email")]),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v("user type")]),_c('div',{staticClass:"col"},[_vm._v("account status")]),_c('div',{staticClass:"col-1"})]),_c('ais-hits',{staticClass:"search-results",scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return _c('router-link',{staticClass:"row",attrs:{"to":item.user_type == 'guarantor' ? ("/guarantor-profile/" + (item.guarantor_account_id)) : ("/member-profile/" + (item.objectID))}},[_c('span',{staticClass:"col"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"col-4"},[(item.email)?_c('span',[_vm._v(_vm._s(_vm._f("trimText")(item.email,35)))]):_vm._e()]),_c('span',{staticClass:"col"},[_c('em',[_vm._v(_vm._s(item.user_type))])]),_c('membership-status',{staticClass:"col",attrs:{"uid":item.objectID,"userType":item.user_type,"guarantorAccountId":item.guarantor_account_id}}),_c('span',{staticClass:"indicator-hover col col-1 text-right"},[_c('i',{staticClass:"far fa-angle-right"})])],1)}}],null,false,3515655328)}),_c('ais-state-results',{staticClass:"search-footer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var nbPages = ref.nbPages;
      var hits = ref.hits;
return _c('div',{},[(!hits.length)?_c('p',{staticClass:"no-results"},[_c('em',[_vm._v("No results found.")])]):_vm._e(),(nbPages > 1)?_c('ais-pagination'):_vm._e()],1)}}],null,false,3520192721)})],1)])],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"sm"},[_c('em',[_vm._v("Search members and guarantors by name or email address")])])}]

export { render, staticRenderFns }