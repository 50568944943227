import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import FirebaseApp from './FirebaseApp'
import moment from 'moment'
import sanitizeHTML from 'sanitize-html';

Vue.prototype.$sanitize = sanitizeHTML
Vue.use(FirebaseApp)
Vue.config.productionTip = false

//  set up filters
Vue.filter('trimText', (text, max) => {
  text = text.toString()
  if(text && max && text.length > max) {
    return text.slice(0,max-3) + "...";
  }
  return text;
})
Vue.filter('prettyDate', (timestamp) => {
  if(timestamp) {
    return moment.unix(timestamp).format("MMM Do, YYYY");
  }
  return "";
})
Vue.filter('fillPracticeName', (text, practiceName) => {
  return text.replace("{practiceName}",practiceName);
})

// find user before creating app, if they are logged in when page refreshed
// wrap Vue app in firebase onAuthStateChanged to check for user before creating app
Vue.auth.onAuthStateChanged((user) => {
  new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    created () {
      if(user) {
        store.dispatch('updateUser', user)
      }
    }
  })
})
