<template lang="html">
  <layout-private>
    <div class="row">
      <div class="col-12">
        <h2>Membership Account</h2>
        <hr />

        <ul class="nav nav-sub">
          <li class="nav-item">
            <router-link to="/guarantor-account" exact-active-class="active" class="nav-link">Membership</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/guarantor-user" exact-active-class="active" class="nav-link">User Account</router-link>
          </li>
        </ul>

        <p v-if="!this.verified" class="alert alert-app">
          <span v-if="!verifyEmailSent"><strong>Your account isn't verified.</strong> Please check your email to verify your account and protect your data. <a href="#" @click.prevent="verifyEmail">Resend the email.</a></span>
          <span v-if="verifyEmailSent">Email verification sent to {{ this.email }}. Please check your inbox!</span>
        </p>

      </div> <!-- /.col -->

    <router-view :plans="plans" class="col-12"></router-view>

  </div> <!-- /.row -->
  </layout-private>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import Firebase from 'firebase/app'
import 'firebase/auth'
import LayoutPrivate from '../components/LayoutPrivate.vue'

export default {
  props: ['plans'],
  components: {
    LayoutPrivate
  },
  data () {
    return {
      verifyEmailSent: false,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'account']),
    email() {
      return this.user.email
    },
    verified() {
      return this.user.emailVerified
    }
  },
  methods: {
    async verifyEmail () {
      let result = await this.$firebase.verifyEmail()
      if(result == true) {
        this.verifyEmailSent = true;
      }
    },
  },

}
</script>

<style lang="less" scoped>
.alert-app {
  margin-bottom: 50px;
}
</style>
