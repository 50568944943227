<template lang="html">
  <layout-private>
    <div v-if="loading" class="col">
      <h3><i class="far fa-pulse fa-spinner major-color"></i> Connecting Your Stripe Account</h3>
      <p>This will take just a moment...</p>
    </div>
    <div v-if="!loading && errors.length">
      <h2>Error Connecting Your Stripe Account</h2>
      <list-errors :errors="errors"></list-errors>
      <p>Please <router-link to="/account">try again</router-link>.</p>
    </div>
  </layout-private>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import ListErrors from '../components/ListErrors.vue'

export default {
  data () {
    return {
      loading: true,
      authCode: null,
      errors: [],
    }
  },
  components: {
    LayoutPrivate, ListErrors
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'account']),
  },
  watch: {
    // watch for stripe account id, when set, navigate to account page
    account () {
      if(this.account && this.account.restricted.stripe_connected_account_id) {
        // console.log("success connecting stripe account");
        this.$router.push('/account');
      }
    },
    // when user_props loads, try to use authCode
    user_props () {
      if(this.user_props.owned_account_id && this.$route.query.code && this.$route.query.state == this.user_props.owned_account_id) {
        this.authCode = this.$route.query.code;
        // save authCode to Firebase as account.private.stripe_connect_auth_code to trigger a cloud function
        Vue.db.ref(`accounts/${this.user_props.owned_account_id}/private/stripe_connect_auth_code`).set(this.authCode)
          .then(result => {
            // console.log("success saving stripe auth code, watching for account id");
          })
          .catch(err => {
            console.log("error creating account",err);
            this.loading = false;
            this.errors.push(err);
          })

      } else if (this.$route.query.error || !this.$route.query.code) {
        if(this.$route.query.error) {
          this.errors.push(`Error code "${this.$route.query.error}": ${this.$route.query.error_description}`);
        } else {
          this.errors.push("Stripe did not authorize your account connection.");
        }
        this.loading = false;
      } else {
        this.errors.push("There was an error connecting your account.");
        this.loading = false;
      }
    },
  },
  mounted () {

  },
}
</script>

<style lang="less" scoped>
</style>
