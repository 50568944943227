<template lang="html">
  <layout-private>
    <div class="">

      <div v-if="published">
        <h2>Edit Plan</h2>
        <hr />
        <ul class="nav nav-sub">
          <li class="nav-item">
            <router-link :to="'/plan-details/' + planId" exact-active-class="active" class="nav-link">Plan Details</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="'/plan-prices/' + planId" exact-active-class="active" class="nav-link">Plan Prices</router-link>
          </li>
        </ul>
      </div>

      <div v-if="!published">
        <h2>Create Plan</h2>
        <hr />
        <h4>Step 1 of 2</h4>
        <h3>Plan Settings &amp; Benefits</h3>
      </div>

      <p v-if="loading" class="loading text-center"><i class="far fa-pulse fa-2x fa-spinner major-color"></i></p>

      <div v-if="!isAccountReady">
        <p>Finish setting up your account to begin creating member plans.</p>
        <p><router-link class="btn btn-app" to="/set-up">Continue Set Up<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
      </div>

      <div v-show="!loading" v-if="isAccountReady">

        <p v-if="!published && account.restricted.plan_count >= account.restricted.plan_count_limit && plan_type == 'additional'">Note: you've reached your account limit for primary plans, but you can still create an additional plan below.</p>

        <div v-if="!published && account.restricted.additional_plan_count_limit && account.restricted.plan_count < account.restricted.plan_count_limit">
          <h4>Plan Type</h4>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3 preset" :class="{ 'active': plan_type == 'primary' }">
              <a href="#preset" class="btn-preset" @click.prevent="setPlanType('primary')">
                <p class="serif-font">
                  <i class="active-indicator far fa-fw fa-check"></i>
                  <strong>Primary</strong>
                </p>
                <small>Preventative &amp; restorative care for uninsured patients</small>
              </a>
            </div>
            <div class="col-12 col-md-6 col-lg-3 preset" :class="{ 'active': plan_type == 'additional' }">
              <a href="#preset" class="btn-preset" @click.prevent="setPlanType('additional')">
                <p class="serif-font">
                  <i class="active-indicator far fa-fw fa-check"></i>
                  <strong>Additional</strong>
                </p>
                <small>Additional benefits for anyone, with or without insurance</small>
              </a>
            </div>
          </div> <!-- /.row -->
        </div> <!-- /v-if published -->

        <div v-if="!published && plan_type == 'primary'">
          <h4>Plan Presets</h4>
          <p>Use one of these pre-written membership plans as a starting point, then edit as you see fit.</p>
          <div class="row">
            <div v-for="preset in planPresets" class="col-12 col-md-4 col-lg-3 preset" :class="{ 'active': preset.name == name }">
              <a href="#preset" class="btn-preset" @click.prevent="presetPlan(preset)">
                <p class="serif-font">
                  <i class="active-indicator far fa-fw fa-check"></i>
                  <strong>{{ preset.name }}</strong>
                </p>
                <small><em>Benefits:</em> {{ preset.benefits_preventative_count }} preventative, {{ preset.benefits_restorative_count }} restorative, {{ preset.benefits_special_count }} special</small>
              </a>
            </div>
          </div> <!-- /.row -->
        </div> <!-- /v-if published -->

        <h4>Plan Name</h4>
        <input v-model="name" @input="setEdited" class="form-control" placeholder="Enter plan name" type="text" />

        <div v-if="published && account.restricted.additional_plan_count_limit">
          <h4>Plan Type</h4>
          <p>{{ plan_type }}</p>
        </div>

        <div v-if="published" class="archive">
          <h4>Archive Plan</h4>
          <p>Free up space in your account to create a new membership plan if you've reached your account limit. Archiving this plan will hide it on your sales page and prevent members from joining it. Members already on this plan will not be affected.</p>
          <div class="form-check form-check-inline">
            <input v-model="archived" @input="setEdited" class="form-check-input" id="archived" type="checkbox" />
            <label class="form-check-label" for="archived">Archive this plan</label>
          </div>
        </div>

        <h4>Assured Preventative Health Services</h4>
        <p>List all preventative health benefits for this membership plan</p>
        <rich-editor
          v-on:editor-html="benefits_preventative = $event"
          :defaultText="benefits_preventative"
          :disableHeadings="true"
        ></rich-editor>

        <h4>Health Restorative Services</h4>
        <p>List all restorative service benefits for this membership plan</p>
        <rich-editor
          v-on:editor-html="benefits_restorative = $event"
          :defaultText="benefits_restorative"
          :disableHeadings="true"
        ></rich-editor>

        <h4>Special Additions</h4>
        <p>List all additional benefits or limitations for this membership plan</p>
        <rich-editor
          v-on:editor-html="benefits_special = $event"
          :defaultText="benefits_special"
          :disableHeadings="true"
        ></rich-editor>

        <h4>Who is this good for?</h4>
        <p>Describe which type of patient might benefit most from this plan</p>
        <rich-editor
          v-on:editor-html="benefits_patient_type = $event"
          :defaultText="benefits_patient_type"
          :disableHeadings="true"
        ></rich-editor>

        <list-errors :errors="errors"></list-errors>

        <p>
          <button @click.prevent="publishPlan" :disabled="!edited" class="btn btn-app">Save &amp; Next <i class="far fa-angle-right fa-fw fa-right"></i></button>
          <router-link v-if="published" :to="'/plan-prices/' + planId" class="btn btn-outline">Skip</router-link>
        </p>
      </div> <!-- /v-show loading -->


    </div>
  </layout-private>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import ListErrors from '../components/ListErrors.vue'
import RichEditor from '../components/RichEditor.vue'
import presets from "../assets/json/plan-presets.json";

export default {
  props: [''],
  components: {
    LayoutPrivate, ListErrors, RichEditor,
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'account']),
    isAccountReady () {
      if( this.account && this.account.private && ['active', 'pastDue', 'pending'].includes(this.account.private.subscription_status) && this.account.restricted && this.account.restricted.stripe_connected_account_id ) {
        return true;
      }
      return false;
    },
  },
  data () {
    return {
      loading: false,
      errors: [],
      editor: null,
      planId: null,
      published: false,
      edited: false,
      planPresets: presets,
      plan: null,
      // plan properties
      name: "",
      benefits_preventative: "",
      benefits_restorative: "",
      benefits_special: "",
      benefits_patient_type: "",
      archived: false,
      plan_type: 'primary', // default so we only switch it if additional plans are allowed
    }
  },
  watch: {
    account () {
      // load plan once account is set on page reload
      if(this.account) {
        this.loadPlan();
      }
      // on refresh, if plan limit is met, default to additional plans
      if(this.account && this.account.restricted && this.account.restricted.plan_count >= this.account.restricted.plan_count_limit) {
        this.plan_type = 'additional';
        this.resetPlan();
      }
    },
    // when any rich text editor makes an update, set edited
    // watch separately
    benefits_preventative (n, o) {
      if(n !== o && !this.edited && o) {
        this.setEdited("benefits_preventative")
      }
    },
    benefits_restorative (n, o) {
      if(n !== o && !this.edited && o) {
        this.setEdited("benefits_restorative")
      }
    },
    benefits_special (n, o) {
      if(n !== o && !this.edited && o) {
        this.setEdited("benefits_special")
      }
    },
    benefits_patient_type (n, o) {
      if(n !== o && !this.edited && o) {
        this.setEdited("benefits_patient_type")
      }
    },
  },
  methods: {
    setEdited (source) {
      if(!this.edited) {
        // console.log("setEdited", source)
        this.edited = true;
      }
    },
    setPlanType(type) {
      this.plan_type = type;
      if(type == 'additional') {
        this.resetPlan();
      }
    },
    resetPlan () {
      this.name = "";
      this.benefits_preventative = "No benefits";
      this.benefits_restorative = "No benefits";
      this.benefits_special = "No benefits";
      this.benefits_patient_type = "";
    },
    // apply a plan preset
    presetPlan (preset) {
      // console.log('applying preset', preset.name)
      Object.keys(preset).forEach(prop => {
        if(this[prop] !== null && this[prop] !== undefined) {
          this[prop] = preset[prop]
        }
      });
      this.edited = true;
    },
    // load any pre-existing data
    loadPlan () {
      if(this.planId && this.isAccountReady && this.account.public && this.account.public.plans[this.planId]) {
        this.loading = true;
        // load the existing data
        let planRef = Vue.db.ref(`/plans/${this.planId}`).once('value');
        planRef.then((planSnapshot) => {
          this.loading = false;
          // set as published
          this.published = true;
          let savedPlan = planSnapshot.val();
          // save a copy
          this.plan = savedPlan;
          // console.log('found a plan', savedPlan);
          // fill all data vars with the pre-existing data
          Object.keys(savedPlan).forEach(prop => this[prop] = savedPlan[prop]);
        })
      }
    },
    validatePlan () {
      let emptyValuesArray = [null,""," ", "  ","   ","    ","     "];
      // validate each
      // -----
      // name
      if(emptyValuesArray.includes(this.name)) {
        this.errors.push('You must enter a Plan Name.')
      }
      if(this.practiceName && (this.name.length < 3 || this.name.length > 35)) {
        this.errors.push('The Plan Name must be longer than 2 characters and less than 35.')
      }
      // benefits_preventative
      if(emptyValuesArray.includes(this.benefits_preventative)) {
        this.errors.push('You must enter preventative benefits text.')
      }
      // name
      if(emptyValuesArray.includes(this.benefits_restorative)) {
        this.errors.push('You must enter restorative benefits text.')
      }
      // name
      if(emptyValuesArray.includes(this.benefits_special)) {
        this.errors.push('You must enter special benefits text.')
      }
      // name
      if(emptyValuesArray.includes(this.benefits_patient_type)) {
        this.errors.push("You must enter text for 'Who is this good for?'.")
      }
      // archived
      if(this.archived == false && this.plan && this.plan.archived == true && this.account.restricted.plan_count >= this.account.restricted.plan_count_limit) {
        this.errors.push("You've reached your account's plan limit, and you can't un-archive this plan until you archive a different one.")
      }
    },
    publishPlan () {
      this.loading = true;
      this.errors = [];
      this.validatePlan();
      if(!this.user_props.owned_account_id) {
        this.errors.push("You must start your subscription before you can publish or edit a plan.")
      }
      // check primary plan limits
      if(!this.published && this.plan_type == 'primary' && this.account.restricted.plan_count >= this.account.restricted.plan_count_limit) {
        this.errors.push("Your account has reached its primary membership plan limit. Upgrade your account to increase the limit.")
      }
      // check additional plan limits
      if(!this.published && this.plan_type == 'additional' && this.account.restricted.additional_plan_count_limit && this.account.restricted.additional_plan_count_limit !== 'unlimited') {
        this.errors.push("Your account cannot add additional plans. Upgrade your account first.")
      }
      if(this.errors.length) {
        this.loading = false;
        return;
      }
      // console.log("validation passed!")
      let data = {};
      if(!this.published) {
        this.planId =  Vue.db.ref().child('plans').push().key;
        // add to account
        data[`accounts/${this.user_props.owned_account_id}/public/plans/${this.planId}`] = true;
        data[`plans/${this.planId}/practice_account_id`] = this.user_props.owned_account_id;
      }
      // set props individually so when editing we don't overwrite whole object w/ private data from Stripe
      data[`plans/${this.planId}/name`] = this.name;
      data[`plans/${this.planId}/archived`] = this.archived;
      data[`plans/${this.planId}/benefits_preventative`] = this.benefits_preventative;
      data[`plans/${this.planId}/benefits_restorative`] = this.benefits_restorative;
      data[`plans/${this.planId}/benefits_special`] = this.benefits_special;
      data[`plans/${this.planId}/benefits_patient_type`] = this.benefits_patient_type;
      data[`plans/${this.planId}/plan_type`] = this.plan_type;
      // console.log("data", data)
      // save all updates to database
      Vue.db.ref().update(data)
        .then(result => {
          // console.log(result);
          this.$router.push(`/plan-prices/${this.planId}`);
          this.updated = true;
          this.loading = false;
        })
        .catch(err => {
          // console.log("error publishing page",err);
          this.loading = false;
          this.errors.push(err);
        })
    },
  },
  mounted () {
    // if an id was passed, store it
    if(this.$route.params.id) {
      this.planId = this.$route.params.id;
      this.loadPlan();
    }
    // on router navigation, if plan limit is met, default to additional plans
    if(this.account && this.account.restricted && this.account.restricted.plan_count >= this.account.restricted.plan_count_limit) {
      this.plan_type = 'additional';
      this.resetPlan();
    }
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

h4 {
  margin-bottom: 5px;
}
.btn + .btn {
  margin-left: 10px;
}
.archive {
  margin-bottom: 30px;
}
// Presets
.preset {
  margin-bottom: 20px;

  a {
    display: block;
    padding: 25px;
    border: 1px solid @major-color;
    // background-color: transparent;
    text-decoration: none;
  }
  p {
    margin-bottom: 10px;
  }
  small {
    line-height: 1em;
  }
  p, small, i {
    color: @dark-color;
  }
  .active-indicator {
    // hide, for animation
    opacity: 0;
    transform: scale(.5);
    transition: all 125ms ease-out;
    width: 0;
  }
}
.preset a:hover {
  background-color: @major-color;

  p, small, i {
    color: @light-color;
  }
}
.preset.active {
  .active-indicator {
    transform: scale(1);
    opacity: 1;
    width: 1.25em;
    margin-right: 5px;
  }
  a, a:hover {
    background-color: @dark-color;
    border-color: @dark-color;

    p, small, i {
      color: @light-color;
    }
  }
  a:hover {
    cursor: pointer;
  }
}
</style>
