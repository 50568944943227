<template lang="html">
  <div>

    <p v-if="loading"><i class="far fa-fw fa-pulse fa-spinner"></i></p>

    <div v-if="member && isUserAllowedToView" class="member-meta">
      <p class="dark-color snug">Member</p>
      <h3>{{ member.name_first }} {{ member.name_last }}</h3>
      <p v-if="guarantorAccount && userId && guarantorAccount.private.members[userId]"><strong>Status: </strong><em>{{ memberStatusPretty }}</em></p>
      <p v-if="guarantorProps"><strong>Guarantor: </strong><router-link :to="'/guarantor-profile/' + this.member.guarantor_account_id">{{ guarantorProps.name_first }} {{ guarantorProps.name_last }}</router-link></p>
    </div>

    <div v-if="plan && isUserAllowedToView" class="plan-details ha-plans">
      <h4>Membership Plan: <strong>{{ plan.name }}</strong></h4>
      <p><strong>Plan Price: </strong> {{ formatStripePrice(plan.prices[priceId].amount) }}/{{ planPriceInterval }}</p>
      <p><strong>Assured Preventive Health Services</strong></p>
      <div v-html="$sanitize(plan.benefits_preventative)"></div>
      <p><strong>Health Restorative Services</strong></p>
      <div v-html="$sanitize(plan.benefits_restorative)"></div>
      <p><strong>Special Additions</strong></p>
      <div v-html="$sanitize(plan.benefits_special)"></div>
      <p><strong>Who is this good for?</strong></p>
      <div v-html="$sanitize(plan.benefits_patient_type)"></div>
    </div>

    <div v-if="!loading && !isUserAllowedToView">
      <p><em>Sorry, you don't have permission to view this page.</em></p>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'

export default {
  props: ['userId'],
  data () {
    return {
      loading: true,
      member: null,
      guarantorAccount: null,
      guarantorProps: null,
      // use computed props instead, sometimes these get undefined
      // planId: null,
      // priceId: null,
      plan: null,
    }
  },
  computed: {
    ...mapGetters(['user_props']),
    memberStatusPretty () {
      let status = this.guarantorAccount.private.members[this.userId].subscription_status;
      if(status == "pastDue") {
        return "past due";
      }
      if(!status && !this.guarantorAccount.private.members[this.userId].subscription_id) {
        return "awaiting payment";
      }
      return this.guarantorAccount.private.members[this.userId].subscription_status;
    },
    // verify logged in user owns account this member belongs to
    isUserAllowedToView () {
      if(this.user_props && this.member && this.user_props.owned_account_id && this.member.practice_account_id && this.user_props.owned_account_id == this.member.practice_account_id) {
        return true;
      }
      return false;
    },
    planPriceInterval () {
      if(this.plan && this.priceId) {
        let interval = this.plan.prices[this.priceId].interval;
        if(interval == 'month') {
          return 'mo';
        }
        if(interval == 'year') {
          return 'yr';
        }
      } else {
        return ''
      }
    },
    planId () {
      if(this.guarantorAccount && this.guarantorAccount.private && this.guarantorAccount.private.members && this.guarantorAccount.private.members[this.userId]) {
        return this.guarantorAccount.private.members[this.userId].plan_id
      }
      return null;
    },
    priceId () {
      if(this.guarantorAccount && this.guarantorAccount.private && this.guarantorAccount.private.members && this.guarantorAccount.private.members[this.userId]) {
        return this.guarantorAccount.private.members[this.userId].plan_price_id
      }
      return null;
    },
  },
  watch: {
    // load member on page reload
    userId () {
      this.loadMember();
    },
    // when member set, load guarantorAccount
    member () {
      if(this.member) {
        this.loading = true;
        Vue.db.ref(`guarantor_accounts/${this.member.guarantor_account_id}`)
          .once('value')
          .then(snapshot => {
            if(snapshot.val()) {
              this.guarantorAccount = snapshot.val();
              // use computed props instead, sometimes these get undefined
              // this.planId = snapshot.val().private.members[this.userId].plan_id;
              // this.priceId = snapshot.val().private.members[this.userId].plan_price_id;
              this.loading = false;
            } else {
              console.log("error loading guarantorAccount");
            }
          })
          .catch(err => console.log(err));
      }
    },
    // when guarantorAccount set, load plan & guarantor props
    guarantorAccount () {
      if(this.guarantorAccount && this.planId && this.priceId) {
        // load guarantor props to get name
        Vue.db.ref(`user_props/${this.guarantorAccount.private.guarantor_uid}`)
          .once('value')
          .then(snapshot => {
            if(snapshot.val()) {
              this.guarantorProps = snapshot.val();
              this.loading = false;
            } else {
              console.log("error loading guarantorProps");
            }
          })
          .catch(err => console.log(err));
        // load plan to get name and benefits
        Vue.db.ref(`plans/${this.planId}`)
          .once('value')
          .then(snapshot => {
            if(snapshot.val()) {
              this.plan = snapshot.val();
              this.loading = false;
            } else {
              console.log("error loading plan");
            }
          })
          .catch(err => console.log(err));
      }
    },
  },
  methods: {
    formatStripePrice(price) {
      return `$${price/100}`;
    },
    loadMember() {
      if(this.userId) {
        this.loading = true;
        Vue.db.ref(`members/${this.userId}`)
          .once('value')
          .then(snapshot => {
            if(snapshot.val()) {
              this.member = snapshot.val();
              this.loading = false;
            } else {
              console.log("error loading member");
            }
          })
          .catch(err => console.log(err));
      }
    },
  },
  mounted () {
    // load member on router navigation
    this.loadMember();
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";

.member-meta {
  margin-bottom: 50px;

  > p {
    margin-bottom: 10px;
  }
}
.plan-details {
  h4 {
    margin: 0 0 10px;
  }
}
</style>
