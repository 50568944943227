<template lang="html">
  <div class="col-md-8">
    <div v-if="!loading && !isUserAllowedToView">
      <p><em>Sorry, you don't have permission to view this page.</em></p>
    </div>
    <div v-if="!reauthenticate && isUserAllowedToView">

      <h3><span v-if="user_props && !user_props.user_type">Your </span>User Account</h3>

      <label for="firstName">First Name</label>
      <input v-model="newNameFirst" name="firstName" class="form-control input-name" placeholder="Your first name" type="text" />

      <label for="lastName">Last Name</label>
      <input v-model="newNameLast" name="lastName" class="form-control input-name" placeholder="Your last name" type="text" />


      <p v-if="user_props && user_props.user_type"><strong>Email Address:</strong> {{ newEmail }}</p>
      <p v-if="user_props && user_props.user_type"><small><em><strong>Note:</strong> Practices cannot edit a guarantor's email address or password. For security purposes, the guarantor must log in and change their own email address or password.</em></small></p>

      <div v-if="user_props && !user_props.user_type">
        <h4>Change Your Email Address</h4>
        <input v-model="newEmail" name="email" class="form-control" placeholder="Enter email" type="text" />
      </div>


      <list-errors :errors="errors"></list-errors>

      <p v-if="updated"><i class="far fa-check"></i> account successfully updated</p>

      <button v-if="!loading" @click.prevent="updateUser" class="btn btn-app">Save All Changes<i class="far fa-angle-right fa-fw fa-right"></i></button>
      <i v-if="loading" class="far fa-spinner-third fa-spin fa-2x"></i>
    </div>
    <div v-if="reauthenticate">
      <h3>Please Log In Again</h3>
      <ul class="list-errors" v-if="errors.length">
        <li v-for="(error, index) in errors" :key="index">
          <i class="far fa-fw fa-times"></i>
          <small>{{ error }}</small>
        </li>
      </ul>
      <button @click.prevent="logOut" class="btn btn-app">Log In<i class="far fa-angle-right fa-fw fa-right"></i></button>
    </div>

    <hr />

    <div v-if="user_props && !user_props.user_type">
      <h3 class="light-weight tall">Change Your Password</h3>
      <router-link to="/reset-password" class="btn btn-app">Reset Password<i class="far fa-angle-right fa-fw fa-right"></i></router-link>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import Firebase from 'firebase/app'
import 'firebase/auth'
import ListErrors from '../components/ListErrors.vue'

export default {
  props: ['plans','userId'],
  components: {
    ListErrors
  },
  data () {
    return {
      newEmail: null,
      newNameFirst: null,
      newNameLast: null,
      errors: [],
      loading: false,
      updated: false,
      reauthenticate: false,
      // define separately so it can be redefined when used as guarantor profile page
      guarantorAccount: this.guarantor_account,
      guarantorProps: null,
    }
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'guarantor_account']),
    email() {
      return this.user.email
    },
    // verify logged in user has permission to view this page as a profile
    isUserAllowedToView () {
      if(this.user_props) {
        // if a practice user
        if(this.user_props.user_type && this.hasAccountPrivate) {
          // if the guarantor belongs to this practice
          if(this.user_props.owned_account_id && this.user_props.owned_account_id == this.guarantorAccount.private.practice_account_id) {
            return true;
          }
        }
        // if not a practice user, and we're loading via userId, check we're not a guarantor trying to view another guarantor
        // guarantor's own profile route will override one with a param but we need to let them see their own profile!
        else if(!this.userId || (this.userId && this.user_props.owned_guarantor_account_id == this.userId) ) {
          return true;
        }
      }
      return false;
    },
    // to shorten long conditionals and avoid TypeError: Cannot read property 'X' of undefined
    hasAccountPrivate () {
      if(this.guarantorAccount && this.guarantorAccount.private){
        return true;
      }
      return false
    },
    hasAccountRestricted () {
      if(this.guarantorAccount && this.guarantorAccount.restricted){
        return true;
      }
      return false
    },
  },
  watch: {
    // fill name and email on page refresh
    guarantorProps () {
      this.prefillFields();
    },
    // load guarantorAccount if id set by props via route params
    userId () {
      this.loadGuarantorAccount();
    },
    // load guarantorProps if undefined after guarantorAccount was loaded
      // when this page used as profile by practice user
    guarantorAccount () {
      this.loadGuarantorProps();
    },
    // set guarantorAccount on page refresh
    guarantor_account() {
      if(this.guarantor_account) {
        this.guarantorAccount = this.guarantor_account;
      }
    },
    // set guarantorProps on page refresh
    user_props() {
      if(this.user_props && !this.user_props.user_type) {
        this.guarantorProps = this.user_props;
      }
    },
  },
  methods: {
    loadGuarantorAccount () {
      if(this.userId) {
        Vue.db.ref(`guarantor_accounts/${this.userId}`)
          .once('value')
          .then(snapshot => {
            if(snapshot.val()) {
              this.guarantorAccount = snapshot.val();
              this.loading = false;
            } else {
              console.log("error loading guarantorAccount");
            }
          })
          .catch(err => console.log(err));
      }
    },
    loadGuarantorProps () {
      if(this.guarantorAccount && !this.guarantorProps) {
        this.loading = true;
        Vue.db.ref(`user_props/${this.guarantorAccount.private.guarantor_uid}`)
          .once('value')
          .then(snapshot => {
            if(snapshot.val()) {
              this.guarantorProps = snapshot.val();
              this.loading = false;
            } else {
              console.log("error loading guarantorProps");
            }
          })
          .catch(err => console.log(err));
      }
    },
    logOut () {
      this.$firebase.logOut()
    },
    prefillFields () {
      if(this.guarantorProps && this.guarantorProps.name_first) {
        this.newNameFirst = this.guarantorProps.name_first;
      }
      if(this.guarantorProps && this.guarantorProps.name_last) {
        this.newNameLast = this.guarantorProps.name_last;
      }
      if(this.guarantorProps && this.guarantorProps.email) {
        this.newEmail = this.guarantorProps.email;
      }
    },
    updateUser() {
      let promises = [];
      let uid;
      if(this.guarantorAccount && this.guarantorAccount.private && this.guarantorAccount.private.guarantor_uid) {
        uid = this.guarantorAccount.private.guarantor_uid;
      } else {
        uid = Firebase.auth().currentUser.uid;
      }
      this.loading = true;
      this.updated = false;
      let disallowedNames = ["", " ", "  "];
      // reset errors
      this.errors = [];

      let data = {};

      // if email changed, update it
        // do not allow guarantor users to update an email, firebase auth will not allow
      if(this.newEmail !== this.guarantorProps.email && !this.user_props.user_type) {
        let user = Firebase.auth().currentUser;
        let updateEmailPromise = user.updateEmail(this.newEmail);
        // store as data to save later if email successfully updates via firebase auth
        data[`/user_props/${uid}/email`] = this.newEmail;
        promises.push(updateEmailPromise)
      }

      // if name_first changed, update it
        // save immediately instead of waiting until email is done saving
      if(this.newNameFirst !== this.guarantorProps.name_first) {
        let updateNameFirstPromise = Vue.db.ref(`user_props/${uid}/name_first`).set(this.newNameFirst);
        promises.push(updateNameFirstPromise)
      }

      // if name_last changed, update it
        // save immediately instead of waiting until email is done saving
      if(this.newNameLast !== this.user_props.name_last) {
        let updateNameLastPromise = Vue.db.ref(`user_props/${uid}/name_last`).set(this.newNameLast);
        promises.push(updateNameLastPromise)
      }

      if(promises.length) {
        Promise.all(promises)
          .then(results => {
            // const [emailResult, profileResult] = results;
            // console.log("updateUser results:",results);
            this.loading = false;
            this.updated = true;
            // if email update w/ firebase auth as successful, then store it in user_props
            if(Object.keys(data).length) {
              return Vue.db.ref().update(data);
            }
            return true;
          })
          .catch(error => {
            // console.log("updateUser error:",error);
            this.loading = false;

            if(error.code == "auth/invalid-email") {
              this.errors.push("Oops, that's not a valid email address.")
            }
            else if(error.code =="auth/requires-recent-login") {
              this.errors.push("To protect your security, you need to log in again before you can update your email address.")
              this.reauthenticate = true;
            }
            else if(error.code =="auth/network-request-failed") {
              this.errors.push("We couldn't udpate your account because of a network error. Please check your internet connection and try again.")
            }
            else if(error.message) {
              this.errors.push(error.message);
            }
          });
      } else {
        this.errors.push("No changes to save. Edit your email address or name first.")
        this.loading = false;
      }

    },
  },
  mounted () {
    // set guarantorAccount on router navigation
    if(this.guarantor_account) {
      this.guarantorAccount = this.guarantor_account;
    }
    // set guarantorProps on router navigation
    if(this.user_props && !this.user_props.user_type) {
      this.guarantorProps = this.user_props;
    }
    // load guarantorAccount -> guarantorProps on router navigation for practice users
    this.loadGuarantorAccount();
    // fill name & email on page load
    this.prefillFields();
  },

}
</script>

<style lang="less" scoped>
.input-name {
  margin-bottom: 30px;
}
h4 {
  margin-bottom: 10px;
}
h3.tall {
  margin: 50px 0 10px;
}
a .fa-fw {
  margin-right: 5px;
}
hr {
  margin: 50px 0;
}
</style>
