<template lang="html">
  <layout-private>
    <div class="">
      <h2>Set Up</h2>
      <hr />
      <p class="lead">Just follow the steps below to get your membership plans up and running in 15 minutes or less.</p>
      <div class="row">
        <div class="onboarding-step col-6 col-lg-3" :class="{ 'onboarding-step-complete': step1Complete }">
          <h3 v-if="!step1Complete">1</h3>
          <h4 v-if="step1Complete" class="check major-color"><i class="fas fa-check"></i></h4>
          <div class="illu-onboarding">
            <img src="../assets/img/icon-onboarding-shopping-cart.svg" width="98" height="100" alt="shopping cart" />
          </div>
          <h4>Start Your Subscription Plan</h4>
          <p v-if="!step1Complete"><router-link class="btn btn-app" to="/subscription">Pick Your Plan<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
        </div> <!-- /.col-x -->
        <div class="onboarding-step col-6 col-lg-3" :class="{ 'onboarding-step-complete': step2Complete }">
          <h3 v-if="!step2Complete">2</h3>
          <h4 v-if="step2Complete" class="check major-color"><i class="fas fa-check"></i></h4>
          <div class="illu-onboarding">
            <img src="../assets/img/icon-onboarding-payment.svg" width="156" height="100" alt="online payments" />
          </div>
          <h4>Create/Connect Your Stripe Account</h4>
          <p v-if="step1Complete && !step2Complete">
            <a :href="'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=' + stripeConnectClientId + '&scope=read_write&state=' + account['.key']" class="btn-stripe-connect"><img src="../assets/img/btn-stripe-blue-on-light@2x.png" width="190px" height="33px" alt="Connect with Stripe" /></a>
          </p>
        </div> <!-- /.col-x -->
        <div class="onboarding-step col-6 col-lg-3" :class="{ 'onboarding-step-complete': step3Complete }">
          <h3 v-if="!step3Complete">3</h3>
          <h4 v-if="step3Complete" class="check major-color"><i class="fas fa-check"></i></h4>
          <div class="illu-onboarding">
            <img src="../assets/img/icon-onboarding-clipboard.svg" width="150" height="100" alt="clipboard and money" />
          </div>
          <h4>Create Membership Plan(s)</h4>
          <p v-if="step1Complete && step2Complete && !step3Complete"><router-link class="btn btn-app" to="/plan-details">Create a Plan<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
        </div> <!-- /.col-x -->
        <div class="onboarding-step col-6 col-lg-3" :class="{ 'onboarding-step-complete': step4Complete }">
          <h3 v-if="!step4Complete">4</h3>
          <h4 v-if="step4Complete" class="check major-color"><i class="fas fa-check"></i></h4>
          <div class="illu-onboarding">
            <img src="../assets/img/icon-onboarding-edit.svg" width="126" height="100" alt="pencil and paper" />
          </div>
          <h4>Confirm Membership Sales Page</h4>
          <p v-if="step1Complete && step2Complete && step3Complete && !step4Complete"><router-link class="btn btn-app" to="/edit">Edit &amp; Publish<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
        </div> <!-- /.col-x -->
      </div> <!-- /.row -->
    </div>
  </layout-private>
</template>

<script>
import {mapGetters} from 'vuex'
import LayoutPrivate from '../components/LayoutPrivate.vue'

export default {
  props: [''],
  data () {
    return {

    }
  },
  components: {
    LayoutPrivate
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'account']),
    stripeConnectClientId () {
      if(process.env.VUE_APP_STRIPE_MODE === 'live') {
        return "ca_FridlESuQVpasKXXqvgE5AOW0Q2f3RnQ"
      }
      return "ca_FridJeDc2JSfIibMbTTmSZ9VBndSl2ot"
    },
    step1Complete () {
      if( this.account && this.account.private && ['active', 'pastDue', 'pending'].includes(this.account.private.subscription_status) ) {
        return true;
      }
      return false;
    },
    step2Complete () {
      if( this.account && this.account.restricted && this.account.restricted.stripe_connected_account_id ) {
        return true;
      }
      return false;
    },
    step3Complete () {
      if( this.account && this.account.public && this.account.public.plans ) {
        return true;
      }
      return false;
    },
    step4Complete () {
      if( this.account && this.account.public && this.account.public.sales_page_meta_id ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    account () {
      this.redirectIfComplete();
    }
  },
  methods: {
    // if all steps complete, redirect
    redirectIfComplete () {
      if(this.step1Complete && this.step2Complete && this.step3Complete && this.step4Complete) {
        this.$router.push('/account');
      }
    }
  },
  mounted () {
    this.redirectIfComplete();
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/variables.less";
p.lead {
  margin: 30px 0;
}
.onboarding-step {
  text-align: center;
  position: relative;
  margin-bottom: 30px;

  h4 {
    margin: 0 0 10px;
  }
  h3, h4.check {
    line-height: 1em;
    display: block;
    position: absolute;
    top: 20px;
    left: 35px;
    margin: 0;
  }
}

.illu-onboarding {
  height: 255px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  // default only
  border: 1px solid @major-color;
}
.onboarding-step-complete {
  .illu-onboarding {
    border-color: transparent;
    background-color: @box-color;
  }
}

</style>
