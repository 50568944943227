<template lang="html">
  <layout-private>
    <div class="row">
      <div v-if="this.account" class="col-12 col-md-8 offset-md-2 text-center">
        <h2>Cancel Your Subscription</h2>
      </div> <!-- /.col-x -->
      <div v-if="this.account" class="col-12 col-md-6 offset-md-3">
        <p class="lead">If you cancel your subscription, all your data will be deleted and unrecoverable.</p>
        <input v-model="confirmDelete" class="form-control" placeholder="* Type 'DELETE' here to confirm" />
        <textarea v-model="cancelReason" class="form-control" rows="5" placeholder="* Why are you cancelling your subscription?"></textarea>
        <list-errors :errors="errors"></list-errors>
        <p class="text-center">
          <button v-if="!loading"n @click.once.prevent="cancel" class="btn btn-app">Cancel Subscription &amp; Delete Data<i class="far fa-angle-right fa-fw fa-right"></i></button>
          <i v-if="loading" class="far fa-spinner-third fa-spin fa-2x"></i>
        </p>
        <p class="text-center"><router-link to="/account">Oops, I don’t want to cancel</router-link></p>
      </div> <!-- /.col-x -->
      <div v-if="!this.account" class="col-12 col-md-8 offset-md-2 text-center">
        <p><i class="far fa-spinner-third fa-spin fa-2x"></i></p>
        <h2>Loading Your Subscription</h2>
        <p>If you don't have an active subscription, you can start one here:</p>
        <p><router-link class="btn btn-app" to="/subscription">See Plans &amp; Pricing<i class="far fa-angle-right fa-fw fa-right"></i></router-link></p>
      </div> <!-- /.col-x -->
    </div> <!-- /.row -->
  </layout-private>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import LayoutPrivate from '../components/LayoutPrivate.vue'
import ListErrors from '../components/ListErrors.vue'

let stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);

export default {
  data () {
    return {
      errors: [],
      confirmDelete: '',
      cancelReason: null,
      loading: false,
    }
  },
  components: {
    LayoutPrivate, ListErrors
  },
  computed: {
    ...mapGetters(['user', 'user_props', 'account']),
  },
  methods: {
    cancel () {
      this.errors = [];
      // console.log("this.confirmDelete", this.confirmDelete);
      if(this.confirmDelete != "DELETE") {
        this.errors.push("You must type 'DELETE' to verify that you understand your data will be deleted.")
        return;
      }
      if([null,'',undefined,' '].includes(this.cancelReason) || this.cancelReason.length < 5) {
        this.errors.push("Help us do better! Please provide a reason for cancelling your account.")
        return;
      }
      if(!this.account || !this.user_props.owned_account_id) {
        this.errors.push("Sorry, we couldn't find your account! Please refresh the page and try again, or email us at info@healthassuranceplan.com");
        return;
      }
      // hide button
      this.loading = true;
      // where to store cancellation reason
      let data = {};
      // store cancellation cancelReason
      let newCancellationKey = Vue.db.ref().child('cancellations').push().key;
      data[`cancellations/${newCancellationKey}`] = {
        reason: this.cancelReason,
        account_id: this.user_props.owned_account_id,
        account_owner_email: this.user_props.email,
        plan_id: this.account.private.stripe_practice_plan_id,
        during_subscription_status: this.account.private.subscription_status
      };
      // set account.private.subscription_status = cancelled
      data[`/accounts/${this.user_props.owned_account_id}/private/subscription_status`] = "cancelled";
      Vue.db.ref().update(data)
        .then(result => {
          // console.log("Successfully cancelled.")
          this.$router.push('/account');
        })
        .catch(err => {
          console.log("error cancelling account, please email us at info@healthassuranceplan.com",err);
          this.errors.push("Error cancelling account, please email the Stripe error below to us");
          this.errors.push("Stripe error:", err);
        });
    } // /cancel
  },
}
</script>

<style lang="less" scoped>
</style>
