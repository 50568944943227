import { render, staticRenderFns } from "./PlanPreviewPublic.vue?vue&type=template&id=38009768&scoped=true&lang=html&"
import script from "./PlanPreviewPublic.vue?vue&type=script&lang=js&"
export * from "./PlanPreviewPublic.vue?vue&type=script&lang=js&"
import style0 from "./PlanPreviewPublic.vue?vue&type=style&index=0&id=38009768&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38009768",
  null
  
)

export default component.exports